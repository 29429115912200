import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
// import { adminInstance } from '../../config/axios';
import ListDatatable from './listdatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
// import { toast } from 'react-toastify';
import { useDispatch,useSelector} from 'react-redux';
import { deleteAnnouncement, getAnnouncement } from '../../reducers/adminReducers';
import moment from 'moment';
const Announcement = () => {
	// const [BoxLists, setBoxLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	// const [dummy, setDummy] = useState(false);
    const dispatch = useDispatch();
      const result = useSelector((state) => state.admin);
  const {
    announcementList
} = result || {};
// console.log('addd',announcementList)

	// const getAllBoxessData = async () => {
	// 	try {
	// 		const response = await adminInstance().get('api/admin/getBox');
	// 		const { code, box } = response.data;
	// 		console.log('box==>', box);
	// 		if (code === 200) {
	// 			setBoxLists(box);
	// 		}
	// 	} catch (error) {}
	// };

	useEffect(() => {
        // console.log('ghghggsdds')
		dispatch(getAnnouncement())
	}, [dispatch]);

    const [copyState,setCopyState] = useState([]);

    useEffect(()=>{
        if(announcementList.length>0){
        const copy = JSON.stringify(announcementList);
        const data =JSON.parse(copy);
        data.forEach((object) => {
			object.createdAt = moment(object.joined_at).format('DD/MM/YYYY HH:mm');
		});
        setCopyState(data)
        }
    },[announcementList])

	const handleOnDelete = async (id) => {
		// setLoder(true);
		// setDummy(false);
		try {
            dispatch(deleteAnnouncement(id));
            // setDummy(true);
            setOpen(false)
		} catch (error) {}
	};
	const onCloseModal = () => {
		setOpen(false);
	};

    // console.log('copState',copyState)

	return (
		<Fragment>
			<Breadcrumb title="Announcement List" parent="Announcement" />
			<Container fluid={true}>
				<div className="admin-card">
					{/* {isLoder && <Loader /> } */}
					<Card>
						<CardHeader>
							<h5>Announcement Details </h5>
						</CardHeader>
						<CardBody>
							<div className="btn-popup pull-right">
								<Link to="/create-announcement" className="btn btn-secondary">
									Create Announcement
								</Link>
							</div>
							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{announcementList.length > 0 && (
									<ListDatatable
										multiSelectOption={true}
										myData={copyState}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										setActiveId={setActiveId}
										setOpen={setOpen}
										// setBoxLists={setBoxLists}
									/>
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default Announcement;
