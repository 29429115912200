import React from "react";
import Modal from "react-bootstrap/Modal";

function JoinPopup({ setShowJoin, showJoin, joinUserData, setJoinUserData }) {
  const handleCloseJoin = () => {
    setShowJoin(false);
    setJoinUserData([]);
  };
  return (
    <Modal show={showJoin}>
      <div className='close-button'>
        <button onClick={handleCloseJoin}>
          <svg
            fill='#000000'
            width='26px'
            height='26px'
            viewBox='-6 -6 24 24'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='xMinYMin'
            class='jam jam-close'>
            <path d='M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z' />
          </svg>
        </button>
      </div>
      <div className='container'>
        <div className='tiphead'>
          <h5>Join User Details </h5>
        </div>
        <div className='tipdetails'>
          <table className='table table-striped'>
            <thead className='table-head'>
              <tr>
                <th scope='col'>User Name</th>
                <th scope='col'>Email Id</th>
              </tr>
            </thead>
            <tbody className='table-body'>
              {joinUserData.map((el, index) => (
                <tr className='country-table'>
                  <td>{el?.username}</td>
                  <td>{el?.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

export default JoinPopup;
