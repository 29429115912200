import React from 'react';
import defaultprofile from '../../../assets/images/defaultprofile.jpg';
import { useSelector } from 'react-redux';
const UserPanel = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { profile, firstname, lastname } = loginAdmin || {};
	return (
		<div>
			<div className="sidebar-user text-center admin-name">
				<div>
					<img
						className="img-60 rounded-circle lazyloaded blur-up"
						src={profile || defaultprofile}
						alt="profile"
					/>
				</div>
				<h6 className="mt-3 f-14">
					{firstname || ''} {lastname || ''}
				</h6>
				<p>{'Admin'}</p>
			</div>
		</div>
	);
};

export default UserPanel;
