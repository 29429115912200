import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-toastify/dist/ReactToastify.css";

const AffiliateUserListDataTable = ({ id, myData, myClass }) => {
  const [data, setData] = useState();
  useEffect(() => {
    setData(myData);
  }, [myData]);

  const columnDefs = [
    {
      name: "Username",
      selector: (row) => row?.username,
      sortable: true,
    },
    {
      name: "Level Name",
      selector: (row) => row?.affiliateLevel?.levelName,
      sortable: true,
    },

    {
      name: "Total Refer",
      selector: (row) => row?.referalCount,
      sortable: true,
    },
    {
      name: "Comission Type",
      selector: (row) => row?.affiliateLevel?.referrerCommissionType,
      sortable: true,
    },
    {
      name: "Comission Percent/Amount",
      selector: (row) => row?.affiliateLevel?.referrerCommission,
      sortable: true,
    },

    {
      name: "Comission Rule",
      selector: (row) => row?.affiliateLevel?.commissionRule,
      sortable: true,
    },
  ];

  return (
    <div>
      {/* <DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedTeam}
			/>
			<DeleteConfirmPopup
				title={'Delete User'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
			<div className="btn-popup pull-right">
				<span className="btn btn-secondary" onClick={selectDeleteOpen}>
					Delete
				</span>
			</div> */}
      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            selectableRows
            // clearSelectedRows={selectedRows}
            // onSelectedRowsChange={(e) => handleSelectUsers(e)}
          />
        </DataTableExtensions>
      </Fragment>
      {/* <BlockConfirmation
				title={'Delete User'}
				open={open2}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				blockUnblockInfo={activeUser}
			/> */}
    </div>
  );
};

// const ActionButton = ({ row, handleDelete, handleEdit, handleBlock }) => {
// 	return (
// 		<div className="action-btn action_btn_dot">
// 			<Dropdown>
// 				<Dropdown.Toggle variant="success" id="dropdown-basic">
// 					<i
// 						className="fa fa-ellipsis-v"
// 						style={{
// 							width: 35,
// 							fontSize: 20,
// 							padding: 11,
// 							color: '#e4566e',
// 						}}
// 					></i>
// 				</Dropdown.Toggle>
// 				<Dropdown.Menu>
// 					<Dropdown.Item>
// 						<div className="action client" onClick={() => handleEdit(row._id)}>
// 							<i
// 								className="fa fa-pencil"
// 								style={{
// 									fontSize: 17,
// 									color: '#7c7878',
// 									marginRight: '7px',
// 								}}
// 							></i>
// 							Edit
// 						</div>
// 					</Dropdown.Item>

// 					<Dropdown.Item>
// 						<div
// 							className="action client"
// 							onClick={() => handleDelete(row?._id)}
// 						>
// 							<i
// 								className="fa fa-trash"
// 								style={{
// 									fontSize: 17,
// 									marginRight: '7px',
// 									color: '#7c7878',
// 								}}
// 							></i>
// 							Delete
// 						</div>
// 					</Dropdown.Item>

// 					<Dropdown.Item>
// 						<div
// 							className="action client"
// 							onClick={() => handleBlock(row?._id, row)}
// 						>
// 							<i
// 								className="fa fa-ban"
// 								style={{
// 									fontSize: 17,
// 									marginRight: '7px',
// 									color: '#7c7878',
// 								}}
// 							></i>
// 							Active/Blocked
// 						</div>
// 					</Dropdown.Item>
// 				</Dropdown.Menu>
// 			</Dropdown>
// 		</div>
// 	);
// };
export default AffiliateUserListDataTable;
