import React from "react";
import { Col, Container, Row } from "reactstrap";
const Footer = () => {
  const today = new Date();
  const year = today?.getFullYear();
  return (
    <div>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col className="footer-copyright">
              <p className="mb-0 text-center">
               Copyright © {year || 2024} Dropheat. | All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
