import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Datatablelist from "./datatablelist";
import { getRainDropData } from "../../reducers/adminReducers";
import "./dropstyle.css";
import { useDispatch, useSelector } from "react-redux";
function RainDrop() {
  const result = useSelector((state) => state.admin);
  const { allRaindropsList } = result || {};
  const dispatch = useDispatch();
  const [showTip, setShowTip] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [joinUserData, setJoinUserData] = useState([]);
  const [tipUserData, setTipUserData] = useState([]);

  const handleShowTip = (id) => {
    const findData = allRaindropsList?.find((el)=>el._id===id);
    if(findData?.tipUsers?.length>0){
      setTipUserData(findData?.tipUsers)
    }
    setShowTip(true);
  };
  const handleShowJoin = (id) => {
    const findData = allRaindropsList?.find((el)=>el._id===id);
    if(findData?.joinUser?.length >0){
      setJoinUserData(findData?.joinUser)
    }
    
    setShowJoin(true);
  };

  useEffect(() => {
    dispatch(getRainDropData());
  }, [dispatch]);

  return (
    <Fragment>
      <Breadcrumb title='RainDrop Data' parent='Deposite' />
      <Container fluid={true}>
        <div className='admin-card'>
          <Card>
            <CardHeader>
              <h5>Rain Drop</h5>
            </CardHeader>
            <CardBody>
              <Datatablelist
                handleShowTip={handleShowTip}
                showTip={showTip}
                setShowTip={setShowTip}
                setShowJoin={setShowJoin}
                showJoin={showJoin}
                handleShowJoin={handleShowJoin}
                allRaindropsList={allRaindropsList}
                joinUserData={joinUserData}
                tipUserData={tipUserData}
                setJoinUserData={setJoinUserData}
                setTipUserData={setTipUserData}
              />
            </CardBody>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
}

export default RainDrop;
