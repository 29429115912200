export const queryString = () => {
    let query = window.location.search.substr(1);
    let resultval = {};
    query.split('&').forEach((part) => {
      let item = part.split('=');
      resultval[item[0]] = decodeURIComponent(item[1]);
    });
    return resultval;
  };

  export const add3Dots = (string, limit) =>
   {
  let dots = "...";
  if(string.length > limit)
  {
    // you can also use substr instead of substring
    string = string.substring(0,limit) + dots;
  }
 
    return string;
}

export const searchData = () => {
  ('#example').DataTable({
      searchPanes: {
          panes: [
              {
                  header: 'Custom',
                  options: [
                      {
                          label: 'Accountants from Tokyo',
                          value: function(rowData, rowIdx) {
                              return rowData[1] === 'Accountant' && rowData[2] === 'Tokyo';
                          },
                          className: 'tokyo'
                      }
                  ],
                  dtOpts: {
                      searching: false,
                      order: [[1, 'desc']]
                  }
              }
          ]
      },
      dom: 'Plfrtip'
  });
};



// export const searchDatatable =( settings, data, dataIndex ) => {
//       var min = parseInt(('#min').val(), 10 );
//       var max = parseInt(('#max').val(), 10 );
//       var age = parseFloat( data[3] ) || 0; // use data for the age column

//       if ( ( isNaN( min ) && isNaN( max ) ) ||
//            ( isNaN( min ) && age <= max ) ||
//            ( min <= age   && isNaN( max ) ) ||
//            ( min <= age   && age <= max ) )
//       {
//           return true;
//       }
//       return false;
//   }

// (document).ready(function() {
//   var table = ('#example').DataTable();
   
//   // Event listener to the two range filtering inputs to redraw on input
//   ('#min, #max').keyup( function() {
//       table.draw();
//   } );
// } );