import React, { Fragment} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-toastify/dist/ReactToastify.css";
const TotalBattlesTable = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
  setOpen2,
  setOpen3,
  setOpen4,
  setActiveUser,
}) => {


  const columnDefs = [
		{
			name: 'Players',
			selector: (row) => row.players,
			sortable: true,
		},
		{
			name: 'Rounds',
			selector: (row) => row.round,
			sortable: true,
		},
		{
			name: 'Contain boxes',
			selector: (row) => row?.selectedBoxes.length,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row?.status,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.joinType,
			sortable: true,
		},
		{
			name: 'Total Price',
			selector: (row) => row?.totalbattleCost?.toFixed(2),
			sortable: true,
		},
	];

  return (
    <div>
      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={myData}
          print={false}
          export={false}
        >
          <DataTable
            data={myData}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};

export default TotalBattlesTable;
