/* eslint-disable arrow-body-style */
import axios from 'axios';
import {serverPath} from './keys';

// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line import/prefer-default-export
export const withoutAuth = () => {
  return axios.create({
    baseURL: `${serverPath}`,
  });
};

export const adminInstance = () => {
  return axios.create({
    baseURL: serverPath,
    headers: {
      Authorization: localStorage.getItem('ad@#t')
        ? `${'Bearer '}${localStorage.getItem('ad@#t')}`
        : ''
    },
    timeout: 1000 * 20,
  });
};

