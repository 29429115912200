import React, { Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify'; // ToastContainer,
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//import { yupResolver } from "@hookform/resolvers"
import { yupLoginValidation } from '../../utils/authValidation';
import { withoutAuth } from '../../config/axios';
const LoginTabset = () => {
	// const history = useHistory()
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(yupLoginValidation()),
	});
	const onSubmitHandler = async (data) => {
		try {
			if (Object.keys(errors).length === 0) {
				data.email = data?.email?.toLowerCase();
				const response = await withoutAuth().post('/api/admin/login', data);
				const { token, code, msg } = response.data;
				if (code === 200) {
					toast.success(msg);
					if (token) {
						localStorage.setItem('ad@#t', token);
						// history.push(`/dashboard`)
						window.location.href = '/dashboard';
						reset();
					}
				} else {
					toast.error(msg);
				}
			}
		} catch (error) { }
	};
	const clickActive = (event) => {
		document.querySelector('.nav-link').classList.remove('show');
		event.target.classList.add('show');
	};
	return (
		<div>
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon login-tab">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Login
						</Tab>
						{/* <Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab> */}
					</TabList>

					<TabPanel className="login-tab-page">
						<Form
							className="form-horizontal auth-form"
							onSubmit={handleSubmit(onSubmitHandler)}
						>
							<FormGroup>
								<input
									{...register('email')}
									type="text"
									className="form-control"
									placeholder="Email"
								/>
								<p className="text-danger">{errors.email?.message}</p>
							</FormGroup>
							<FormGroup>
								<input
									{...register('password')}
									type="password"
									className="form-control"
									placeholder="Password"
								/>
								<p className="text-danger">{errors.password?.message}</p>
							</FormGroup>
							{/* <div className='form-terms'>
                <div className='custom-control custom-checkbox mr-sm-2'>
                  <Input
                    type='checkbox'
                    className='custom-control-input'
                    id='customControlAutosizing'
                  />
                  <Label className='d-block'>
                    <Input
                      className='checkbox_animated'
                      id='chk-ani2'
                      type='checkbox'
                    />
                    Reminder Me{' '}
                    <span className="pull-right">
											{" "}
											<a href="/#" className="btn btn-default forgot-pass p-0">
												Forget Password
											</a>
										</span>
                  </Label>
                </div>
              </div> */}
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								// onClick={() => routeChange()}
								>
									Login
								</Button>
							</div>
							{/* <div className="form-footer">
								<span>Or Login up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div> */}
						</Form>
					</TabPanel>
					<TabPanel>
						<Form className="form-horizontal auth-form">
							<FormGroup>
								<Input
									required=""
									name="login[username]"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail12"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Password"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Confirm Password"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox mr-sm-2">
									<Input
										type="checkbox"
										className="custom-control-input"
										id="customControlAutosizing"
									/>
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										I agree all statements in{' '}
										<span>
											<a href="/#">Terms &amp; Conditions</a>
										</span>
									</Label>
								</div>
							</div>
							{/* <div className='form-button'>
                <Button
                  color='primary'
                  type='submit'
                  onClick={() => routeChange()}>
                  Register
                </Button>
              </div> */}
							{/* <div className="form-footer">
								<span>Or Sign up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div> */}
						</Form>
					</TabPanel>
				</Tabs>
				{/* <ToastContainer /> */}
			</Fragment>
		</div>
	);
};

export default withRouter(LoginTabset);
