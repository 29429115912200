import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//import { useHistory } from 'react-router-dom';
const AdminRoute = ({ component: Component, ...rest }) => {
	/* 	const history = useHistory();
	let chekPer = newRole?.some((r) =>
		[
			'suUsers',
			'superAdmin',
			'readSetting',
			'writeSetting',
			'delSetting',
		].includes(r)
	); */
	return (
		<Route
			{...rest}
			render={(props) => {
				let path = <Redirect to="/" />;
				if (localStorage.getItem('ad@#t')) {
					path = <Component {...props} {...rest} />;
				} /* else if (!chekPer && pathName.includes('setting')) {
					history.push('/dashboard');
				} */ else {
					path = <Redirect to="/" />;
				}
				return path;
			}}
		/>
	);
};
export default AdminRoute;
