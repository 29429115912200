import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from "moment";
import { useDispatch } from "react-redux";
import DeleteConfirmForSelected from "../common/deleteConfirmForSelected";
import {
  deleteSelectedLogs,
  getAllActivityLogsData,
} from "../../reducers/adminReducers";
const ListDatatable = ({
  myData,
  myClass,
  setActiveId,
  setOpen,
  setBoxLists,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  // const result = useSelector((state) => state.admin);
  // const { loginAdmin } = result || {};
  // const { role } = loginAdmin || [];
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(true);
  useEffect(() => {
    setData(myData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myData]);
  const columnDefs = [
    {
      name: "Name",
      selector: (row) => `${row?.adminId?.firstname} ${row?.adminId?.lastname}`,
      sortable: true,
    },
    {
      name: "Action Perform",
      selector: (row) => row.action,
      sortable: true,
    },
    {
      name: "Module Name",
      selector: (row) => row?.module,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Date/Time",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
      sortable: true,
    },
    // {
    // 	name: 'Action',
    // 	cell: (row) => (
    // 		<ActionButton row={row} handleDelete={handleDelete} role={role} />
    // 	),
    // },
  ];
  // const handleDelete = (id) => {
  // 	setActiveId(id);
  // 	setOpen(true);
  // };
  const handleSelectBox = (row) => {
    let selectedPro = [];
    if (!row.allSelected) {
      row?.selectedRows?.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedLogs(selectedPro);
      setSelectedRows(true);
    }
    if (row.allSelected) {
      row?.selectedRows?.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedLogs(selectedPro);
      setSelectedRows(true);
    }
  };
  const handleSelectedDelete = async (e, id) => {
    e.preventDefault();
    const res = await dispatch(deleteSelectedLogs(id));
    const { resultData } = res?.payload;
    if (resultData?.code === 200) {
      dispatch(getAllActivityLogsData());
      setOpenDelPopup(false);
      setSelectedRows(false);
    }
  };

  const onCloseModal = () => {
    setOpenDelPopup(false);
  };
  // const selectDeleteOpen = (e) => {
  // 	e.preventDefault();
  // 	if (selectedLogs.length > 0) {
  // 		setOpenDelPopup(true);
  // 	}
  // };

  return (
    <div className="activity-log-table">
      {/* {role?.some((r) =>
				['suUsers', 'superAdmin', 'delActivityLog'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)} */}

      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            selectableRows
            clearSelectedRows={selectedRows}
            onSelectedRowsChange={(e) => handleSelectBox(e)}
          />
        </DataTableExtensions>
      </Fragment>
      <DeleteConfirmForSelected
        open={openDelPopup}
        handleSelectedDelete={handleSelectedDelete}
        onCloseModal={onCloseModal}
        id={selectedLogs}
      />
    </div>
  );
};

// const ActionButton = ({ row, handleDelete, role }) => {
// 	const delRole = role?.some((r) =>
// 		['suUsers', 'superAdmin', 'delActivityLog'].includes(r)
// 	);
// 	return (
// 		<div className="action-btn">
// 			<span>
// 				<i
// 					onClick={() =>
// 						delRole ? handleDelete(row._id) : toastDelPermission('Logs')
// 					}
// 					className="fa fa-trash"
// 					style={{
// 						width: 35,
// 						fontSize: 20,
// 						padding: 11,
// 						color: '#e4566e',
// 					}}
// 				></i>
// 			</span>
// 		</div>
// 	);
// };
export default ListDatatable;
