import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
//import placeholder from '../../assets/images/fashion/product/12.jpg';
//import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')
// console.log('dateheck.....', dateheck)
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toastDelPermission, toastEditPermission } from '../../utils/toastPermission'
import {
	deleteSelectedDeliveryMethod,
	getAllShippingData,
	//getShippingDetailById
} from '../../reducers/adminReducers';
import { adminInstance } from '../../config/axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import UpdateShippingType from './updateShippingType';
const ShippingListDataTable = ({ myData, myClass, handleAddDeliveryMethod, setOpenEditDelivery, openEditDelivery,
	setDeliveryMethod, deliveryMethod }) => {
	// console.log("Data",myData);
	const updInitialState = {
		shippingName: ''
	}
	//const history = useHistory();
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [data, setData] = useState();
	useEffect(() => {
		setData(myData);
	}, [myData]);
	const [selectedShip, setSelectedShip] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [updateShipping, setUpdateShipping] = useState(updInitialState)
	const columnDefs = [
		{
			name: 'Delivery',
			selector: (row) => row.shippingName,
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => moment(row?.updatedAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					role={role}
				/>
			),
		},
	];
	const handleEdit = (id) => {
		setDeliveryMethod(deliveryMethod)
		setActiveId(id);
		setOpenEditDelivery(true)
		// history.push(`/updateShipping?id=${id}`);
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
	};
	const handleSelectUsers = (row) => {
		let selectedTeams = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});
			setSelectedShip(selectedTeams);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});
			setSelectedShip(selectedTeams);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		await dispatch(deleteSelectedDeliveryMethod(id));
		setOpenDelPopup(false);
		setSelectedRows(false);
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
		setOpen(false)
		setOpenEditDelivery(false)
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedShip.length > 0) {
			setOpenDelPopup(true);
		}
	};
	const handleOnDelete = async (id) => {
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/RemoveDeliveryMethod/${id}`
			);
			const { code } = response.data;
			if (code === 200) {
				dispatch(getAllShippingData());
				toast.success('Successfully Deleted');
			}
		} catch (error) { }
	};
	const handleEditDeliveryMethod = async (id) => {
		try {
			setOpenEditDelivery(false);
			// console.log('hitting this with',id)
			const payload = {
				id,
				shippingName: updateShipping.shippingName,
			};
			// console.log('payload', payload);
			const response = await adminInstance().put(
				'api/admin/updateShippingDelivery',
				payload
			);
			const { msg, code } = response?.data;
			if (code === 200) {
				toast.success(msg);
				dispatch(getAllShippingData());
				setUpdateShipping('')
			} else {
				toast.error(msg);
			}
		} catch (error) {
			console.log('error', error);
		}
	};
	return (
		<div>
			<UpdateShippingType
				open={openEditDelivery}
				handleUpdate={handleEditDeliveryMethod}
				onCloseModal={onCloseModal}
				id={activeId}
				updateShipping={updateShipping}
				setUpdateShipping={setUpdateShipping}
				title="Update Delivery Method"
			/>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedShip}
			/>
			<DeleteConfirmPopup
				title={'Delete User'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delDelivery', 'delPreferences'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)}
			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectUsers(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};

const ActionButton = ({ row, handleDelete, handleEdit, role }) => {
	const delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delDelivery', 'delPreferences'].includes(r)
	);
	const editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeDelivery', 'writePreferences'].includes(r)
	);
	return (
		<div className="action-btn">
			<span>
				<i
					onClick={() =>
						delRole ? handleDelete(row._id) : toastDelPermission('delivery method')
					}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>
			<span>
				<i
					onClick={() =>
						editRole ? handleEdit(row._id) : toastEditPermission('delivery method')
					}
					className="fa fa-pencil"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: 'rgb(40, 167, 69)',
					}}
				></i>
			</span>
		</div>
	);
};
export default ShippingListDataTable;
