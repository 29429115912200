import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
//import placeholder from '../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')
// console.log('dateheck.....', dateheck)
// import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import DeleteConfirmPopup from '../../common/deleteConfirmPopup';
import { toastDelPermission, toastEditPermission } from '../../../utils/toastPermission'
// import {
// 	deleteSelectedDeliveryMethod,
// 	getAllShippingData,
// 	//getShippingDetailById
// } from '../../reducers/adminReducers';
// import { adminInstance } from '../../config/axios';
import moment from 'moment';
// import { toast } from 'react-toastify';

const FaqListDataTable = ({ myData, handleOnDelete
}) => {
	console.log("Data", myData);
	// const updInitialState = {
	// 	name: ''
	// }
	const history = useHistory();
	// const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [data, setData] = useState();
	useEffect(() => {
		setData(myData);
	}, [myData]);
	// const [selectedShip, setSelectedShip] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	// const [updateShipping, setUpdateShipping] = useState(updInitialState)
	const [openEditDelivery, setOpenEditDelivery] = useState(false);
	const columnDefs = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => moment(row?.updatedAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					role={role}
				/>
			),
		},
	];
	console.log('check...', openDelPopup, openEditDelivery)
	const handleEdit = (id) => {
		setActiveId(id);
		setOpenEditDelivery(true)
		history.push(`/faq-addcategory?id=${id}`);
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
		console.log(id, 'delete');
	};
	const handleSelectUsers = (row) => {
		let selectedTeams = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});

			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});
			// setSelectedShip(selectedTeams);
			setSelectedRows(true);
		}
	};
	// const handleSelectedDelete = async (e, id) => {
	// 	e.preventDefault();
	// 	handleDelete()
	// 	setOpenDelPopup(false);
	// 	setSelectedRows(false);
	// };
	const onCloseModal = () => {
		setOpenDelPopup(false);
		setOpen(false)
		setOpenEditDelivery(false)
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		setOpenDelPopup(true);
		// if (selectedShip.length > 0) {
		// 	setOpenDelPopup(true);
		// }
	};


	return (
		<div>

			<DeleteConfirmPopup
				title={'Delete User'}
				open={open}
				// handleSelectedDelete={handleSelectedDelete}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delDelivery'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)}
			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={myData}
						columns={columnDefs}
						// className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectUsers(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};

const ActionButton = ({ row, handleDelete, handleEdit, role }) => {
	const delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delDelivery'].includes(r)
	);
	const editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeDelivery'].includes(r)
	);
	return (
		<div className="action-btn">
			<span>
				<i
					onClick={() =>
						delRole ? handleDelete(row._id) : toastDelPermission('Faq method')
					}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>
			<span>
				<i
					onClick={() =>
						editRole ? handleEdit(row._id) : toastEditPermission('Faq method')
					}
					className="fa fa-pencil"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: 'rgb(40, 167, 69)',
					}}
				></i>
			</span>
		</div>
	);
};
export default FaqListDataTable;
