import React, { Fragment } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { Button, Form, FormGroup, Label, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupBoxValidation } from "../../utils/authValidation";
//import { useHistory } from 'react-router-dom';
import defaultproductimg from "../../assets/images/product-list/1.jpg";
import ReactPaginate from "react-paginate";
import { getBoxPrice } from "../../utils/multiuseFunctions";
import { queryString } from "../../utils/queryString";
// import { singleboxPriceCalculation } from './../../utils/multiuseFunctions';
//import DateTimePicker from 'react-datetime-picker';
const AddBattleForm = ({
  allBoxList,
  battleObj,
  handleCount,
  addBoxes,
  handleCross,
  handleChange,
  handleCreateBattle,
  // onChange,
  // value,
}) => {
  const { id } = queryString();
  const { handleSubmit } = useForm({
    mode: "onBlur",
    // defaultValues: payload || {},
    resolver: yupResolver(yupBoxValidation()),
  });

  const players = ["2", "3", "4", "2 x 2"];

  //const [editData, setEditData] = useState();
  const onSubmitHandler = async (data) => {
    try {
    } catch (error) {}
  };
  // const [selectedFile, setSelectedFile] = useState();
  // const [preview, setPreview] = useState();
  // useEffect(() => {
  // 	if (!selectedFile) {
  // 		console.log(preview)
  // 		setPreview(undefined);
  // 		return;
  // 	}

  // 	const objectUrl = URL.createObjectURL(selectedFile);
  // 	setPreview(objectUrl);

  // 	return () => URL.revokeObjectURL(objectUrl);
  // }, []);

  // const onSelectFile = (e) => {
  // 	if (!e.target.files || e.target.files.length === 0) {
  // 		setSelectedFile(undefined);
  // 		return;
  // 	}
  // 	setSelectedFile(e.target.files[0]);
  // };
  // const handleOnChangeVisibility = (e) => {
  // 	console.log('e.target value', e.target);
  // };
  return (
    <Fragment className="addbox-form">
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl="12">
                    <FormGroup className="justify-content-start">
                      <div className="create-battle-section">
                        <Label className="col-form-label pt-0">
                          <span>*</span> Players
                        </Label>
                        <select
                          class="category-search"
                          name="players"
                          value={battleObj?.players}
                          onChange={handleChange}
                        >
                          <option value=""> Choose Players </option>
                          {players.map((el) => (
                            <option value={el}>{el}</option>
                          ))}
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup className="justify-content-start">
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="joinType"
                            onChange={handleChange}
                            value="public"
                            checked={battleObj?.joinType === "public"}
                            // checked={battleObj?.}
                          />
                          {/* <p className="text-danger">
														{errors.freeBox?.message}
													</p> */}
                          Public
                        </Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="joinType"
                            onChange={handleChange}
                            value="private"
                            checked={battleObj?.joinType === "private"}
                          />
                          {/* <p className="text-danger">
														{errors.freeBox?.message}
													</p> */}
                          Private
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  {battleObj?.joinType === "private" && (
                    <div className="col-xl-6 col-md-6">
                      <div>
                        <Label className="col-form-label pt-0">
                          <span>*</span>
                          {id && battleObj?.joinType === "private"
                            ? "Update Password"
                            : "Password"}
                        </Label>
                        <input
                          name="battlePassword"
                          className="form-control"
                          type="text"
                          value={battleObj?.battlePassword}
                          onChange={handleChange}
                          // defaultValue={payload.name || ''}
                        />
                        <p className="text-danger err-message">
                          {/* {errors.name?.message} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {battleObj?.joinType === "private" && (
                    <div className="col-xl-6 col-md-6">
                      <div>
                        <Label className="col-form-label pt-0">
                          <span>*</span>Confirm Password
                        </Label>
                        <input
                          name="battleConfirmPass"
                          className="form-control"
                          type="text"
                          value={battleObj?.battleConfirmPass}
                          onChange={handleChange}
                          // defaultValue={payload.name || ''}
                        />
                        <p className="text-danger err-message">
                          {/* {errors.name?.message} */}
                        </p>
                      </div>
                    </div>
                  )}
                </Row>

                <FormGroup className="search_boxes_section search-box-section justify-content-center">
                  <h4 className="choose-boxes-heading">Choose Boxes</h4>
                  <div className="searched-content-section">
                    <div class="row">
                      {allBoxList?.map((el) => (
                        <div className="col-md-2">
                          <div className="search-list">
                            <div
                              className="middle-btn-selected"
                              onClick={() => addBoxes(el, el.profitMargin)}
                            >
                              <span>Selected</span>
                            </div>
                            <img
                              src={el.image ? el.image : defaultproductimg}
                              alt="No ImGE fOUND"
                            />
                            <h3 className="search-title">{el.name}</h3>
                            <p className="search-price">
                              {getBoxPrice(el.boxItems, el.profitMargin)} $
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div class="row">
                      <div className="col-xl-12">
                        <div class="text-center search-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=""
                            // onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            // pageCount={pageCount}
                            previousLabel=""
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="row justify-content-center">
                  {battleObj?.selectedBoxes?.map((el, index) => (
                    <div className="addbox-input col-xl-12 col-md-12">
                      <div className="search-section">
                        <div className="choose-odds">
                          <div className="row">
                            <div class="col-md-6">
                              <div className="choose-odds-content">
                                <div className="odds-icon">
                                  <img
                                    src={el?.box?.image || defaultproductimg}
                                    alt=""
                                    hight="50"
                                    width="50"
                                  />
                                </div>
                                <div>
                                  <h5>{el?.box?.name || ""}</h5>
                                </div>
                                <div>
                                  <span>$ {el?.price || ""}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div className="choose-odds-content">
                                <div>
                                  <span>
                                    {/* <input
																				type="text"
																				name="addonBlance"
																				placeholder="0.00"
																				// value={singleboxPriceCalculation(
																				// 	chooseProduct[index],
																				// 	boxProfit
																				// )}
																			/> */}
                                  </span>
                                </div>
                                <div>
                                  <div className="increment">
                                    <span
                                      onClick={() =>
                                        handleCount(
                                          el?.box,
                                          "minus",
                                          el?.box?.profitMargin
                                        )
                                      }
                                    >
                                      <i class="fa fa-minus-circle"></i>
                                    </span>
                                    <span className="text-center">
                                      {el.round}
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleCount(
                                          el?.box,
                                          "plus",
                                          el?.box?.profitMargin,
                                          el
                                        )
                                      }
                                    >
                                      <i class="fa fa-plus-circle"></i>
                                    </span>
                                  </div>
                                  {/* <input
                                      type='number'
                                      name='chance'
                                      onChange={(e) =>
                                        handleOnchangePro(e.target, index)
                                      }
                                      value={chooseProduct[index].chance || ''}
                                      placeholder='0.00'
                                      step="0.0001" 

                                    /> */}
                                  <Button
                                    className="del-icon"
                                    onClick={() => handleCross(el.box._id)}
                                  >
                                    <i class="fa fa-trash"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </FormGroup>
                <div className="form-button d-flex justify-content-between submit-btns pt-3">
                  <div className="submit-btns-inner">
                    <Button
                      color="primary"
                      type="button"
                      className="mr-3 md-mb-3 perc_btn"
                    >
                      Total Battle Price :{" "}
                      <span>
                        {battleObj?.totalbattleCost?.toFixed(2)}&nbsp;$
                      </span>
                    </Button>

                    <Button color="primary" type="button" className="perc_btn">
                      Total Rounds : <span>{battleObj?.round}</span>
                    </Button>
                  </div>
                  <div className="justify-content-end sbmit-btn">
                    <Button
                      color="primary"
                      type="submit"
                      className="perc_btn"
                      onClick={handleCreateBattle}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
export default AddBattleForm;
