import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { adminInstance } from "../../config/axios";
import ListDatatable from "./listDatatable";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { toast } from "react-toastify";
import "../../assets/styles/admin.css";
import { useSelector } from "react-redux";
import Loader from "../loader/loader";
const Productbrand = () => {
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  const [BrandsLists, setBrandsLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  const [dummy, setDummy] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAllBrandsData = async () => {
    try {
      setLoading(true);
      const response = await adminInstance().get("api/admin/getBrand");
      const { code, brand } = response.data;
      if (code === 200) {
        setLoading(false);
        setBrandsLists(brand);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllBrandsData();
  }, [dummy]);
  const handleOnDelete = async (id) => {
    setDummy(false);
    try {
      setOpen(false);
      const response = await adminInstance().delete(
        `api/admin/deleteBrand/${id}`
      );
      setDummy(true);
      const { code } = response.data;
      if (code === 200) {
        toast.success("Successfully Deleted ", { toastId: 1 });
      } else {
        setDummy(false);
      }
    } catch (error) {}
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Breadcrumb title="Brand List" parent="Box" />
      <Container fluid={true}>
        <div className="admin-card">
          <Card>
            <CardHeader>
              <h5>Brand Details</h5>
            </CardHeader>
            <CardBody>
              {role?.some((r) =>
                ["suUsers", "superAdmin", "writeBrand"].includes(r)
              ) && (
                <div className="btn-popup pull-right ">
                  <Link to="/create-brand" className="btn btn-secondary">
                    Create Brand
                  </Link>
                </div>
              )}

              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                {!loading ? (
                  BrandsLists && BrandsLists.length > 0 ? (
                    <ListDatatable
                      multiSelectOption={true}
                      myData={BrandsLists}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                      setActiveId={setActiveId}
                      setOpen={setOpen}
                      setBrandsLists={setBrandsLists}
                    />
                  ) : (
                    <h1>No result found</h1>
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
      <DeleteConfirmPopup
        title={"Delete Category"}
        open={open}
        handleOnDelete={handleOnDelete}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};
export default Productbrand;
