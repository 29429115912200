import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {
	getAllBoxesData,
	createBattle,
	getBattleById,
	updateBattles,
} from '../../reducers/adminReducers';

import AddBattleForm from './addBattleForm.js';
import { queryString } from '../../utils/queryString';
import { useSelector, useDispatch } from 'react-redux';
import { getBoxPrice } from '../../utils/multiuseFunctions';
import { toast } from 'react-toastify';
import { validatePassWord } from '../../utils/authValidation';
import { useHistory } from 'react-router-dom';

const AddBattle = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	// const [open, setOpen] = useState(false);
	const { id } = queryString();
	// const history = useHistory()
	const result = useSelector((state) => state.admin);
	const { userDetails, allBoxList, battleData } = result || {};
	const { walletAmount } = userDetails || {};

	//console.log('userr==>', walletAmount)
	const initialObj = {
		// name:'',
		userId: '',
		round: 0,
		selectedBoxes: [],
		players: '2',
		totalbattleCost: 0,
		joinType: 'public',
		battlePassword: '',
		battleConfirmPass: '',
		selectedNewBoxes: [],
	};
	const [battleObj, setBattleObj] = useState(initialObj);
	const [show, setShow] = useState(false);
	// const [total, setTotal] = useState({
	//   price: 0,
	//   round: 0,
	// });
	// const handleActive = e => {
	//   setBattleObj({...battleObj,players:e})
	// }

	// const handleOpen = () => {
	//   setOpen(true)
	// }

	// const onCloseModal = () => {
	//   setOpen(false)
	// }
	const calculating = (data) => {
		const newObj = data.reduce(
			(prev, next) => {
				prev['price'] = prev['price'] + parseFloat(next['price']);
				prev['round'] = prev['round'] + next['round'];
				return prev;
			},
			{ price: 0, round: 0 }
		);
		console.log('newObj', newObj);
		//setTotal(newObj)
		return newObj;
		// setBattleObj({...battleObj,totalbattleCost:newObj?.price,round:newObj?.round})
	};

	const addBoxes = (data, boxProfit) => {
		console.log('okkk');
		const ss = battleObj?.selectedBoxes.slice();
		
		const boxPrice = getBoxPrice(data?.boxItems, boxProfit);
		const fIndex = ss.findIndex((el) => el.box._id === data._id);
		if (fIndex === -1) {
			const obj = {
				box: '',
				round: '',
				price: '',
			};
			obj.box = data;
			obj.round = 1;
			obj.price = boxPrice;
			ss.push(obj);
		} else {
			ss[fIndex].round += 1;
			ss[fIndex].price = (boxPrice * ss[fIndex].round).toFixed(2);
		}

		console.log('fIndex', fIndex, ss);
		const newObj = calculating(ss);

		setBattleObj({
			...battleObj,
			selectedBoxes: ss,
			totalbattleCost: newObj?.price,
			round: newObj?.round,
		});
	};

	const handleCount = (data, type, profit, full) => {
		console.log('couuu---', full);
		const ss = battleObj?.selectedBoxes.slice();
		const boxPrice = getBoxPrice(data?.boxItems, profit);
		const fIndex = ss.findIndex((el) => el?.box?._id === data?._id);
		if (fIndex >= 0) {
			if (ss[fIndex].round >= 1) {
				type === 'minus'
					? ss[fIndex].round !== 1
						? (ss[fIndex].round -= 1)
						: (ss[fIndex].round = 1)
					: (ss[fIndex].round += 1);
			}
			ss[fIndex].price = (boxPrice * ss[fIndex].round).toFixed(2);
		}
		console.log('sss', ss);
		const newObj = calculating(ss);
		setBattleObj({
			...battleObj,
			selectedBoxes: ss,
			totalbattleCost: newObj?.price,
			round: newObj?.round,
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log('name===>>', name, value);
		//  value =  battleObj.joinType ==='public' ? 'private':'public';
		setBattleObj({ ...battleObj, [name]: value });
	};
	const handleCreateBattle = () => {
		//console.log('battleObj.selectedBoxes=>', battleObj.selectedBoxes)
		const { totalbattleCost, joinType } = battleObj || {};
		const copySelectedBox = battleObj.selectedBoxes.slice();
		const newData = copySelectedBox.map((el) => ({
			boxId: el?.box?._id,
			image: el?.box?.image,
			boxName: el?.box?.name,
			price: el?.price,
			round: el?.round,
		}));
		battleObj.selectedNewBoxes = newData;
		if (newData.length === 0) {
			return toast.error('Please Select Boxes for Battle');
		}
		if (parseFloat(totalbattleCost) > parseFloat(walletAmount)) {
			return toast.error(
				'Not sufficient amount in your wallet to create this battle.',
				{
					toastId: 'battles',
				}
			);
		}
		if (joinType === 'private') {
			const payload = {
				password: battleObj?.battlePassword,
				confirmPassword: battleObj?.battleConfirmPass,
			};
			const validData = validatePassWord(payload);
			if (!validData.isValid) {
				toast.error('Please add valid password!', {
					toastId: 'battlespass',
				});
				handleBattlePassOpen();
				return;
			}
		}
		console.log('battleObj=>', battleObj);
		if (!id) {
			dispatch(createBattle(battleObj));
		} else {
			dispatch(updateBattles(battleObj));
		}

		// const payload = {
		//   userId: userDetails?._id,
		//   amount:parseFloat(totalbattleCost.toFixed(2)),
		//   rollType:'1',
		//   boxId:'',
		//   debitCreditType:'debit',
		//   paymentType:'wallet',
		//   description:'Create Battle',
		//   descriptionMessage:'Deduct From Your wallet '
		// }
		//   dispatch(updateUserWallet(payload))
		history.push('/battle-list');
	};

	const handleCross = (idd) => {
		console.log('cross');
		const ss = battleObj?.selectedBoxes.slice();
		const fIndex = ss.findIndex((el) => el?.box?._id === idd);
		if (fIndex >= 0) {
			ss.splice(fIndex, 1);
		}
		const newObj = calculating(ss);
		setBattleObj({
			...battleObj,
			selectedBoxes: ss,
			totalbattleCost: newObj?.price,
			round: newObj?.round,
		});
	};
	const handleBattlePassOpen = () => {
		setShow(!show);
	};
	//   const handleChangePass = (e) =>{
	//     const {name, value} = e.target;
	//     setBattleObj({...battleObj,[name]:value})
	// }

	useEffect(() => {
		if (id) {
			dispatch(getBattleById(id));
		}
		dispatch(getAllBoxesData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (id && battleData) {
			const copy = JSON.stringify(battleData);
			setBattleObj(JSON.parse(copy));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [battleData]);

	console.log('battleObj', battleObj);
	return (
		<Fragment>
			<Breadcrumb title="Battle" parent="Battle" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className="addbox-form">
							<CardHeader>
								<h5> Add Battle </h5>
							</CardHeader>
							<CardBody>
								{id ? (
									battleObj?._id && (
										<AddBattleForm
											battleObj={battleObj}
											allBoxList={allBoxList}
											addBoxes={addBoxes}
											handleCount={handleCount}
											handleCross={handleCross}
											handleChange={handleChange}
											handleCreateBattle={handleCreateBattle}
										/>
									)
								) : (
									<AddBattleForm
										battleObj={battleObj}
										allBoxList={allBoxList}
										addBoxes={addBoxes}
										handleCount={handleCount}
										handleCross={handleCross}
										handleChange={handleChange}
										handleCreateBattle={handleCreateBattle}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AddBattle;
