import * as Yup from 'yup';
export const AddTeamValidationSchema = Yup.object({
	firstName: Yup.string()
		.matches(/^[A-Z a-z]{3,}$/, 'Enter only character')
		.required('First name field is required'),
	lastName: Yup.string()
		.matches(/^[A-Z a-z]{3,}$/, 'Enter only character')
		.required('Last name field is required'),
	email: Yup.string()
		.matches(
			/^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/,
			'Email not valid'
		)
		.required('Email Field is required'),
	password: Yup.string()
		// .matches(
		// 	/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
		// 	'Must contain 8 characters, One uppercase, One lowercase, One number and one special case character'
		// )
		.required('Password field required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Password must be match')
		.required('Confirm field required'),
	// documentPic: Yup.mixed().required('A file is required'),
	//profilePic: Yup.mixed().required('Profile Picture is required'),
});
export const updateTeamValidationSchema = Yup.object({
	firstName: Yup.string()
		.matches(/^[A-Z a-z]{3,}$/, 'Enter only character')
		.required('First name field is required'),
	lastName: Yup.string()
		.matches(/^[A-Z a-z]{3,}$/, 'Enter only character')
		.required('Last name field is required'),
	email: Yup.string()
		.matches(
			/^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/,
			'Email not valid'
		)
		.required('Email Field is required'),
});


export const AddAffiliateLevelValidationSchema = Yup.object({
	levelName: Yup.string().required('Level name field is required'),
	// levelDescription: Yup.string().required('Level Description field is required'),
	referrerCommissionType: Yup.string().required('Commission Type field is required'),
	referrerCommission: Yup.string().required('Commission field is required'),
	refereeCommissionType: Yup.string().required('Commission Type field is required'),
	refereeBonus: Yup.string().required('Bonus field is required'),
	bonusRule: Yup.string().required('Bonus rule field is required'),
	commissionRule: Yup.string().required('Commission Rule field is required'),
})
