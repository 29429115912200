import React from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
    FormGroup,
    Label,
} from 'reactstrap';
// import deleteimg from '../../assets/images/delete-icon.png';
import '../../assets/styles/deletePopup.css'
const AddWalletAmount = ({ onCloseModal, open, handleUpdate,id,showState,setState,title }) => {
	return (
		<Modal
			className="delete-modal-dialog delete_modal_dialog"
			isOpen={open}
			toggle={()=>onCloseModal('close')}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={()=>onCloseModal('close')}
			></ModalHeader>
			<ModalBody>
				
            <FormGroup className='form__group'>
                    <Label className="col-form-label add_title pt-0">
                      {title}
                    </Label>
                    <input
                      className={`form-control ${title === 'Add Wallet Amount' ?  'add_wallet':'pass_gene'}`}
                      type={title === 'Add Wallet Amount' ? 'number' :'text'}
                      value={showState}
                      onChange={(e)=>setState(e.target.value)}
                    />
                    
                  </FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={()=>handleUpdate(id)}
				>
					{title === 'Add Wallet Amount' ? 'Confirm' : 'Generate'}
				</Button>
				<Button
					type="button"
					color="secondary"
					className="cancel-button"
					onClick={onCloseModal}
				>
					{title === 'Add Wallet Amount' ? 'Cancel' : 'Send Mail'}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default AddWalletAmount;
