import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import defaultprofile from '../../../assets/images/defaultprofile.jpg';
import { useSelector } from 'react-redux';

const UserMenu = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { profile } = loginAdmin || {};
	const LogOut = () => {
		localStorage.removeItem('ad@#t');
	};
	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media align-items-center">
					<img
						className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
						src={profile || defaultprofile}
						alt="header-user"
					/>
					<div className="dotted-animation">
						<span className="animate-circle"></span>
						<span className="main-circle"></span>
					</div>
				</div>
				<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
					<li>
						<Link to="/settings?tab=profile">
							<i data-feather="user"></i>Profile
						</Link>
					</li>
					{/* <li>
						<a href="##">
							<i data-feather="mail"></i>Inbox
						</a>
					</li>
					<li>
						<a href="##">
							<i data-feather="lock"></i>Lock Screen
						</a>
					</li> */}
					{/* <li>
						<a href="##">
							<i data-feather="settings"></i>Settings
						</a>
					</li> */}
					<li>
						<Link to={`${process.env.PUBLIC_URL}/`} onClick={LogOut}>
							<i data-feather="log-out"></i>Logout
						</Link>
					</li>
				</ul>
			</li>
		</Fragment>
	);
};

export default UserMenu;
