import React from 'react';
//import { Input } from 'react-dropzone-uploader';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//images
import superAdmin from '../../assets/images/team/superAdmin.jpg';
const HandleConfirmAddTeam = ({ onCloseModal, open, handleConfirm }) => {
	return (
		<Modal
			className="delete-modal-dialog"
			isOpen={open}
			toggle={onCloseModal}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={onCloseModal}
			></ModalHeader>
			<ModalBody>
				<img className="blur-up lazyloaded" src={superAdmin} alt="delete" />
				<h3>
					Are you sure you want to give all the access to this user ?
					<br />
				</h3>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					checked="true"
					name="suUsers"
					style={{ background: '#ff4d53 !important' }}
					onClick={handleConfirm}
				>
					Confirm
				</Button>
				<Button
					type="button"
					color="secondary"
					className="cancel-button"
					onClick={onCloseModal}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default HandleConfirmAddTeam;
