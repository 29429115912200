import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import "react-toastify/dist/ReactToastify.css";
// import placeholder from "../../assets/images/fashion/product/12.jpg";
// import { useHistory } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')
// console.log('dateheck.....', dateheck)
const UserTransactions = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
  setOpen2,
  setOpen3,
  setOpen4,
  setActiveUser,
}) => {
  // console.log("MYDATA========>", myData)
  const [data, setData] = useState();

  useEffect(() => {
    setData(myData);

  }, [myData]);

  let i = 0;
  const columnDefs = [
    // {
    //   name: "Order_Id",
    //   selector: (row) => row.username,
    //   sortable: true,
    // },
    {
      name: "S.No",
      selector: (row) => i += 1,
      sortable: true,
    },
    {
      name: "Payment_Method",
      selector: (row) => row?.paymentType,
      sortable: true,
    },
    {
      name: "transaction type",
      selector: (row) => row?.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <ActionButton
    //       row={row}
    //       handleDelete={handleDelete}
    //       handleEdit={handleEdit}
    //       handleBlock={handleBlock}
    //       handleAddWallet={handleAddWallet}
    //       handleGeneratePassword={handleGeneratePassword}
    //       handleUserProfile={handleUserProfile}
    //     />
    //   ),
    // },
  ];

  // const handleEdit = (id) => {
  //   setActiveId(id);
  //   console.log("active id", id);
  //   history.push(`/users/create-user?id=${id}`);
  //   console.log(id, "edit");
  // };
  // const handleDelete = (id) => {
  //   setActiveId(id);
  //   setOpen(true);
  //   console.log(id, "delete");
  // };
  // const handleBlock = (id, data) => {
  //   setActiveUser(data);
  //   setActiveId(id);
  //   setOpen2(true);
  // };
  // const handleAddWallet = (id) => {
  //   setOpen3(true);
  //   setActiveId(id);
  // };
  // const handleGeneratePassword = (id) => {
  //   console.log("id", id);
  //   setOpen4(true);
  //   setActiveId(id);
  // };

  // const handleUserProfile = (id) =>{
  //   console.log('uidusodusoiduyou')
  //   history.push(`/user-profile-details?id=${id}`)
  // }

  return (
    <div>

      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};

// const ActionButton = ({
//   row,
//   handleDelete,
//   handleEdit,
//   handleBlock,
//   handleAddWallet,
//   handleGeneratePassword,
//   handleUserProfile,
// }) => {
//   return (
//     <div className="action-btn action_btn_dot">
//       <Dropdown>
//         <Dropdown.Toggle variant="success" id="dropdown-basic">
//           <i
//             className="fa fa-ellipsis-v"
//             style={{
//               width: 35,
//               fontSize: 20,
//               padding: 11,
//               color: "#e4566e",
//             }}
//           ></i>
//         </Dropdown.Toggle>
//         <Dropdown.Menu>
//           <Dropdown.Item>
//             <div className="action client" onClick={() => handleEdit(row._id)}>
//               <i
//                 className="fa fa-pencil"
//                 style={{
//                   fontSize: 17,
//                   color: "rgb(40, 167, 69)",
//                   marginRight: "7px",
//                 }}
//               ></i>
//               Edit
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleDelete(row._id)}
//             >
//               <i
//                 className="fa fa-trash"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#ef072e",
//                 }}
//               ></i>
//               Delete
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleBlock(row._id, row)}
//             >
//               <i
//                 className="fa fa-ban"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#ff002b",
//                 }}
//               ></i>
//               Active/Blocked
//             </div>
//           </Dropdown.Item>
//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleAddWallet(row._id)}
//             >
//               <i
//                 className="fa fa-briefcase"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#3B7FDB",
//                 }}
//               ></i>
//               Add Wallet
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleGeneratePassword(row._id)}
//             >
//               <i
//                 className="fa fa-unlock-alt"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#e89b32",
//                 }}
//               ></i>
//               Reset Password
//             </div>
//           </Dropdown.Item>
//           <Dropdown.Item>
//             {/* <Link to="/user-profile-details"> */}
//             <div
//               className="action client"
//               onClick={() => handleUserProfile(row._id)}
//             >
//               <i
//                 className="fa fa-user-circle-o"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#878787",
//                 }}
//               ></i>
//               User Profile
//             </div>
//             {/* </Link> */}
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     </div>
//   );
// };

export default UserTransactions;
