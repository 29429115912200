// import React from 'react'
import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

// import ListDatatable from './listDatatable';
// import DeleteConfirmPopup from '../../common/deleteConfirmPopup';
import FaqListDataTable from './listDatatable';
import { adminInstance } from '../../../config/axios';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllfaqData } from '../../../reducers/adminReducers';
// import { editFaqData } from '../../../reducers/adminReducers';
import Loader from '../../loader/loader';
const FaqManagement = () => {
	const result = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const { allFaqData } = result;
	// console.log("this is result of code STATUS", code)
	// console.log("this is result of GetAPI", result)
	console.log('allFAQData', allFaqData);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	// const [faqLists, setFaqLists] = useState([]);
	const [open, setOpen] = useState(false);
	console.log("Check open", open)
	const [activeId, setActiveId] = useState();
	//const [dummy, setDummy] = useState(false);
	const [data1, setData1] = useState();
	const [loading, setLoading] = useState(true)
	const getFaqCategorylist = async () => {
		const respnse = await dispatch(getAllfaqData());
		console.log('gffgdgfdfghdfdfgdfg', respnse?.payload)
		if (respnse?.payload) {
			setLoading(false)
		}
	};
	useEffect(() => {
		//setLoading(true)
		getFaqCategorylist();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log('I am getting triggered');
		setData1(allFaqData)
	}, [allFaqData])
	const handleOnDelete = async (id) => {
		//setDummy(false);
		try {
			setOpen(true)
			//dispatch(editFaqData(id));
			const response = await adminInstance().delete(
				`api/admin/deleteFaq/${id}`
			);
			//setDummy(true);
			console.log("DELETE RESPONSE ++++>>>>>>", response.data)
			const { code } = response.data;
			if (code === 200) {

				toast.success('Successfully Deleted', { toastId: 1 });
				setOpen(false);
			}
			//else {
			//setDummy(false);
			//}
		} catch (error) {
			console.log("ERROR IN DELETE ", error)
		}
	};
	// const onCloseModal = () => {
	// 	setOpen(false);
	// };
	console.log('ActiveId', activeId);
	console.log('thsi is the faq Data', data1);
	console.log('this is the loading', loading);
	return (
		<Fragment>
			<Breadcrumb title="Faq Management" parent="Faq" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>Add Faq</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeBrand'].includes(r)
							) && (<div className="btn-popup pull-right ">
								<Link to="/addfaq" className="btn btn-secondary">
									ADD
								</Link>
							</div>
								)}
							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									allFaqData && allFaqData.length > 0 ?
										<FaqListDataTable
											multiSelectOption={true}
											myData={allFaqData}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											handleOnDelete={handleOnDelete}
											setOpen={setOpen}
										// setFaqLists={setFaqLists}

										/>
										: <>{console.log('conwgduyefuefyetuft')}<h1>Data is not found</h1></>
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			{/* <DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/> */}
		</Fragment>
	)
}

export default FaqManagement
