import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import DataTableExtensions from 'react-data-table-component-extensions';
// import placeholder from '../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
// import { add3Dots } from '../../utils/queryString';
// import moment from 'moment';
// import { handleBoxPrice } from '../../utils/multiuseFunctions';
import { useSelector, useDispatch } from 'react-redux';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { deleteSelectedBattles } from '../../reducers/adminReducers';
// import { adminInstance } from '../../config/axios';
import {
	toastDelPermission,
	toastEditPermission,
} from '../../utils/toastPermission';
const ListDatatable = ({
	id,
	myData,
	myClass,
	multiSelectOption,
	pagination,
	setActiveId,
	setOpen,
	setBoxLists,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [data, setData] = useState();
	const result = useSelector((state) => state.admin);
	const { loginAdmin, adminSetting } = result || {};
	const { role } = loginAdmin || [];
	//const { settings } = loginAdmin || {};
	const { boxProfit } = adminSetting || {};
	const [selectedBox, setSelectedBox] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	useEffect(() => {
		// handleBoxPrice(myData);
		setData(myData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myData, boxProfit]);
	let delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delBattle'].includes(r)
	);
	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeBattle'].includes(r)
	);
	const columnDefs = [
		{
			name: 'Players',
			selector: (row) => row.players,
			sortable: true,
		},
		{
			name: 'Rounds',
			selector: (row) => row.round,
			sortable: true,
		},
		{
			name: 'Contain boxes',
			selector: (row) => row?.selectedBoxes.length,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row?.status,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.joinType,
			sortable: true,
		},
		// {
		// 	name: 'Join Users',
		// 	selector: (row) => row?.joinUser.length-1,
		// 	sortable: true,
		// },
		{
			name: 'Total Price',
			selector: (row) => row?.totalbattleCost?.toFixed(2),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					role={role}
					delRole={delRole}
					editRole={editRole}
				/>
			),
		},
	];
	useEffect(() => {
		const check = delRole || editRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])
	const handleEdit = (id) => {
		setActiveId(id);
		history.push(`/create-battle?id=${id}`);
		// console.log(id, 'edit');
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
		// console.log(id, 'delete');
	};
	const handleSelectBox = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		// console.log('sekejhfjkdfd', id);
		const res = await dispatch(deleteSelectedBattles(id));
		const { resultData } = res?.payload;
		if (resultData?.data?.code === 200) {
			setOpenDelPopup(false);
			setSelectedRows(false);
		}
	};

	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedBox.length > 0) {
			setOpenDelPopup(true);
		}
	};

	return (
		<div>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delBattle'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)}

			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectBox(e)}
					/>
				</DataTableExtensions>
			</Fragment>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedBox}
			/>
		</div>
	);
};
// const ProfileImage = ({ row }) => {
// 	return (
// 		<div className="profile-image">
// 			<img src={row.image ? row.image : placeholder} alt="logo" />
// 		</div>
// 	);
// };

const ActionButton = ({ row, handleDelete, handleEdit, role, delRole, editRole }) => {
	// const delRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'delBattle'].includes(r)
	// );
	// const editRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'writeBattle'].includes(r)
	// );
	return (
		<div className="action-btn">
			{delRole && <span>
				<i
					onClick={() =>
						delRole ? handleDelete(row._id) : toastDelPermission('battle')
					}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>}
			{editRole && <span>
				<i
					onClick={() =>
						editRole ? handleEdit(row._id) : toastEditPermission('battle')
					}
					className="fa fa-pencil"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: 'rgb(40, 167, 69)',
					}}
				></i>
			</span>}
		</div>
	);
};
export default ListDatatable;
