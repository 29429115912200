import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { adminInstance } from '../../config/axios';
import { Button, Label, Form, FormGroup } from 'reactstrap';
import { getAdminData } from '../../reducers/adminReducers';

const HideTryForFreeForm = () => {
	const initialChance = {
		hideTryForFree: false,
	};
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { settings, role } = loginAdmin || {};
	const { hideTryForFree } = settings || {};
	const [rollChance, setRollChance] = useState(initialChance);
	//const [tryForFree, setTryForFree] = useState(false);
	useEffect(() => {
		if (hideTryForFree) {
			const copy = hideTryForFree;
			setRollChance({ ...rollChance, hideTryForFree: copy });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideTryForFree]);

	const onSubmitHandleData = async (e) => {
		e.preventDefault();
		try {
			const payload = {
				hideTryForFree: rollChance?.hideTryForFree,
			};
			const response = await adminInstance().put(
				'/api/admin/updateHideTryForFree',
				payload
			);
			const { code, msg } = response.data;
			if (code === 200) {
				toast.success(msg);
				dispatch(getAdminData());
			} else {
				toast.error(msg);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleRollChanceVisibility = (e, p) => {
		const { name } = e.target;
		setRollChance({ ...rollChance, [name]: p });
	};
	return (
		<>
			<Form className="needs-validation user-add" onSubmit={onSubmitHandleData}>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="col-form-label pt-0">Hide Roll Chance</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="hideTryForFree"
								defaultValue={rollChance.hideTryForFree || hideTryForFree}
								checked={rollChance?.hideTryForFree === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Show
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="hideTryForFree"
								checked={rollChance?.hideTryForFree === false ? true : false}
								defaultValue={rollChance?.hideTryForFree || hideTryForFree}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Hide
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>

				{role?.some((r) =>
					['suUsers', 'superAdmin', 'writeHideTryForFree', 'writePreferences'].includes(r)
				) && (
						<div className="form-button d-flex justify-content-start pt-3">
							<Button type="submit" color="primary">
								Update
							</Button>
						</div>
					)}
			</Form>
		</>
	);
};

export default HideTryForFreeForm;
