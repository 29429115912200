import {
  Home,
  Settings,
  Box,
  Users,
  UserPlus,
  Play,
  // Flag,
  Zap,
} from "react-feather";
//PieChart

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    access: "Dashboard",
  },

  {
    title: "Warehouse",
    icon: Box,
    type: "sub",
    active: false,
    access: "Warehouse" || "Category",
    children: [
      {
        title: "Category",
        icon: Box,
        path: "/category-list",
        type: "link",
        access: "Category",
      },

      {
        title: "Products",
        icon: Box,
        path: "/products/list_product",
        type: "link",
        access: "Product",
      },
      {
        title: "Add Product",
        icon: Box,
        path: "/products/create-product",
        type: "link",
        access: "AddProduct",
      },
      {
        title: "Raritylevel",
        icon: Box,
        path: "/products/rarity-list",
        type: "link",
        access: "RarityLevel",
      },
      // {
      //   title: "Brand",
      //   icon: Box,
      //   path: "/brand-list",
      //   type: "link",
      //   access: "Brand",
      // },
      // {
      //   title: "Partner",
      //   icon: Box,
      //   path: "/vendors/list_vendors",
      //   type: "link",
      //   access: "Partner",
      // },
    ],
  },

  {
    title: "Game Management",
    icon: Play,
    type: "sub",
    active: false,
    access: "GameManagement",
    children: [
      {
        title: "Unboxing",
        icon: Box,
        path: "/box-list",
        type: "link",
        access: "Box",
      },
      // {
      // 	title: 'Bot simulator',
      // 	icon: Box,
      // 	path: '/bot-simulator',
      // 	type: 'link',
      // 	access: 'BotSimulator',
      // },
      // {
      //   title: "battle",
      //   icon: Box,
      //   path: "/battle-list",
      //   type: "link",
      //   access: "Battle",
      // },
    ],
  },

  // {
  // 	title: 'Box',
  // 	icon: Box,
  // 	path: '/box-list',
  // 	type: 'link',
  // 	access: 'Box',
  // },
  // {
  // 	title: 'Bot simulator',
  // 	icon: Box,
  // 	path: '/bot-simulator',
  // 	type: 'link',
  // 	access: 'BotSimulator',
  // },
  // {
  // 	title: 'battle',
  // 	icon: Box,
  // 	path: '/battle-list',
  // 	type: 'link',
  // 	access: 'Battle',
  // },
  // {
  //     title: 'Products', icon: Box, type: 'sub', active: false, children: [
  //         {
  //             title: 'Physical', type: 'sub', active: false, children: [
  //                 { path: '/products/physical/category', title: 'Category', type: 'link' },
  //                 { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
  //                 { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
  //                 { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
  //                 { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
  //             ]
  //         },
  //         {
  //             title: 'digital', type: 'sub', active: false, children: [
  //                 { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
  //                 { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
  //                 { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
  //                 { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
  //             ]
  //         },
  //     ]
  // },

  // {
  //     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
  //         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
  //         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
  //         { path: '/pages/list-page', title: 'List Page', type: 'link' },
  //         { path: '/pages/create-page', title: 'Create Page', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
  // },
  // {
  //     title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
  //         { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
  //         { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
  //     ]
  // },
  {
    title: "Users",
    icon: Users,
    path: "/users/list-user",
    type: "link",
    access: "Users",
  },

  {
    title: "Profit Management",
    icon: Zap,
    type: "sub",
    active: false,
    access: "ProfitManagement",
    children: [
      {
        title: "Withdraws",
        icon: Box,
        path: "/funds-management",
        type: "link",
        access: "Withdraws",
      },
      {
        title: "Order Management",
        icon: Box,
        path: "/order-management",
        type: "link",
        access: "Orders",
      },
      {
        title: "Deposits",
        icon: Box,
        path: "/deposite-mangement",
        type: "link",
        access: "Deposits",
      },
      // {
      //   title: "Rain Drop",
      //   icon: Box,
      //   path: "/raindrop-data",
      //   type: "link",
      //   access: "RainDrop"
      // }
    ],
  },

  // {
  //   title: "Withdraws",
  //   icon: Box,
  //   path: "/funds-management",
  //   type: "link",
  //   access: "Funds",
  // },
  /////
  // {
  // 	title: 'Faq Management',
  // 	icon: PieChart,
  // 	type: 'sub',
  // 	active: false,
  // 	access: 'Faq' || 'faq Category',
  // 	children: [
  // 		{
  // 			title: 'Faq Category',
  // 			icon: Box,
  // 			path: '/faq-category',
  // 			type: 'link',
  // 			access: 'Category',
  // 		},

  // 		{
  // 			title: 'Faq',
  // 			icon: Box,
  // 			path: '/faq',
  // 			type: 'link',
  // 			access: 'Faq',
  // 		},

  // 	],
  // },

  ////
  // {
  // 	title: 'Faq Management',
  // 	icon: Box,
  // 	path: '/faq',
  // 	type: 'link',
  // 	access: 'Faq',
  // },
  // {
  // 	title: 'Order',
  // 	icon: Box,
  // 	path: '/order-management',
  // 	type: 'link',
  // 	access: 'Orders',
  // },
  // {
  // 	title: 'Deposits',
  // 	icon: Box,
  // 	path: '/deposite-mangement',
  // 	type: 'link',
  // 	access: 'Deposite',
  // },
  // {
  // 	title: 'Funds Management',
  // 	icon: Box,
  // 	path: '/funds-management',
  // 	type: 'link',
  // 	access: 'Funds',
  // },
  // {
  // 	title: 'Order Management',
  // 	icon: Box,
  // 	path: '/order-management',
  // 	type: 'link',
  // 	access: 'Orders',
  // },

  {
    title: "Affiliate",
    icon: UserPlus,
    type: "sub",
    active: false,
    access: "Affiliate",
    children: [
      {
        title: "Affiliate Level",
        icon: Users,
        path: "/affiliate-level",
        type: "link",
        access: "AffiliateLevel",
      },
      {
        title: "Affiliate Users",
        icon: Users,
        path: "/affiliate-users",
        type: "link",
        access: "AffiliateUsers",
      },
      {
        title: "Affiliate Stats",
        icon: Users,
        path: "/affiliate-stats",
        type: "link",
        access: "AffiliateStats",
      },
    ],
  },

  {
    title: "Team Management",
    icon: UserPlus,
    type: "sub",
    active: false,
    access: "TeamManagement",
    children: [
      {
        title: "Team Members",
        icon: Users,
        path: "/team-management",
        type: "link",
      },
      {
        title: "Activity Log",
        icon: Users,
        path: "/activityLog",
        type: "link",
        access: "ActivityLog",
      },
    ],
  },
  // Jio location Tab start from here
  // {
  //   title: "Allowed Country",
  //   icon: Flag,
  //   path: "/country-allow",
  //   type: "link",
  //   access: "allowedcountry",
  // },
  // Jio location Tab end from here

  // {
  // 	title: 'Announcements',
  // 	icon: Box,
  // 	path: '/announcements',
  // 	type: 'link',
  // 	access: 'Announcement',
  // },
  // {
  // 	title: 'Delivery Management',
  // 	icon: Users,
  // 	path: '/shippingManagement',
  // 	type: 'link',
  // 	access: 'Delivery'
  // },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    access: "Setting",
    children: [
      {
        path: "/settings?tab=profile",
        title: "Profile",
        type: "link",
        access: "Profile",
      },
      {
        path: "/settings?tab=changePassword",
        title: "Change Password",
        type: "link",
        access: "ChangePassword",
      },
      {
        path: "/settings?tab=preferences",
        title: "Preferences",
        type: "link",
        access: "Preferences",
      },
      // {
      //   path: "/settings?tab=quickSellPercentage",
      //   title: "Quick Sell Percentage",
      //   type: "link",
      //   access: "QuickSellPercentage",
      // },
      // {
      //   path: "/settings?tab=otherSettings",
      //   title: "Other Settings",
      //   type: "link",
      //   access: "OtherSettings",
      // },
      // {
      //   path: "/settings?tab=hideRolChance",
      //   title: "Hide Role Chance",
      //   type: "link",
      //   access: "HideRolChance",
      // },
      // {
      //   path: "/settings?tab=hideTryForFree",
      //   title: "Hide Try For Free Roll",
      //   type: "link",
      //   access: "HideTryForFree",
      // },
      // {
      //   path: "/settings?tab=paymentOptions",
      //   title: "Payment Option",
      //   type: "link",
      //   access: "PaymentOption",
      // },
    ],
  },
];
