import { toast } from 'react-toastify';
export const toastDelPermission = (items) => {
	toast.error(`You do not have permission to delete ${items} `, {
		toastId: 'permission',
	});
};
export const toastEditPermission = (items) => {
	toast.error(`You do not have permission to edit ${items}`, {
		toastId: 'permission',
	});
};
