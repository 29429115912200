import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import ReactExport from 'react-export-excel';
import DataTableExtensions from 'react-data-table-component-extensions';
import placeholder from '../../assets/images/fashion/product/12.jpg';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import {
	deleteSelectedProduct,
	getAllBotPlayList,
} from '../../reducers/adminReducers';
import { useDispatch, useSelector } from 'react-redux';
//import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { toastDelPermission } from '../../utils/toastPermission';
const ListDatatable = ({
	id,
	myData,
	myClass,
	multiSelectOption,
	pagination,
	setActiveId,
	setOpen,
}) => {
	const dispatch = useDispatch();
	const { ExcelFile } = ReactExport;
	const { ExcelSheet } = ReactExport.ExcelFile;
	const { ExcelColumn } = ReactExport.ExcelFile;
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	//const [data, setData] = useState();
	const [dummyData, setdummyData] = useState();
	const [selectedProduct, setSelectedProduct] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	// console.log('allBoxList==>', data);
	// useEffect(() => {
	// 	// if (myData) {
	// 	// 	const updateArr = myData.map((el, i) => {
	// 	// 		return { ...el, isActive: false, index: i };
	// 	// 	});
	// 	setData(myData);
	// 	// }
	// }, [myData]);

	useEffect(() => {
		let serverUsers = myData?.map((u, i) => ({
			// 'Box image': u.boxId ? u?.boxId?.image : placeholder,
			// Name: u?.boxId?.name
			'Box Name': u?.boxId?.name,
			'Box Price': u?.boxPrice || 0,
			//'Win item': u?.result?.pid ? u?.result?.pid?.bannerImage : placeholder,
			'Win Item Name': u?.result?.pid?.name,
			'Win Item Price': `${u?.result?.pid?.price.toFixed(2)}`,
			'Date/Time': moment(u.createdAt).format('DD/MM/YYYY HH:mm'),
		}));
		setdummyData(serverUsers);
	}, [myData]);
	// console.log('Data is here==>', myData);
	const columnDefs = [
		{
			name: 'Box Image',
			cell: (row) => <ProfileImage row={row} />,
		},
		{
			name: 'Box Name',
			selector: (row) => row?.boxData?.name,
			sortable: true,
		},
		{
			name: 'Total Box Opened',
			selector: (row) => row?.count,
			sortable: true,
		},
		{
			name: 'Total Cost',
			selector: (row) => row?.boxPrice?.toFixed(2),
			sortable: true,
		},
		{
			name: 'Total Wins',
			selector: (row) => row?.totalWins,
			sortable: true,
		},
		// {
		// 	name: 'Win Item Name',
		// 	selector: (row) => row?.result?.pid?.name,
		// 	sortable: true,
		// },
		// {
		// 	name: 'Win Item Price',
		// 	selector: (row) => `${row?.result?.pid?.price.toFixed(2)} DT`,
		// 	sortable: true,
		// },
		{
			name: 'Date/Time',
			selector: (row) => moment(row.date).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton row={row} handleDelete={handleDelete} role={role} />
			),
		},
	];
	let delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delBotSimulator'].includes(r)
	);
	useEffect(() => {
		const check = delRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])

	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
	};

	const handleSelectProduct = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedProduct(selectedPro);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedProduct(selectedPro);
		}
		setSelectedRows(true);
	};

	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		const res = await dispatch(deleteSelectedProduct(id));
		const { resultData } = res?.payload;
		if (resultData?.code === 200) {
			dispatch(getAllBotPlayList(''));
			setOpenDelPopup(false);
			setSelectedRows(false);
		}
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedProduct.length > 0) {
			setOpenDelPopup(true);
		}
	};
	let downloadFilename =
		'bootplay-' + moment(new Date()).format('DD/MM/YYYY HH:mm');
	//console.log('downloadFilename=>', downloadFilename)
	return (
		<div className="botlist-page botplaytable">
			<div className="export-csv">
				{role?.some((r) =>
					['suUsers', 'superAdmin', 'delBotSimulator'].includes(r)
				) && (
						<div class="btn-popup pull-right">
							<span
								class="btn btn-secondary mt-0 mx-2"
								onClick={
									// handleSelectedDelete();
									selectDeleteOpen
								}
							>
								Delete
							</span>
						</div>
					)}

				{role?.some((r) =>
					['suUsers', 'superAdmin', 'writeBotSimulator'].includes(r)
				) && (
						<ExcelFile
							filename={downloadFilename}
							element={
								<Button className="btn btn-dwnld">Download in Excel</Button>
							}
						>
							<ExcelSheet data={dummyData} name="Employees">
								{/* <ExcelColumn label="Box image" value="Box image"/> */}
								<ExcelColumn label="Box Name" value="Box Name" />
								<ExcelColumn label="Box Price" value="Box Price" />
								{/* <ExcelColumn label="Win item" value="Win item"/> */}
								<ExcelColumn label="Win Item Name" value="Win Item Name" />
								<ExcelColumn label="Win Item Price" value="Win Item Price" />
								<ExcelColumn label="Date/Time" value="Date/Time" />
							</ExcelSheet>
						</ExcelFile>
					)}
			</div>
			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={myData}
					print={false}
					export={false}
				>
					<DataTable
						data={myData}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectProduct(e)}
					/>
				</DataTableExtensions>
			</Fragment>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedProduct}
			/>
		</div>
	);
};

const ProfileImage = ({ row }) => {
	// console.log();
	return (
		<div className="profile-image">
			<img src={row.boxData ? row?.boxData?.image : placeholder} alt="logo" />
		</div>
	);
};
// const WinImage = ({ row }) => {
// 	return (
// 		<div className="profile-image">
// 			<img
// 				src={row?.result?.pid ? row?.result?.pid?.bannerImage : placeholder}
// 				alt="logo"
// 			/>
// 		</div>
// 	);
// };
const ActionButton = ({ row, handleDelete, role }) => {
	const delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delBotSimulator'].includes(r)
	);
	return (
		<div className="action-btn">
			<span>
				<i
					onClick={() =>
						delRole
							? handleDelete(row._id)
							: toastDelPermission('bot simulator')
					}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>
		</div>
	);
};

export default ListDatatable;
