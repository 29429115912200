import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import "./location.css";
import CountryTableList from "./countryTableList";
import { useState } from "react";
import {
  addCountry,
  getCountryData,
  updateCountryStatus,
} from "../../reducers/adminReducers";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import countryList from "country-list";
import Loader from "../loader/loader";
function AllowedCountry() {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { allCountryList } = result || {};
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [allow, setAllow] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryStatus, setCountryStatus] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      setCountryStatus(selectedCountry.allow ? "allow" : "block");
    }
  }, [selectedCountry]);

  const handleStatusChange = (e) => {
    setCountryStatus(e.target.id);
  };
  const handleCountryNameChange = (e) => {
    setCountryName(e.target.value);
  };

  const handleAllowChange = (e) => {
    setAllow(e.target.id === "allow");
  };
  const handleShowAdd = () => {
    setShowAdd(true);
  };
  const closeAdd = () => {
    setShowAdd(false);
  };
  const handleShowEdit = (country) => {
    setShowEdit(true);
    setSelectedCountry(country);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const clearForm = () => {
    setCountryName("");
    setAllow(true);
  };

  useEffect(() => {
    const countryNames = countryList.getNames();
    setCountries(countryNames);
  }, []);

  useEffect(() => {
    dispatch(getCountryData());
  }, [dispatch]);

  const handleAddCountry = () => {
    if (!countryName.trim()) {
      toast.error("Country Name is required");
      return;
    }
    if (allow === null || allow === undefined) {
      toast.error("Please specify if the country is allowed or not");
      return;
    }
    const countryExists = allCountryList.some(
      (country) =>
        country.countryName.toLowerCase() === countryName.toLowerCase()
    );

    if (countryExists) {
      toast.error("Country already exists");
      return;
    }

    let data = {
      countryName: countryName,
      allow: allow,
    };
    dispatch(addCountry(data));
    clearForm();
    setShowAdd(false);
    dispatch(getCountryData(data));
    toast.success("You Have Added Country Successfully", {
      toastId: "countryadd",
    });
    setLoading(true);
    setTimeout(()=>{
      setLoading(false)
    },2000)
  };

  const handleNewCountryStatus = () => {
    if (selectedCountry) {
      const updatedStatus = countryStatus === "allow" ? true : false;
      const updatedData = {
        countryName: selectedCountry.countryName,
        allow: updatedStatus,
      };
      let data = {
        countryName: countryName,
        allow: allow,
      };
      dispatch(updateCountryStatus(updatedData));
      setShowEdit(false);
      dispatch(getCountryData(data));
      toast.success("You Have Changed Country Status Successfully", {
        toastId: "countrystatus",
      });
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const handleFilterChange = (e) => {
    const searchText = e.target.value.toLowerCase().trim();
    if (!searchText) {
      setFilteredCountries(allCountryList);
    } else {
      const filtered = allCountryList.filter((country) =>
        country.countryName.toLowerCase().includes(searchText)
      );
      if (filtered.length > 0) {
        setFilteredCountries(filtered);
      } else {
        setFilteredCountries([]);
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb title='Allowed Country' parent='Country' />
      <Container fluid={true}>
        <div className='country-card'>
          <Card>
            <CardHeader>
              <h5>Allowed Countries </h5>
            </CardHeader>
            <CardBody>
              <div className='card-body-header'>
                <Button onClick={handleShowAdd}>Add New</Button>
                <input
                  type='text'
                  className='country-filter'
                  placeholder='Filter Country'
                  onChange={handleFilterChange}
                />
              </div>
              {!loading ? (
                <CountryTableList
                  handleShowAdd={handleShowAdd}
                  showAdd={showAdd}
                  closeAdd={closeAdd}
                  showEdit={showEdit}
                  handleShowEdit={handleShowEdit}
                  handleCloseEdit={handleCloseEdit}
                  handleAddCountry={handleAddCountry}
                  countryName={countryName}
                  handleAllowChange={handleAllowChange}
                  handleCountryNameChange={handleCountryNameChange}
                  allow={allow}
                  countries={countries}
                  allCountryList={allCountryList}
                  selectedCountry={selectedCountry}
                  handleStatusChange={handleStatusChange}
                  countryStatus={countryStatus}
                  handleNewCountryStatus={handleNewCountryStatus}
                  filteredCountries={filteredCountries}
                />
              ) : (
                <Loader />
              )}
            </CardBody>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
}

export default AllowedCountry;
