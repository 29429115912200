import React, { Fragment,useEffect, useState} from "react";
// import ordered from "../../assets/images/dashboard/designer.jpg";
import { queryString } from "../../utils/queryString";
import Breadcrumb from "../common/breadcrumb";
import { getOrderDetailsByOrderId } from './../../reducers/adminReducers';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../loader/loader";

const OrderDetail = () => {
  const {id} = queryString();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const {orderDetails} = result;
  const {orderSummary,orderInfo} = orderDetails || {}; 
  const [loading,setLoading] = useState(false)

  // console.log('orderDetails',orderDetails,'id',id);

  const createAddress = (data) => {
    let address = "";
    if(data){
    Object.keys(data).forEach((el) => {
      if (data[el]) {
        address = `${address ? `${address},` : address} ${data[el]}`;
      }
    });
  }
    // console.log("createAddress", address);
    return address;
  };
  const getOrderDetailsById=async()=>{
    setLoading(true);
 const response= await dispatch(getOrderDetailsByOrderId(id))
//  console.log("Check RESPONSE11",response)
      if(response?.payload){
        setLoading(false)
      } 


  }

  useEffect(()=>{
    getOrderDetailsById()
    // if(id){
    // setLoading(true)
    // dispatch(getOrderDetailsByOrderId(id))
    // setLoading(false)
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  return (
    <>
    	{loading&&<Loader/>}
      <Breadcrumb title="orderdetail" parent="orderdetail" />
      <div className="order-detail-page">
        <div className="container">
          <div className="order-detail">
            <div className="order-detail-list">
              <h4>User Details</h4>
              <div className="user-name">
                <p>
                  Username: <span>{orderInfo?.userId?.username}</span>
                </p>
                <p>
                  Phone:<span>{orderInfo?.userId?.mobile}</span>
                </p>
                <p>
                  Address:{" "}
                  <span>{createAddress(orderInfo?.shippingAddress)}</span>
                </p>
              </div>
            </div>
            <div className="order-detail-list">
              <h4>Order Details</h4>
              <div className="user-name">
                <p>
                  Order No: <span>{orderInfo?.orderid}</span>
                </p>
                {/* <p>
                  Order Id: <span>#576676787466</span>
                </p> */}
                <p>
                  Order from: <span>{orderInfo?.orderBy}</span>
                </p>
                <p>
                  Total Order Items: <span>{orderSummary?.length}</span>
                </p>
              </div>
            </div>
            <div className="order-detail-list">
              {/* <h4>Ordered Items:</h4> */}
             {orderSummary?.length > 0 && orderSummary?.map((el)=> <div className="ordered-detail-list">
                <div className="left-order-details">
                  {/* <h6>Ordered Items:</h6> */}

                  <div className="ordered-items">
                    <img src={el?.productId?.bannerImage} alt="ordered" />
                  </div>
                </div>
                <div className="right-order-section">
                {/* <h6>Ordered Items Detail:</h6> */}

                  <div className="user-name">
                  <p>
                    Order Name: <span>{el?.productId?.name}</span>
                  </p>
                  <p>
                    Price: <span>{el?.price}</span>
                  </p>
                  <p>
                    Quantity: <span>{el?.quantity}</span>
                  </p>
                  {el?.reason&&<p>
                    Info: <span>{el?.reason}</span>
                  </p>}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
     
                  
    </>
  );
};

export default OrderDetail;
