import React, { useEffect, Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import Breadcrumb from "../common/breadcrumb";
// import Datatable from "../common/datatable";
import { User, Box, DollarSign, Layers } from "react-feather";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  /* Button,
  Label,
  Form,
  FormGroup,*/
} from "reactstrap";
import UserTransactions from "./userTransaction";
import UserBoxTable from "./userBoxTable";
import TotalBattlesTable from "./totalBattlesTable";
// import Userinventory from "./user-inventory";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "./../../utils/queryString";
import Userinventory from "./user-inventory";
import {
  getloginHistoryData,
  getUserDetailById,
} from "../../reducers/adminReducers";
import placeholder from "../../assets/images/fashion/product/12.jpg";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
// import LoginHistory from "./loginHistory";
import Loader from "../loader/loader";
// import Affiliates from "./affiliates";
// import AffiliatesStats from "./affiliateStatsForUser";
const UserProfileDetails = () => {
  const result = useSelector((state) => state.admin);
  // console.log("All result", result);
  const {
    userData,
    openBoxCount,
    // pnL,
    userTransactions,
    userBoxList,
    playBattleList,
    userinventoryList,
    // loginHistory,
  } = result || {};
  // console.log('LogHISTORY =====>', loginHistory)
  // console.log("userTransactions===>", userTransactions)
  // console.log("LOADING IN USER PROFILE =====>", loading)
  // console.log("this is my 2nd userData ", userData);
  const dispatch = useDispatch();
  const { id: userId } = queryString();
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  // const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  // const [noteText, setNoteText] = useState("");
  // const [selectedFile, setSelectedFile] = useState(null);
  const [kycStatus, setKycStatus] = useState("Pending");
  // const checkLogin = async () => {
  //   const res = await adminInstance().get('api/admin/getloginHistoryData');
  //   console.log("RESPONSE LG", res)
  // }
  // useEffect(() => {
  //   checkLogin()
  // }, [])

  // console.log('userTransaction', userTransactions)

  useEffect(() => {
    async function getData() {
      setLoading(true);
      await dispatch(getloginHistoryData(userId));
      const res1 = await dispatch(getUserDetailById(userId));
      // if (res && res1) {
      // console.log("res====>", res)
      if (res1) setLoading(false);
      // } screenLeft
    }
    getData();
  }, [dispatch, userId]);
  const handleOnDelete = async (id) => {
    try {
      const response = await adminInstance().delete(
        `api/admin/deleteInventory/${id}/${userId}`
      );
      const { code } = response.data;
      if (code === 200) {
        toast.success("Successfully Deleted");
        dispatch(getUserDetailById(userId));
      }
      setOpen(false);
    } catch (error) {}
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  // const handleOnchange = (value) => {
  //   setAmount(value);
  // };
  // const handleOnSubmit = async (e) => {
  //   e.preventDefault();
  //   if (parseFloat(userData?.walletAmount) >= parseFloat(amount)) {
  //     const payload = {
  //       amount: amount,
  //       userId: userData?._id,
  //       debitCreditType: "debit",
  //       paymentType: "wallet",
  //       type: "withdraw",
  //       description: "Withdraw Fund",
  //       descriptionMessage: "Withdraw Amount from Wallet",
  //       withdrawFrom: "admin",
  //     };
  //     const response = await adminInstance().post(
  //       "api/admin/withDrawAmount",
  //       payload
  //     );
  //     const { code } = response.data;
  //     if (code === 200) {
  //       dispatch(getUserDetailById(userId));
  //       setAmount("");
  //       toast.success("Withdraw amount successfully");
  //     }
  //   } else {
  //     toast.error(
  //       "Not sufficient amount in your wallet to withdraw this amount",
  //       {
  //         toastId: "withdrawamount",
  //       }
  //     );
  //   }
  // };

  // Add Note for user
  // const addNote = async () => {
  //   try {
  //     const response = await adminInstance().post("api/admin/addNotes", {
  //       userId: userId,
  //       note: noteText,
  //     });

  //     const { code } = response.data;
  //     if (code === 200) {
  //       setNoteText("");
  //       toast.success("Note added successfully");
  //       dispatch(getUserDetailById(userId));
  //     } else {
  //       toast.error("Failed to add note");
  //     }
  //   } catch (error) {
  //     console.error("Error adding note:", error);
  //     toast.error("An error occurred while adding the note");
  //   }
  // };

  // const handleFileChange = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  // Upload KYC Doucments
  // const uploadDocument = async () => {
  //   try {
  //     if (selectedFile) {
  //       const formData = new FormData();
  //       formData.append("file", selectedFile);
  //       formData.append("userId", userId);

  //       const response = await adminInstance().post(
  //         "api/admin/uploadDocument",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       if (response.data.code === 200) {
  //         toast.success("Document uploaded successfully");
  //         setSelectedFile(null);
  //         dispatch(getUserDetailById(userId));
  //       } else {
  //         toast.error("Failed to upload document");
  //       }
  //     } else {
  //       toast.error("Please select a document to upload.");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading document:", error);
  //     toast.error("Please Upload the PDF Formate file only.");
  //   }
  // };

  // const handleChangeKYCStatus = async () => {
  //   try {
  //     const newStatus = userData?.kycStatus;
  //     const response = await adminInstance().post("api/admin/changeKYCStatus", {
  //       userId,
  //       status: newStatus,
  //     });
  //     const { code, msg } = response.data;
  //     if (code === 200) {
  //       toast.success(msg);
  //       dispatch(getUserDetailById(userId));
  //     } else {
  //       toast.error(msg);
  //     }
  //   } catch (error) {
  //     toast.error("Please Upload document for the change status.");
  //   }
  // };

  useEffect(() => {
    if (userData && userData?.kycStatus === true) {
      setKycStatus("Completed");
    } else {
      setKycStatus("Pending");
    }
  }, [userData]);
  return (
    <Fragment>
      {loading && <Loader />}
      <Breadcrumb title='User Profile Details' parent='Settings' />
      <Container fluid={true}>
        <Row>
          <Col xl='12'>
            <Card className='profile-card user__profile__card'>
              <CardBody>
                <div>
                  <Tabs>
                    <TabList className='nav nav-tabs tab-coupon'>
                      <Tab className='nav-link'>
                        <User className='mr-2' />
                        Profile
                      </Tab>
                      <Tab className='nav-link'>
                        <DollarSign className='mr-2' />
                        Transactions
                      </Tab>
                      <Tab className='nav-link'>
                        <Box className='mr-2' />
                        Total Cases
                      </Tab>
                      <Tab className='nav-link'>
                        <Layers className='mr-2' />
                        Total Battles
                      </Tab>
                      <Tab className='nav-link'>
                        <Layers className='mr-2' />
                        Inventory
                      </Tab>
                      {/* <Tab className="nav-link">
                        <Layers className="mr-2" />
                        Withdraw Deposit
                      </Tab> */}
                      {/* <Tab className="nav-link">
                        <Layers className="mr-2" />
                        Login history
                      </Tab> */}
                      {/* <Tab className="nav-link">
                        <Layers className="mr-2" />
                        Affiliates
                      </Tab>
                      <Tab className="nav-link">
                        <Layers className="mr-2" />
                        Affiliates Stats
                      </Tab> */}
                    </TabList>

                    <TabPanel>
                      <section
                        className='section about-section gray-bg'
                        id='about'>
                        <div className='container'>
                          <div className='row align-items-center flex-row-reverse'>
                            <div className='col-lg-8'>
                              <div className='about-text go-to'>
                                <h3 className='dark-color'>User Info</h3>
                                <div className='row about-list'>
                                  <div className='col-md-6'>
                                    <div className='media'>
                                      <label>First Name</label>
                                      <p>{userData?.firstname || ""}</p>
                                    </div>
                                    <div className='media'>
                                      <label>Last Name</label>
                                      <p>{userData?.lastname || ""}</p>
                                    </div>
                                    <div className='media'>
                                      <label>Email</label>
                                      <p>{userData?.email || ""}</p>
                                    </div>
                                    <div className='media'>
                                      <label>User Type</label>
                                      <p>{userData?.userType || ""}</p>
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='media'>
                                      <label>Username</label>
                                      <p>{userData?.username || ""}</p>
                                    </div>
                                    <div className='media'>
                                      <label>Mobile No</label>
                                      <p>{userData?.mobile || ""}</p>
                                    </div>
                                    <div className='media'>
                                      <label>DOB</label>
                                      <p>
                                        {userData?.dateOfBirth?.substr(0, 10) ||
                                          ""}
                                      </p>
                                    </div>
                                    <div className='media'>
                                      <label>KYC Status</label>
                                      <p>{kycStatus}</p>
                                    </div>
                                    {/* 
                                    <div className="media">
                                      <label>Language</label>
                                      <p>{userData?.language || ""}</p>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-3'>
                              <div className='about-avatar'>
                                <img
                                  src={userData?.profile || placeholder}
                                  title=''
                                  alt=''
                                />
                              </div>
                            </div>
                          </div>
                          <div className='counter'>
                            <div className='row'>
                              <div className='col-6 col-lg-4'>
                                <div className='count-data text-center border_total'>
                                  <h6
                                    className='count h2'
                                    data-to='500'
                                    data-speed='500'>
                                    {(userData?.totalDeposit || 0).toFixed(2)} $
                                  </h6>
                                  <p className='m-0px font-w-600'>
                                    Total Deposit
                                  </p>
                                </div>
                              </div>
                              <div className='col-6 col-lg-4'>
                                <div className='count-data text-center'>
                                  <h6
                                    className='count h2'
                                    data-to='150'
                                    data-speed='150'>
                                    {userData?.walletAmount?.toFixed(2) ||
                                      "0.00"}{" "}
                                    ${" "}
                                  </h6>
                                  <p className='m-0px font-w-600'>
                                    Current Balance
                                  </p>
                                </div>
                              </div>
                              {/* <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                  <h6
                                    className="count h2"
                                    data-to="850"
                                    data-speed="850"
                                  >
                                    {pnL?.toFixed(2) || 0}
                                  </h6>
                                  <p className="m-0px font-w-600">PnL</p>
                                </div>
                              </div> */}
                              <div className='col-6 col-lg-4'>
                                <div className='count-data text-center'>
                                  <h6
                                    className='count h2'
                                    data-to='850'
                                    data-speed='850'>
                                    {openBoxCount || 0}
                                  </h6>
                                  <p className='m-0px font-w-600'>
                                    Total Box Open
                                  </p>
                                </div>
                              </div>
                              {/* <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                  <h6
                                    className="count h2"
                                    data-to="190"
                                    data-speed="190"
                                  >
                                    {userData?.totalWin || 0}
                                  </h6>
                                  <p className="m-0px font-w-600">Total Win</p>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className='container mt-4'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='admin-notes mb-4'>
                                <h3 className='dark-color'>Add Notes</h3>
                                <textarea
                                  class='form-control'
                                  id='exampleFormControlTextarea1'
                                  rows='3'
                                  value={noteText}
                                  onChange={(e) =>
                                    setNoteText(e.target.value)
                                  }></textarea>
                                <button
                                  type='button'
                                  class='btn btn-primary mt-2'
                                  onClick={addNote}>
                                  Add
                                </button>
                                <div className='note-result mt-2'>
                                  <p>{userData?.note}</p>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='kyc-document'>
                                <h3 className='dark-color'>
                                  KYC Documents Upload
                                </h3>
                                <div class='mb-3 mt-4'>
                                  <label
                                    for='formFile'
                                    class='form-label dark-color'>
                                    Upload Doucumetn for KYC
                                  </label>
                                  <input
                                    type='file'
                                    className='upload-control'
                                    id='formFile'
                                    onChange={handleFileChange}
                                  />
                                  <button
                                    type='button'
                                    class='btn btn-primary mt-3 ml-1'
                                    onClick={uploadDocument}>
                                    Add
                                  </button>
                                </div>

                                <div className='kyc-status'>
                                  <h3 className='dark-color'>
                                    Change KYC Status Manually
                                  </h3>
                                  <div className='mb-3 mt-4'>
                                    <button
                                      type='button'
                                      className={`btn ${
                                        userData?.kycStatus
                                          ? "btn-danger"
                                          : "btn-success"
                                      }`}
                                      onClick={handleChangeKYCStatus}>
                                      {userData?.kycStatus
                                        ? "Change to Pending"
                                        : "Change to Completed"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <div id='batchDelete' className='transactions'>
                        <UserTransactions
                          multiSelectOption={false}
                          myData={userTransactions}
                          pageSize={10}
                          pagination={true}
                          className='-striped -highlight'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div id='batchDelete' className='transactions'>
                        <UserBoxTable
                          multiSelectOption={false}
                          myData={userBoxList}
                          pageSize={10}
                          pagination={true}
                          className='-striped -highlight'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div id='batchDelete' className='transactions'>
                        <TotalBattlesTable
                          multiSelectOption={false}
                          myData={playBattleList}
                          pageSize={10}
                          pagination={true}
                          className='-striped -highlight'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div id='batchDelete' className='transactions'>
                        <Userinventory
                          multiSelectOption={false}
                          myData={userinventoryList}
                          pageSize={10}
                          pagination={true}
                          className='-striped -highlight'
                          setOpen={setOpen}
                          setActiveId={setActiveId}
                          id={userId}
                        />
                      </div>
                    </TabPanel>
                    {/* <TabPanel>
                      <div id="batchDelete" className="transactions">
                        <Form
                          className="needs-validation user-add"
                          onSubmit={handleOnSubmit}
                        >
                          <FormGroup className="row justify-content-start mx-0">
                            <div className="admin-input col-xl-5 col-md-7">
                              <h6
                                className="count h5"
                                data-to="150"
                                data-speed="150"
                              >
                                Current balance:{" "}
                                {userData?.walletAmount?.toFixed(2) || "0.00"}{" "}
                                DT{" "}
                              </h6>
                              <Label className="col-form-label pt-0">
                                <span>*</span>Enter amount
                              </Label>
                              <input
                                className="form-control"
                                placeholder="Enter amount"
                                type="number"
                                step="0.01"
                                onChange={(e) => handleOnchange(e.target.value)}
                                required
                                value={amount}
                              />
                            </div>
                          </FormGroup>
                          <div className="form-button d-flex justify-content-start pt-3">
                            <Button type="submit" color="primary">
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </TabPanel> */}
                    {/* <TabPanel>
                      <div id="batchDelete" className="transactions">
                        <LoginHistory
                          multiSelectOption={false}
                          myData={loginHistory}
                          pageSize={10}
                          pagination={true}
                          className="-striped -highlight"
                        />
                      </div>
                    </TabPanel> */}

                    {/* <TabPanel>
                      <div id="batchDelete" className="transactions">
                        <Affiliates userData={userData} userId={userId} />
                      </div>
                    </TabPanel> */}

                    {/* <TabPanel>
                      <div id="batchDelete" className="transactions">
                        <AffiliatesStats userData={userData} userId={userId} />
                      </div>
                    </TabPanel> */}
                  </Tabs>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DeleteConfirmPopup
        title={"Delete User"}
        open={open}
        handleOnDelete={handleOnDelete}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};

export default UserProfileDetails;
