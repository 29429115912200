import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { adminInstance } from "../../config/axios";
import { Button, Label, Form, FormGroup } from "reactstrap";
import {
  getAdminData,
  updateCurrentBoxesProfit,
} from "../../reducers/adminReducers";
import BoxProfitConfirmation from "./../common/BoxProfitConfirmation";

const OtherSettingForm = () => {
  // const initialChance = {
  // 	hideRollChance: false,
  // };
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("current");
  const { loginAdmin, adminSetting } = result || {};
  const { role } = loginAdmin || {};
  const { boxProfit /* hideRollChance */ } = adminSetting || {};
  const [emptyError, setEmptyError] = useState();
  const [newboxProfit, setBoxProfit] = useState(boxProfit || "");
  //const [rollChance, setRollChance] = useState(initialChance);

  // useEffect(() => {
  // 	if (hideRollChance) {
  // 		const copy = hideRollChance;
  // 		setRollChance({ ...rollChance, hideRollChance: copy });
  // 	}
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hideRollChance]);
  useEffect(() => {
    if (boxProfit) {
      setBoxProfit(boxProfit);
    }
  }, [boxProfit]);
  const onCloseModal = () => {
    setOpen(false);
  };

  const handleChange = (e, data) => {
    setSelectedValue(data);
  };

  const handleConfirm = () => {
    console.log("selectedValue", selectedValue, boxProfit);
    const payload = {
      boxProfit: boxProfit,
    };
    if (selectedValue === "current") {
      dispatch(updateCurrentBoxesProfit(payload));
      toast.success("Profit Updated for current Boxes");
    } else {
      toast.success("Profit updated for New Boxes");
    }
    onCloseModal();
  };
  const onSubmitHandleData = async (e) => {
    e.preventDefault();
    try {
      if (newboxProfit !== "" && parseFloat(newboxProfit) > 0) {
        setEmptyError("");
        const payload = {
          boxProfit: newboxProfit,
        };
        const response = await adminInstance().put(
          "/api/admin/updateBoxProfit",
          payload
        );
        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg);
          dispatch(getAdminData());
          setOpen(true);
        } else {
          toast.error(msg);
        }
      } else {
        setEmptyError("This field is required");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnchange = (value) => {
    setBoxProfit(value);
  };
  return (
    <>
      <Form className='needs-validation user-add' onSubmit={onSubmitHandleData}>
        <FormGroup className='row justify-content-start mx-0'>
          <div className='admin-input col-xl-5 col-md-7'>
            <Label className='col-form-label pt-0'>
              <span>*</span> Box Profit
            </Label>
            <input
              className='form-control'
              placeholder='Box Profit'
              type='number'
              step='0.01'
              defaultValue={newboxProfit || boxProfit}
              onChange={(e) => handleOnchange(e.target.value)}
            />
            <p className='text-danger'>{emptyError || ""}</p>
          </div>
        </FormGroup>
        {role?.some((r) =>
          [
            "suUsers",
            "superAdmin",
            "writeOtherSettings",
            "writePreferences",
          ].includes(r)
        ) && (
          <div className='form-button d-flex justify-content-start pt-3'>
            <Button type='submit' color='primary'>
              Update
            </Button>
          </div>
        )}
      </Form>

      <BoxProfitConfirmation
        open={open}
        onCloseModal={onCloseModal}
        handleConfirm={handleConfirm}
        handleChange={handleChange}
        selectedValue={selectedValue}
      />
    </>
  );
};

export default OtherSettingForm;
