import React, { useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";

import QuckSellForm from "./quckSellForm";
import { queryString } from "./../../utils/queryString";
import EditProfile from "./editProfile";
import ChangePassword from "./changePassword";
import OtherSettingForm from "./otherSetting";
import HideRollChance from "./HideRollChance";
import HideTryForFree from "./hideTryForFree";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import PaymentOption from "./paymentOption";
import ShippingManagement from "../shippingManagement";
const TabsetProfile = ({
  userData,
  handleEdit,
  edit,
  handleChange,
  handleUpdate,
}) => {
  const { tab } = queryString();
  const history = useHistory();
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];

  // const [tabVisibility,setTabVisibility] = useState(false)
  useEffect(() => {
    if (role?.length > 0) {
      let chekPer = role?.some((r) =>
        [
          "suUsers",
          "superAdmin",
          `write${capitalizeFirstLetter(tab)}`,
          `read${capitalizeFirstLetter(tab)}`,
        ].includes(r)
      );
      // let checkQuick = role?.some((r) =>
      // 	[
      // 		`write${capitalizeFirstLetter(tab.slice(0, tab.length - 7))}`,
      // 		`read${capitalizeFirstLetter(tab.slice(0, tab.length - 7))}`,
      // 	].includes(r)
      // );
      // let checkSetting = role?.some((r) =>
      // 	[
      // 		`write${capitalizeFirstLetter(tab.slice(0, tab.length - 1))}`,
      // 		`read${capitalizeFirstLetter(tab.slice(0, tab.length - 1))}`,
      // 	].includes(r)
      // );
      if (!chekPer && tab === "profile") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "changePassword") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "quickSellPercentage") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "otherSettings") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "hideRolChance") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "hideTryForFree") {
        history.push("/dashboard");
      } else if (!chekPer && tab === "paymentOptions") {
        history.push("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>{
  // 	if(tab === 'profile' || tab === 'changePassword' || tab === 'paymentOptions'){
  // 		console.log('comingnijjjij')
  // 		setTabVisibility(true)
  // 	}
  // },[tab])

  // console.log('ddsjdkjsdkjs',tabVisibility)
  // const onSubmitHandleData = async (data) => {
  //   console.log('I am in submithandler pro');
  //   try {
  //       if (Object.keys(errors).length === 0) {
  //         console.log('data====', data);
  //           const response = await adminInstance().put(
  //             '/api/admin/UpdateQuickSellPercentage',
  //             data
  //           )

  //           console.log('I am in UpdateQUIckSellPrecentage');
  //           const { code, msg, QuickSellPercentageUpdate } = response.data
  //           console.log(' code, msg, QuickSellPercentageUpdate',  code, msg , QuickSellPercentageUpdate,);
  //           if (code === 200) {
  //             toast.success(msg)
  //             reset()
  //           } else {
  //             toast.error(msg)
  //           }
  //       }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <div>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          {role?.some((r) =>
            [
              "suUsers",
              "superAdmin",
              "readProfile",
              "writeProfile",
              "readSetting",
              "writeSetting",
            ].includes(r)
          ) &&
            tab === "profile" && (
              <Tab className="nav-link">
                <User className="mr-2" />
                Profile
              </Tab>
            )}

          {role?.some((r) =>
            [
              "suUsers",
              "superAdmin",
              "readChangePassword",
              "writeChangePassword",
              "readSetting",
              "writeSetting",
            ].includes(r)
          ) &&
            tab === "changePassword" && (
              <Tab className="nav-link">
                <Settings className="mr-2" />
                Change Password
              </Tab>
            )}

          {
            // role?.some((r) =>
            // 	[
            // 		'suUsers',
            // 		'superAdmin',
            // 		'readQuickSellPercentage',
            // 		'writeQuickSellPercentage',
            // 		'readSetting',
            // 		'writeSetting',
            // 	].includes(r)
            // ) &&
            tab === "preferences" && (
              <Tab className="nav-link">
                <Settings className="mr-2" />
                Quick Sell Percentage
              </Tab>
            )
          }

          {
            // role?.some((r) =>
            // 	[
            // 		'suUsers',
            // 		'superAdmin',
            // 		'readOtherSettings',
            // 		'writeOtherSettings',
            // 		'readSetting',
            // 		'writeSetting',
            // 	].includes(r)
            // )
            // &&
            tab === "preferences" && (
              <Tab className="nav-link">
                <Settings className="mr-2" />
                Other Setting
              </Tab>
            )
          }

          {/* {
            // role?.some((r) =>
            // 	[
            // 		'suUsers',
            // 		'superAdmin',
            // 		'readHideRolChance',
            // 		'writeHideRolChance',
            // 		'readSetting',
            // 		'writeSetting',
            // 	].includes(r)
            // )
            //  &&
            tab === "preferences" && (
              <Tab className="nav-link">
                <Settings className="mr-2" />
                Hide Roll Chance
              </Tab>
            )
          } */}
          {/* {
            // role?.some((r) =>
            // 	[
            // 		'suUsers',
            // 		'superAdmin',
            // 		'readHideTryForFree',
            // 		'writeHideTryForFree',
            // 		'readSetting',
            // 		'writeSetting',
            // 	].includes(r)
            // )
            //  &&
            tab === "preferences" && (
              <Tab className="nav-link">
                <Settings className="mr-2" />
                Hide Try For Free Roll
              </Tab>
            )
          } */}

          {tab === "preferences" && (
            <Tab className="nav-link">
              <Settings className="mr-2" />
              Delivery Management
            </Tab>
          )}
          {/* {tab === "preferences" && (
            <Tab className="nav-link">
              <Settings className="mr-2" />
              Payment Option Enable/Disable
            </Tab>
          )} */}
        </TabList>
        {role?.some((r) =>
          [
            "suUsers",
            "superAdmin",
            "readProfile",
            "writeProfile",
            "readSetting",
          ].includes(r)
        ) &&
          tab === "profile" && (
            <TabPanel>
              <EditProfile
                handleUpdate={handleUpdate}
                handleEdit={handleEdit}
              />
            </TabPanel>
          )}

        {role?.some((r) =>
          [
            "suUsers",
            "superAdmin",
            "readChangePassword",
            "writeChangePassword",
            "readSetting",
            "writeSetting",
          ].includes(r)
        ) &&
          tab === "changePassword" && (
            <TabPanel>
              <ChangePassword />
            </TabPanel>
          )}

        {
          // role?.some((r) =>
          // 	[
          // 		'suUsers',
          // 		'superAdmin',
          // 		'readQuickSellPercentage',
          // 		'writeQuickSellPercentage',
          // 		'readSetting',
          // 		'writeSetting',
          // 	].includes(r)
          // ) &&
          tab === "preferences" && (
            <TabPanel>
              <div className="account-setting">
                <h5 className="f-w-600 f-16">Quick Sell Percentage</h5>
                <QuckSellForm />
              </div>
            </TabPanel>
          )
        }
        {
          // role?.some((r) =>
          // 	[
          // 		'suUsers',
          // 		'superAdmin',
          // 		'readOtherSettings',
          // 		'writeOtherSettings',
          // 		'readSetting',
          // 		'writeSetting',
          // 	].includes(r)
          // ) &&
          tab === "preferences" && (
            <TabPanel>
              <div className="account-setting">
                <h5 className="f-w-600 f-16">Other Setting</h5>
                <OtherSettingForm />
              </div>
            </TabPanel>
          )
        }
        {
          // role?.some((r) =>
          // 	[
          // 		'suUsers',
          // 		'superAdmin',
          // 		'readHideRolChance',
          // 		'writeHideRolChance',
          // 		'readSetting',
          // 		'writeSetting',
          // 	].includes(r)
          // ) &&
          tab === "preferences" && (
            <TabPanel>
              <div className="account-setting">
                <h5 className="f-w-600 f-16">Hide Rol Chance</h5>
                <HideRollChance />
              </div>
            </TabPanel>
          )
        }
        {
          // role?.some((r) =>
          // 	[
          // 		'suUsers',
          // 		'superAdmin',
          // 		'readHideTryForFree',
          // 		'writeHideTryForFree',
          // 		'readSetting',
          // 		'writeSetting',
          // 	].includes(r)
          // ) &&
          tab === "preferences" && (
            <TabPanel>
              <div className="account-setting">
                <h5 className="f-w-600 f-16">Hide Try For Free Roll</h5>
                <HideTryForFree />
              </div>
            </TabPanel>
          )
        }

        {tab === "preferences" && (
          <TabPanel>
            <div className="account-setting">
              {/* <h5 className="f-w-600 f-16">Delivery Management</h5> */}
              <ShippingManagement />
            </div>
          </TabPanel>
        )}

        {tab === "preferences" && (
          <TabPanel>
            <div className="account-setting">
              <h5 className="f-w-600 f-16">Payment Option</h5>
              <PaymentOption />
            </div>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default TabsetProfile;
