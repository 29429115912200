import React from "react";
import AddPopup from "./addPopup";
import EditPopup from "./editPopup";

function CountryTableList({
  showAdd,
  closeAdd,
  handleShowEdit,
  showEdit,
  handleCloseEdit,
  handleAddCountry,
  countryName,
  handleAllowChange,
  handleCountryNameChange,
  allow,
  countries,
  allCountryList,
  selectedCountry,
  handleStatusChange,
  countryStatus,
  handleNewCountryStatus,
  filteredCountries
}) {
  const displayList = filteredCountries.length > 0 ? filteredCountries : allCountryList;
  return (
    <>
      <div className='mt-4'>
        <table className='table table-striped'>
          <thead className='table-head'>
            <tr>
              <th scope='col'>S.No</th>
              <th scope='col'>Country Name</th>
              <th scope='col'>Country Code</th>
              <th scope='col'>Allow or Block</th>
              <th scope='col'>Edit</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {displayList.map((country, index) => (
              <tr className='country-table' key={index}>
                <th scope='row'>{index + 1}</th>
                <td className='country-details'>
                  <p>{country.countryName}</p>
                </td>
                <td className='country-details'>
                  <p>{country.countryCode}</p>
                </td>
                <td>
                  <span className={country.allow ? "allow" : "block"}>
                    {country.allow ? "Allow" : "Block"}
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => handleShowEdit(country)}
                    className='country-edit'>
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddPopup
        showAdd={showAdd}
        closeAdd={closeAdd}
        handleAddCountry={handleAddCountry}
        countryName={countryName}
        handleAllowChange={handleAllowChange}
        handleCountryNameChange={handleCountryNameChange}
        allow={allow}
        countries={countries}
      />
      <EditPopup
        showEdit={showEdit}
        handleCloseEdit={handleCloseEdit}
        selectedCountry={selectedCountry}
        handleStatusChange={handleStatusChange}
        countryStatus={countryStatus}
        handleNewCountryStatus={handleNewCountryStatus}
      />
    </>
  );
}

export default CountryTableList;
