import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminInstance } from "../config/axios";
import { toast } from "react-toastify";
const initialState = {
  value: 0,
  categoryList: [],
  rarityList: [],
  productList: [],
  loginAdmin: {},
  loginAdmin_QiickSell: {},
  selectedCategory: [],
  selectedRarity: [],
  brandList: [],
  selectebrandList: [],
  selectePartnerList: [],
  selectedProductList: [],
  searchProducts: [],
  loderStatus: true,
  userCount: 0,
  productCount: 0,
  partnerCount: 0,
  filterproductList: [],
  pageCounts: 1,
  selectedBoxCategory: [],
  userData: {},
  allBoxList: [],
  allBotPlayList: [],
  allspecialTagList: [],
  boxLatestColor: [],
  withdrawRequests: [],
  withdrawData: [],
  openBoxCount: 0,
  pnL: 0,
  userTransactions: [],
  allOrders: [],
  totalProductsCount: 0,
  announcementList: [],
  announcementData: {},
  allBattles: [],
  battleData: {},
  allTeamData: {},
  teamDataById: {},
  userBoxList: [],
  playBattleList: [],
  userinventoryList: [],
  activityLogData: {},
  adminSetting: {
    boxProfit: 0,
  },
  allShippingData: {},
  shippingDataById: {},
  allFaqCategories: [],
  allFaqData: [],
  addFaq: {},
  editFaqData: {},
  depositTransactionsData: {},
  depositData: {},
  depositeDetails: {},
  loginHistory: [],
  allAffiliateLevelList: [],
  referrerList: [],
  allCountryList: [],
  allRaindropsList: [],
};
export const getAllCategoryList = createAsyncThunk(
  "admin/getCategory",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getCategory");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllRarityList = createAsyncThunk(
  "admin/getRarity",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getRarity");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllProductList = createAsyncThunk(
  "admin/getProduct",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().post("api/admin/getProduct", data);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getSearchProducts = createAsyncThunk(
  "admin/getSearchProducts",

  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().post(
        "api/admin/getSearchProducts",
        data
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllCategorySelectList = createAsyncThunk(
  "admin/getCategorylist",
  async (data, { rejectWithValue }) => {
    try {
      let blankArray = [];
      let blankArray2 = [];
      const res = await adminInstance().get("api/admin/getCategory");
      const { code, category } = res.data;
      if (code === 200) {
        category.forEach((el) => {
          if (el.categoryType === "box") {
            blankArray2.push({ value: el._id, label: el.name });
          } else {
            blankArray.push({ value: el._id, label: el.name });
          }
        });
      }
      return {
        categorySelectList: blankArray,
        categoryBoxSelectList: blankArray2,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllRaritySelectList = createAsyncThunk(
  "admin/getRaritylist",
  async (data, { rejectWithValue }) => {
    // console.log('data.....', data)
    try {
      let blankArray = [];
      const res = await adminInstance().get("api/admin/getRarity");
      const { code, rarity } = res.data;
      if (code === 200) {
        rarity.forEach((el) =>
          blankArray.push({ value: el._id, label: el.name })
        );
      }
      return {
        raritySelectList: blankArray,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllProductSelectList = createAsyncThunk(
  "admin/getProductList",
  async (data, { rejectWithValue }) => {
    try {
      let blankArray = [];
      const res = await adminInstance().post("api/admin/getProduct");
      const { code, product } = res.data;
      if (code === 200) {
        product.forEach((el) =>
          blankArray.push({ value: el._id, label: el.name })
        );
      }
      return {
        productSelectList: blankArray,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAdminData = createAsyncThunk(
  "admin/getAdminDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getAdminDetails");
      return {
        adminData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err,
        code: err,
      });
    }
  }
);

export const getQuickSell = createAsyncThunk(
  "admin/getQuickSellDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getQuickSellDetails");
      return {
        QuickSellData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err,
        code: err,
      });
    }
  }
);

export const getAllBrandSelectList = createAsyncThunk(
  "admin/getBrandlist",
  async (data, { rejectWithValue }) => {
    try {
      let blankArray = [];
      const res = await adminInstance().get("api/admin/getSelectBrandList");
      const { code, brand } = res.data;
      if (code === 200) {
        brand.forEach((el) =>
          blankArray.push({ value: el._id, label: el.name })
        );
      }
      return {
        resultData: blankArray,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const getAllPartnerSelectList = createAsyncThunk(
  "admin/getPartnerlist",
  async (data, { rejectWithValue }) => {
    try {
      let blankArray = [];
      const res = await adminInstance().get("api/admin/getSelectPartnerList");
      const { code, partner } = res.data;
      if (code === 200) {
        partner.forEach((el) =>
          blankArray.push({ value: el._id, label: el.firstname })
        );
      }
      return {
        resultData: blankArray,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getUserDetailById = createAsyncThunk(
  "admin/getUserDetailById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getUserDetailById/${data}`
      );
      // const {code} = response?.data;
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err,
        code: err,
      });
    }
  }
);

export const getUserTransactions = createAsyncThunk(
  "admin/getUserTransactions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getUserTransactions/${data}`
      );
      // const {code} = response?.data;
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err,
        code: err,
      });
    }
  }
);

export const getloginHistoryData = createAsyncThunk(
  "admin/getloginHistoryData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getloginHistoryData/${data}`
      );
      // const {code} = response?.data;
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err,
        code: err,
      });
    }
  }
);

// export const getRarityList = createAsyncThunk(
//   'admin/getSelectedRarityList',
//   async (data, { rejectWithValue }) => {
//     try {
//       let blankArray = []
//       const res = await adminInstance().get('api/admin/getSelectedRarityList')
//        const { code, partner } = res.data;
//        if(code === 200){
//         partner.forEach((el) =>
//         blankArray.push({ value: el._id, label: el.firstname })
//         )
//        }
//       return {
//         resultData: blankArray,
//       }
//     } catch (err) {
//       return rejectWithValue({
//         msg: err.response.data.msg,
//         code: err.response.data.code,
//       })
//     }
//   }
// )
export const getAllBoxesData = createAsyncThunk(
  "admin/getBoxList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().post("api/admin/getPlayBox", data);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllBotPlayList = createAsyncThunk(
  "admin/getAllBotPlayList",
  async (data, { rejectWithValue }) => {
    try {
      // const { setLoading } = data;
      // setLoading(true);
      const res = await adminInstance().get(
        `api/admin/getAllBotPlayList?id=${data}`
      );
      // setLoading(true);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateCurrentBoxesProfit = createAsyncThunk(
  "admin/updateCurrentBoxesProfit",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('data', data);
      const res = await adminInstance().put(
        "api/admin/updateCurrentBoxesProfit",
        data
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//userWithdrawRequestList

export const getWithdrawalRequests = createAsyncThunk(
  "admin/getWithdrawalRequests",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getWithdrawalRequests");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getWithdrawData = createAsyncThunk(
  "admin/getWithdrawData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getWithdrawData");
      console.log("res===============>", res)
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const withdrawApprovedStatus = createAsyncThunk(
  "admin/withdrawApprovedStatus",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/withdrawApprovedStatus",
        data
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getOrders = createAsyncThunk(
  "admin/getOrders",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getOrders");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getOrderDetailsByOrderId = createAsyncThunk(
  "admin/getOrderDetailsByOrderId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get(
        `api/admin/getOrderDetailsByOrderId/${data}`
      );
      // console.log('response=======>>>>>>>>>>>>>', res)
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//orders

export const updateOrders = createAsyncThunk(
  "admin/updateOrders",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('data', data);
      const res = await adminInstance().put("api/admin/updateOrders", data);
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//validatetoken
//orders

export const checkValidtoken = createAsyncThunk(
  "admin/checkValidtoken",
  async (data, { rejectWithValue }) => {
    try {
      // console.log('data ============>', data);
      const res = await adminInstance().get("api/admin/checkValidtoken");
      //console.log('data', res);
      const { code } = res.data || {};
      if (code === 401) {
        localStorage.removeItem("ad@#t");
        window.location.href = "/";
      }
      if (code)
        return {
          resultData: res,
        };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedProduct = createAsyncThunk(
  "admin/deleteSelectedProduct",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedProduct",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedBox = createAsyncThunk(
  "admin/deleteSelectedBox",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedBox",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedUsers = createAsyncThunk(
  "admin/deleteSelectedUsers",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedUsers",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedOrders = createAsyncThunk(
  "admin/deleteSelectedOrders",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedOrders",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedWithdrawRequest = createAsyncThunk(
  "admin/deleteSelectedWithdrawRequest",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedWithdrawRequest",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedCategory = createAsyncThunk(
  "admin/deleteSelectedCategory",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedCategory",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedRarity = createAsyncThunk(
  "admin/deleteSelectedRarity",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedRarity",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedBrand = createAsyncThunk(
  "admin/deleteSelectedBrand",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedBrand",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedPartner = createAsyncThunk(
  "admin/deleteSelectedPartner",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedPartner",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteSelectedDepositeTransaction = createAsyncThunk(
  "admin/deleteSelectedDepositeTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedDepositeTransaction",
        data
      );
      // console.log('DATA hai ', res)
      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//announcements

export const createAnnouncement = createAsyncThunk(
  "admin/createAnnouncement",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await adminInstance().post(
        "api/admin/createAnnouncement",
        data
      );
      const { code } = res?.data;
      if (code === 200) {
        toast.success("Added Successfully");
        dispatch(getAnnouncement());
      }
      // console.log('annoucementcreated',res)

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "admin/getAnnouncement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getAnnouncement");

      // console.log('annoucementcreated', res);

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAnnouncementDetailById = createAsyncThunk(
  "admin/getAnnouncementDetailById",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get(
        `api/admin/getAnnouncementDetailById/${data}`
      );

      console.log("annoucementcreated", res);

      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteSelectedAnnouncement = createAsyncThunk(
  "admin/deleteSelectedAnnouncement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedAnnouncement",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteAnnouncement = createAsyncThunk(
  "admin/deleteAnnouncement",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await adminInstance().put(
        `api/admin/deleteAnnouncement/${data}`
      );
      const { code } = res?.data;
      if (code === 200) {
        toast.success("Deleted Successfully");
        dispatch(getAnnouncement());
      }
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateAnnouncement = createAsyncThunk(
  "admin/updateAnnouncement",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const ancId = data?.get("_id");
      // console.log('ancId',ancId)
      const res = await adminInstance().put(
        `api/admin/updateAnnouncement/${ancId}`,
        data
      );
      // console.log('hdhsdhshdhsdhsahdsahdsa', res);
      const { code } = res?.data;
      if (code === 200) {
        toast.success("Updated Successfully");
        dispatch(getAnnouncement());
      }

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

//battles
export const createBattle = createAsyncThunk(
  "admin/createBattle",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().post("/api/admin/createBattle", data);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getBattles());
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBattles = createAsyncThunk(
  "admin/getBattles",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().get(`/api/admin/getBattles/${data}`);
      // console.log('res===>>>>', res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getBattleById = createAsyncThunk(
  "admin/getBattleById",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      // console.log('sdasadsa', data);
      const res = await adminInstance().get(`/api/admin/getBattleById/${data}`);
      // console.log('singleBattledata===>>>>', res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateBattles = createAsyncThunk(
  "admin/updateBattles",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // const history = useHistory();
      // console.log('data', data);
      const res = await adminInstance().put(
        `/api/admin/updateBattles/${data._id}`,
        data
      );
      // console.log('updatedata', res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getBattles());
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteBattles = createAsyncThunk(
  "admin/deleteBattles",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().delete(
        `/api/admin/deleteBattles/${data}`
      );
      // console.log('res===>>>>', res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getBattles());
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const deleteSelectedBattles = createAsyncThunk(
  "admin/deleteSelectedBattles",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().delete(
        `/api/admin/deleteSelectedBattles`,
        { data: data }
      );
      // console.log('res===>>>>',res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getBattles());
        // history.push('/battles')
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const addTeam = createAsyncThunk(
  "admin/addTeam",
  async (data, { rejectWithValue }) => {
    const showToast = toast("processing", {
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      toastId: "oastId",
    });
    try {
      const res = await adminInstance().post("/api/admin/addTeam", data);
      return {
        showToast,
        msg: res.data.msg,
        code: res.data.code,
      };
    } catch (err) {
      return rejectWithValue({
        showToast,
        msg: err.response.data.msg || err.response.data.error,
        code: err.response.data.code,
      });
    }
  }
);
export const getAllTeamsData = createAsyncThunk(
  "admin/getAllTeamsData",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().get(`/api/admin/getAllTeamsData`);
      return {
        allTeamData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedTeams = createAsyncThunk(
  "admin/deleteSelectedTeams",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // console.log("your data will be delete ");
      const res = await adminInstance().delete(
        `/api/admin/deleteSelectedTeams`,
        { data: data }
      );
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getAllTeamsData());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const updateTeamData = createAsyncThunk(
  "admin/updateTeamData",
  async (data, { rejectWithValue }) => {
    const showToast = toast("processing", {
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      toastId: "updateProfile_toastId",
    });
    try {
      const res = await adminInstance().put("/api/admin/updateTeamData", data);
      return { showToast, code: res.data.code, msg: res.data.msg };
    } catch (err) {
      return rejectWithValue({
        showToast,
        code: err.response.data.code || 501,
        msg: err.response.data.msg ?? err.response.data.error,
      });
    }
  }
);
export const getTeamDetailById = createAsyncThunk(
  "admin/getTeamDetailById",
  async (data, { rejectWithValue }) => {
    const { userId } = data || {};
    try {
      const res = await adminInstance().get("/api/admin/getTeamDetailById", {
        params: { userId },
      });
      return {
        teamDataById: res?.data,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const getAllActivityLogsData = createAsyncThunk(
  "admin/getAllActivityLogsData",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().get(
        `/api/admin/getAllActivityLogsData`
      );
      return {
        activityLogData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const deleteSelectedLogs = createAsyncThunk(
  "admin/deleteSelectedLogs",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/deleteSelectedLogs",
        data
      );

      return {
        resultData: res.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const createShipping = createAsyncThunk(
  "admin/createShipping",
  async (data, { rejectWithValue }) => {
    const showToast = toast("processing", {
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      toastId: "oastId",
    });
    try {
      const res = await adminInstance().post("/api/admin/createShipping", data);
      return {
        showToast,
        msg: res.data.msg,
        code: res.data.code,
      };
    } catch (err) {
      return rejectWithValue({
        showToast,
        msg: err.response.data.msg || err.response.data.error,
        code: err.response.data.code,
      });
    }
  }
);
export const getAllShippingData = createAsyncThunk(
  "admin/getAllShippingData",
  async (data, { rejectWithValue }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().get(`/api/admin/getAllShippingData`);
      return {
        allShippingData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);
export const getShippingDetailById = createAsyncThunk(
  "admin/getShippingDetailById",
  async (data, { rejectWithValue }) => {
    const { shippingId } = data || {};
    try {
      const res = await adminInstance().get(
        "/api/admin/getShippingDetailById",
        {
          params: { shippingId },
        }
      );
      return {
        shippingDataById: res?.data,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const deleteSelectedDeliveryMethod = createAsyncThunk(
  "admin/deleteSelectedDelivery",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await adminInstance().delete(
        `/api/admin/deleteSelectedDeliveryMethod`,
        { data: data }
      );
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getAllShippingData());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getDepositeTransactions = createAsyncThunk(
  "admin/getDepositeTransactions",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get(
        "api/admin/getDepositeTransactions"
      );
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getDepositData = createAsyncThunk(
  "admin/getDepositData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("api/admin/getDepositData");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

// export const getDepositeTransactionsById = createAsyncThunk(
//   "admin/getDepositeTransactionsById",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await adminInstance().get(
//         `api/admin/getDepositeTransactionsById/${data}`
//       );
//       // console.log("Check response===>", res)
//       return {
//         resultData: res,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         msg: err.response.data.msg,
//         code: err.response.data.code,
//       });
//     }
//   }
// );
export const getDepositDataById = createAsyncThunk(
  "admin/getDepositDataById",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get(
        `api/admin/getDepositDataById/${data}`
      );
      // console.log("Check response===>", res)
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getAllfaqCategoryData = createAsyncThunk(
  "admin/getAllfaqCategoryData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().get("/api/admin/getAllfaqCategoryData");
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const addFaq = createAsyncThunk(
  "admin/addFaq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().post(`/api/admin/addFaq`, data);
      // console.log('response IN ADDFAQ =>>>', response)
      return {
        response: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const editFaqData = createAsyncThunk(
  "admin/editFaqData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().put(
        `/api/admin/editFaqData/${data}`
      );
      // console.log('response=>>>', response)
      return {
        response: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const getAllfaqData = createAsyncThunk(
  "admin/getAllfaqData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get("/api/admin/getAllfaqData");
      // console.log('response=>>>', response)
      return {
        response: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const deleteFaq = createAsyncThunk(
  "admin/deleteFaq",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      // const history = useHistory();
      const res = await adminInstance().delete(
        `/api/admin/deleteFaq/${data}`,
        data
      );

      const { code } = res?.data;
      if (code === 200) {
        dispatch(getAllfaqData());
        toast.success("Successfully Deleted the data");
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const addAffiliateLevel = createAsyncThunk(
  "admin/addAffiliateLevel",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      console.log("data", data);
      const res = await adminInstance().post(
        `/api/admin/addAffiliateLevel/`,
        data
      );
      return {
        response: res,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);

export const updateAffiliateLevel = createAsyncThunk(
  "admin/updateAffiliateLevel"
);

export const getAllAffiliateLevels = createAsyncThunk(
  "admin/getAllAffiliateLevels",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get(
        "/api/admin/getAllAffiliateLevels"
      );

      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);

export const deleteAffiliateLevel = createAsyncThunk(
  "admin/deleteAffiliateLevel",
  async (data, { rejectWithValue }) => {
    console.log("hii this is my id ", data);
    try {
      const response = await adminInstance().delete(
        `/api/admin/deleteAffiliateLevel/${data}`
      );
      console.log("response", response);
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);

export const deleteSelectedAffiliateLevel = createAsyncThunk(
  "admin/deleteSelectedAffiliateLevel",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      console.log("your data will be delete ");
      const res = await adminInstance().delete(
        "/api/admin/deleteSelectedAffiliateLevel",
        { data: data }
      );

      console.log("this delete all function ", res);
      const { code, msg } = res?.data;
      if (code === 200) {
        toast.success(msg);
        dispatch(getAllAffiliateLevels());
      }
      return {
        resultData: res,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);

export const getReferrerUsers = createAsyncThunk(
  "admin/getReferrerUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get("/api/admin/getReferrerUsers");

      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);
export const addCountry = createAsyncThunk(
  "admin/addCountry",
  async (data, { rejectWithValue }) => {
    console.log("Data===>", data);
    try {
      const response = await adminInstance().post(
        `/api/admin/addCountry`,
        data
      );

      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);

export const getCountryData = createAsyncThunk(
  "admin/getCountryData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get("/api/admin/getCountryData");
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
);

export const getRainDropData = createAsyncThunk(
  "admin/getRainDropData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminInstance().get("/api/admin/getRainDropData");
      return {
        resultData: response,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.response.data.code || 501,
        msg: err.response.data.msg,
      });
    }
  }
)

export const updateCountryStatus = createAsyncThunk(
  "admin/updateCountryStatus",
  async (data, { rejectWithValue }) => {
    try {
      const res = await adminInstance().put(
        "api/admin/updateCountryStatus",
        data
      );
      console.log("Responese", res);
      return {
        resultData: res,
      };
    } catch (err) {
      console.log("err", err?.response?.data);
      return rejectWithValue({
        msg: err.response.data.msg,
        code: err.response.data.code,
      });
    }
  }
);


export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetProducts: (state, action) => {
      // console.log('sdhsadhbsjhgdjshgdsh');
      state.productList = [];
    },
    resetAnnouncementData: (state, action) => {
      state.announcementData = {};
    },
    resetTeamData: (state, action) => {
      state.teamDataById = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllCategoryList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.categoryList = resultData?.data?.category;
      state.loderStatus = false;
    });
    builder.addCase(getAllCategoryList.rejected, (state, { payload }) => {});

    builder.addCase(getAllRarityList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.rarityList = resultData?.data?.rarity;
    });
    builder.addCase(getAllRarityList.rejected, (state, { payload }) => {});

    builder.addCase(getAllProductList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.productList = resultData?.data?.product;
      state.totalProductsCount = resultData?.data?.totalProductsCount;
    });

    builder.addCase(getSearchProducts.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.searchProducts = resultData?.data?.products;
      state.productList = resultData?.data?.products;
      state.pageCounts = resultData?.data?.count;
      state.allspecialTagList = resultData?.data?.specialTagList;
      state.boxLatestColor = resultData?.data?.latestColor;
    });

    builder.addCase(getAdminData.fulfilled, (state, { payload }) => {
      const { adminData } = payload || {};
      //console.log('admin datattaa==>', adminData?.data?.settings)
      state.loginAdmin = adminData?.data?.adminDetails;
      state.partnerCount = adminData?.data?.partnerCount;
      state.productCount = adminData?.data?.productCount;
      state.userCount = adminData?.data?.userCount;
      state.adminSetting = adminData?.data?.settings;
      //state.adminSetting.profitMarginBox = 10;
    });

    builder.addCase(getAdminData.rejected, (state, { payload }) => {});

    builder.addCase(getQuickSell.fulfilled, (state, { payload }) => {
      const { QuickSellData } = payload || {};
      state.loginAdmin_QiickSell = QuickSellData?.data?.QuickDetails;
    });

    builder.addCase(getQuickSell.rejected, (state, { payload }) => {});

    builder.addCase(
      getAllCategorySelectList.fulfilled,
      (state, { payload }) => {
        const { categorySelectList, categoryBoxSelectList } = payload || {};
        state.selectedCategory = categorySelectList;
        state.selectedBoxCategory = categoryBoxSelectList;
      }
    );
    builder.addCase(
      getAllCategorySelectList.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getAllRaritySelectList.fulfilled, (state, { payload }) => {
      const { raritySelectList } = payload || {};
      state.selectedRarity = raritySelectList;
    });
    builder.addCase(
      getAllRaritySelectList.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getAllProductSelectList.fulfilled, (state, { payload }) => {
      const { productSelectList } = payload || {};
      state.selectedProductList = productSelectList;
    });

    builder.addCase(
      getAllProductSelectList.rejected,
      (state, { payload }) => {}
    );
    builder.addCase(getAllBrandSelectList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.selectebrandList = resultData;
    });
    builder.addCase(getAllBrandSelectList.rejected, (state, { payload }) => {});
    builder.addCase(getAllPartnerSelectList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.selectePartnerList = resultData;
    });
    builder.addCase(
      getAllPartnerSelectList.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getUserDetailById.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.userData = resultData?.data?.userData;
      state.openBoxCount = resultData?.data?.openBoxCount;
      state.pnL = resultData?.data?.pnL;
      state.userBoxList = resultData?.data?.boxList;
      state.userTransactions = resultData?.data?.transactionsData;
      state.playBattleList = resultData?.data?.playBattleList;
      state.userinventoryList = resultData?.data?.inventoryList;
    });

    builder.addCase(getAllBoxesData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allBoxList = resultData?.data?.box;
      state.pageCounts = resultData?.data?.count;
    });
    builder.addCase(getAllBoxesData.rejected, (state, { payload }) => {});
    builder.addCase(getAllBotPlayList.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allBotPlayList = resultData?.data?.list;
    });
    builder.addCase(getAllBotPlayList.rejected, (state, { payload }) => {});

    builder.addCase(
      updateCurrentBoxesProfit.fulfilled,
      (state, { payload }) => {
        // const { resultData } = payload || {}
        // state.allBotPlayList = resultData?.data?.list
      }
    );
    builder.addCase(
      updateCurrentBoxesProfit.rejected,
      (state, { payload }) => {}
    );

    //userWithdrawRequestList
    builder.addCase(getWithdrawalRequests.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.withdrawRequests = resultData?.data?.withdrawRequests;
    });
    builder.addCase(getWithdrawalRequests.rejected, (state, { payload }) => {});



    builder.addCase(getWithdrawData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.withdrawData = resultData?.data?.withdrawData;
    });
    builder.addCase(getWithdrawData.rejected, (state, { payload }) => {});









    builder.addCase(withdrawApprovedStatus.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.withdrawRequests = resultData?.data?.withdrawRequests;
    });
    builder.addCase(
      withdrawApprovedStatus.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getUserTransactions.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.userTransactions = resultData?.data?.userTransactions;
    });
    builder.addCase(getUserTransactions.rejected, (state, { payload }) => {});

    builder.addCase(getloginHistoryData.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log('Check result LOGINHISTORY', resultData)
      state.loginHistory = resultData?.data?.loginHistory;
    });
    builder.addCase(getloginHistoryData.rejected, (state, { payload }) => {});

    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allOrders = resultData?.data?.allOrders;
    });
    builder.addCase(getOrders.rejected, (state, { payload }) => {});

    builder.addCase(
      getOrderDetailsByOrderId.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        state.orderDetails = resultData?.data?.fullDetails;
      }
    );
    builder.addCase(
      getOrderDetailsByOrderId.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(updateOrders.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
      // state.allOrders = resultData?.data?.allOrders
    });
    builder.addCase(updateOrders.rejected, (state, { payload }) => {});

    builder.addCase(updateCountryStatus.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {}
      // state.allOrders = resultData?.data?.allOrders
    });
    builder.addCase(updateCountryStatus.rejected, (state, { payload }) => {});

    builder.addCase(deleteSelectedProduct.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedProduct.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(deleteSelectedBox.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedBox.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });

    builder.addCase(deleteSelectedUsers.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedUsers.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(deleteSelectedOrders.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedOrders.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(
      deleteSelectedWithdrawRequest.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        if (resultData.code === 200) {
          toast.success("Your selected items deleted", {
            toastId: "abrar singh",
          });
        }
      }
    );
    builder.addCase(
      deleteSelectedWithdrawRequest.rejected,
      (state, { payload }) => {
        const { msg } = payload || {};
        toast.success(msg, {
          toastId: "abrar singh",
        });
      }
    );
    builder.addCase(deleteSelectedCategory.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedCategory.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(deleteSelectedRarity.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedRarity.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(deleteSelectedBrand.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedBrand.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(deleteSelectedPartner?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedPartner?.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });

    builder.addCase(
      deleteSelectedDepositeTransaction?.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        // console.log("Result 123====>", resultData)
        if (resultData.code === 200) {
          toast.success("Your selected items deleted", {
            toastId: "abrar singh",
          });
        }
      }
    );
    builder.addCase(
      deleteSelectedDepositeTransaction?.rejected,
      (state, { payload }) => {
        const { msg } = payload || {};
        toast.success(msg, {
          toastId: "abrar singh",
        });
      }
    );

    builder.addCase(getAnnouncement?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log(' resultData?.data?.announcements;', resultData);
      state.announcementList = resultData?.data?.announcements;
    });
    builder.addCase(getAnnouncement?.rejected, (state, { payload }) => {});

    builder.addCase(
      getAnnouncementDetailById?.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        // console.log(' resultData?.data?.announcements;', resultData);
        state.announcementData = resultData?.data?.announcementData;
      }
    );
    builder.addCase(
      getAnnouncementDetailById?.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getBattles?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log(' resultData?.data?.announcements;', resultData);
      state.allBattles = resultData?.data?.allBattles;
    });
    builder.addCase(getBattles?.rejected, (state, { payload }) => {});

    builder.addCase(getBattleById?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log(' resultData?.data?.announcements;',resultData)
      state.battleData = resultData?.data?.battleData;
    });
    builder.addCase(getBattleById?.rejected, (state, { payload }) => {});
    builder.addCase(addTeam.fulfilled, (state, { payload }) => {
      const { showToast, msg } = payload || {};

      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.SUCCESS,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(addTeam.rejected, (state, { payload }) => {
      const { showToast, msg } = payload || {};

      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.ERROR,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(getAllTeamsData?.fulfilled, (state, { payload }) => {
      const { allTeamData } = payload || {};
      // console.log(' getAllTeamsData?.data?.announcements;', allTeamData);
      state.allTeamData = allTeamData?.data?.allTeamsData;
    });
    builder.addCase(getAllTeamsData?.rejected, (state, { payload }) => {});
    builder.addCase(deleteSelectedTeams?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected items deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedTeams?.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });
    builder.addCase(getTeamDetailById.fulfilled, (state, { payload }) => {
      const { teamDataById } = payload || {};
      state.teamDataById = teamDataById?.teamData;
    });
    builder.addCase(updateTeamData.fulfilled, (state, { payload }) => {
      const { showToast, msg } = payload || {};
      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.SUCCESS,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(updateTeamData.rejected, (state, { payload }) => {
      const { showToast, msg } = payload || {};

      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.ERROR,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(getAllActivityLogsData?.fulfilled, (state, { payload }) => {
      const { activityLogData } = payload || {};
      state.activityLogData = activityLogData?.data?.allLogsData;
    });
    builder.addCase(
      getAllActivityLogsData?.rejected,
      (state, { payload }) => {}
    );
    builder.addCase(deleteSelectedLogs.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      if (resultData.code === 200) {
        toast.success("Your selected logs deleted", {
          toastId: "abrar singh",
        });
      }
    });
    builder.addCase(deleteSelectedLogs.rejected, (state, { payload }) => {
      const { msg } = payload || {};
      toast.success(msg, {
        toastId: "abrar singh",
      });
    });

    builder.addCase(createShipping.fulfilled, (state, { payload }) => {
      const { showToast, msg } = payload || {};

      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.SUCCESS,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(createShipping.rejected, (state, { payload }) => {
      const { showToast, msg } = payload || {};

      toast.update(showToast, {
        render: msg,
        type: toast.TYPE.ERROR,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        autoClose: 5000,
        className: "rotateY animated",
      });
    });
    builder.addCase(getAllShippingData?.fulfilled, (state, { payload }) => {
      const { allShippingData } = payload || {};
      state.allShippingData = allShippingData?.data?.shippingData;
    });
    builder.addCase(getAllShippingData?.rejected, (state, { payload }) => {});
    builder.addCase(getShippingDetailById.fulfilled, (state, { payload }) => {
      const { shippingDataById } = payload || {};
      state.shippingDataById = shippingDataById?.shippingData;
    });
    builder.addCase(
      deleteSelectedDeliveryMethod?.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        if (resultData.code === 200) {
          toast.success("Your selected Data deleted", {
            toastId: "abrar singh",
          });
        }
      }
    );
    builder.addCase(
      deleteSelectedDeliveryMethod?.rejected,
      (state, { payload }) => {
        const { msg } = payload || {};
        toast.success(msg, {
          toastId: "abrar singh",
        });
      }
    );
    //deposite
    builder.addCase(
      getDepositeTransactions?.fulfilled,
      (state, { payload }) => {
        const { resultData } = payload || {};
        state.depositTransactionsData =
          resultData?.data?.depositTransactionsData;
      }
    );
    builder.addCase(
      getDepositeTransactions?.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getDepositData?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.depositData = resultData?.data?.depositData;
    });
    builder.addCase(getDepositData?.rejected, (state, { payload }) => {});

    builder.addCase(getDepositDataById.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log("Result ===>", resultData)
      state.depositeDetails = resultData?.data?.depositeDetails;
    });
    builder.addCase(getDepositDataById.rejected, (state, { payload }) => {});

    builder.addCase(getAllfaqCategoryData?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allFaqCategories = resultData?.data?.allFaqCategories;
    });
    builder.addCase(
      getAllfaqCategoryData?.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(getAllfaqData?.fulfilled, (state, { payload }) => {
      const { response } = payload || {};
      state.allFaqData = response?.data?.allFaqData;
      // state.code=response?.data?.code
    });
    builder.addCase(getAllfaqData?.rejected, (state, { payload }) => {});
    builder.addCase(addFaq?.fulfilled, (state, { payload }) => {
      //const { response } = payload || {};
      // state.addFaq = response?.data?.addFaq
    });
    builder.addCase(addFaq?.rejected, (state, { payload }) => {});

    builder.addCase(editFaqData?.fulfilled, (state, { payload }) => {
      //const { response } = payload || {};
      // state.editFaqData = response?.data?.editFaqData
    });
    builder.addCase(editFaqData?.rejected, (state, { payload }) => {});

    builder.addCase(deleteFaq?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      // console.log("data is show in deleted ", resultData)
      if (resultData.code === 200) {
        toast.success("Your selected Data deleted");
      }
    });
    builder.addCase(deleteFaq?.rejected, (state, { payload }) => {
      // toast.success(msg, {
      // 	// toastId: 'abrar singh',
      // });
    });

    builder.addCase(addAffiliateLevel?.fulfilled, (state, { payload }) => {});
    builder.addCase(addAffiliateLevel?.rejected, (state, { payload }) => {});

    builder.addCase(getAllAffiliateLevels?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      console.log("response", resultData);
      state.allAffiliateLevelList = resultData?.data?.allAffiliateLevels;
    });

    builder.addCase(
      getAllAffiliateLevels?.rejected,
      (state, { payload }) => {}
    );

    builder.addCase(deleteAffiliateLevel?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      console.log(resultData);
    });

    builder.addCase(deleteAffiliateLevel?.rejected, (state, { payload }) => {
      const { msg } = payload;
      console.log(msg);
    });

    builder.addCase(getReferrerUsers?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      console.log("response", resultData);
      state.referrerList = resultData?.data?.referrerList;
    });

    builder.addCase(getReferrerUsers?.rejected, (state, { payload }) => {});

    builder.addCase(getCountryData?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allCountryList = resultData?.data?.allCountryList;
    });

    builder.addCase(getCountryData?.rejected, (state, { payload }) => {});



    builder.addCase(getRainDropData?.fulfilled, (state, { payload }) => {
      const { resultData } = payload || {};
      state.allRaindropsList = resultData?.data?.allRaindrops;
    });

    builder.addCase(getRainDropData?.rejected, (state, { payload }) => {});






    builder.addCase(addCountry?.fulfilled, (state, { payload }) => {
      // const { resultData } = payload || {};
      // console.log("response", resultData);
    });

    builder.addCase(addCountry?.rejected, (state, { payload }) => {});
  },
});
// Action creators are generated for each case reducer function

export const { resetProducts, resetAnnouncementData, resetTeamData } =
  adminSlice.actions;

export default adminSlice.reducer;
