import React, { useEffect } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	FormGroup,
	Label,
} from 'reactstrap';
import { getShippingDetailById } from '../../reducers/adminReducers';
// import deleteimg from '../../assets/images/delete-icon.png';
import '../../assets/styles/deletePopup.css'
import { useDispatch, useSelector } from 'react-redux';
const UpdateShippingType = ({ onCloseModal, open, id, handleUpdate, updateShipping, setUpdateShipping, title }) => {
	const dispatch = useDispatch()
	const result = useSelector((state) => state.admin)
	const { shippingDataById } = result || {}
	useEffect(() => {
		if (id) {
			dispatch(getShippingDetailById({ shippingId: id }))
			setUpdateShipping('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])
	useEffect(() => {
		if (shippingDataById) {
			setUpdateShipping({ ...updateShipping, shippingName: shippingDataById?.shippingName })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shippingDataById, title])
	const handleOnchange = (e) => {
		const { name, value } = e.target
		setUpdateShipping({ ...updateShipping, [name]: value })
	}
	return (
		<Modal
			className="delete-modal-dialog delete_modal_dialog"
			isOpen={open}
			toggle={() => onCloseModal('close')}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={() => onCloseModal('close')}
			></ModalHeader>
			<ModalBody>

				<FormGroup className='form__group'>
					<Label className="col-form-label add_title pt-0">
						{title}
					</Label>
					<input
						className='form-control'
						type="text"
						name='shippingName'
						defaultValue={updateShipping?.shippingName || ''}
						onChange={(e) => handleOnchange(e)}
					/>



				</FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={(e) => handleUpdate(id)}
				>
					Submit
				</Button>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={onCloseModal}
				>
					Cancel
				</Button>

			</ModalFooter>
		</Modal>
	);
};
export default UpdateShippingType;
