/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
// import { useSelector, useDispatch } from 'react-redux'
// import { adminInstance } from '../../config/axios'
// import { getAllRarityList } from '../../../reducers/adminReducers'
// import ListDatatable from './listdatatable';
import ConfirmationPopUp from '../common/ConfirmationPopUp';
// import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getDepositeTransactions , getDepositData} from '../../reducers/adminReducers';
import { toast } from 'react-toastify';
import Loader from '../loader/loader';
import ListDatatable from './listdatatable';
// import InvoicePopUp from './invoice';
const DepositeManagement = () => {
    const dispatch = useDispatch();
    const result = useSelector((state) => state.admin);
    console.log("result==>", result)
    const { depositData } = result || {};
    // console.log("depositTransactionsData===>", depositData)
    const [open, setOpen] = useState(false);
    const [dummy, setDummy] = useState(false);
    const [activeId, setActiveId] = useState('')
    // const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDepositeData = async () => {
        setLoading(true)
        const response = await dispatch(getDepositData())
        // console.log('This is boat play ', response?.payload)
        if (response?.payload) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDepositeData()
        // getDeposite();

    }, [dummy])

    const handleConfirm = async (id) => {
        // console.log("ID IN DELETE TRANSACTION ====>", id)
        setDummy(false);
        try {
            // console.log('payload====>>', selected);
            // const newPayload = { ...payload }
            // newPayload.selected = selected
            // setOpen(false);

            // dispatch(updateOrders(newPayload));
            dispatch(getDepositeTransactions());
            onCloseModal();
            toast.success('Order Status Updated Successfully');
        } catch (error) { }
    };
    const onCloseModal = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Breadcrumb title="Deposite Transaction List" parent="Deposite" />
            <Container fluid={true}>
                <div className="admin-card">
                    <Card>
                        <CardHeader>
                            <h5>Deposite</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="btn-popup pull-right">

                            </div>
                            <div className="clearfix"></div>
                            <div
                                id="batchDelete"
                                className="category-table user-list order-table coupon-list-delete deposit-table"
                            >

                                {
                                    !loading ? (
                                        depositData.length > 0 && (
                                            <ListDatatable
                                                multiSelectOption={true}
                                                myData={depositData}
                                                pageSize={10}
                                                pagination={true}
                                                class="-striped -highlight"
                                                setActiveId={setActiveId}
                                                setOpen={setOpen}
                                            />
                                        )
                                    ) : (
                                        <Loader />
                                    )
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Container>
            <ConfirmationPopUp
                // title={`Are You Sure Want to ${payload?.displayName} ?`}
                // subtitle=""
                open={open}
                handleConfirm={handleConfirm}
                onCloseModal={onCloseModal}
                id={activeId}

            />
            {/* <InvoicePopUp
				title={`Are You Sure Want to ${payload?.displayName} ?`}
				subtitle=""
				open={open}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				displayName={payload?.displayName}
				// handleReason={handleReason}
				payload={payload}
				orderDetails={orderDetails}
				selected={selected}
				handleSelected={handleSelected}
				selectedlen={selectedlen}
			/> */}
        </Fragment>
    );
};
export default DepositeManagement;
