import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import ProductForm from "./createProductForm";
import { adminInstance } from "../../config/axios";
//import { queryString } from '../../utils/queryString'
//import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  getAllCategorySelectList,
  getAllRaritySelectList,
  getAllBrandSelectList,
  getAllPartnerSelectList,
  getAllProductSelectList,
} from "../../reducers/adminReducers";
import Loader from "../loader/loader";
const Create_product = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const {
    selectedCategory,
    selectedRarity,
    // selectebrandList,
    selectePartnerList,
    adminSetting,
  } = result || {};
  const { quickSellPercentage } = adminSetting || {};
  // console.log('list data====>', { result });
  const sizeArray = [
    {
      value: "S",
      label: "Small",
    },
    {
      value: "M",
      label: "Medium",
    },
    {
      value: "L",
      label: "Large",
    },
    {
      value: "XL",
      label: "Extra Large",
    },
  ];

  const productNatureArray = [
    {
      label: "Digital",
      value: "Digital",
    },
    {
      label: "Physical",
      value: "Physical",
    },
  ];

  const colorArray = [
    {
      label: "Gold",
      value: "Gold",
    },
    {
      label: "Red",
      value: "Red",
    },
    {
      label: "Purple",
      value: "Purple",
    },
    {
      label: "Green",
      value: "Green",
    },
    {
      label: "Blue",
      value: "Blue",
    },
    {
      label: "Gray",
      value: "Gray",
    },
  ];
  const initialObj = {
    id: "",
    name: "",
    bannerImage: "",
    price: "",
    size: "",
    rarityLevel: "",
    quickSellPercentage: quickSellPercentage || 0,
    productTag: "",
    productNature: "",
    description: "",
    categoryId: [],
    color: "",
    capacity: [],
    image: [],
    brandId: "",
    partnerId: "",
    totalProduct: 0,
    quickSell: true,
    visibility: true,
    productCode: "",
    setCategory: [],
    setSize: [],
    setColor: [],
    setCapacity: [],
    setBrand: [],
    setPartner: [],
    setRarity: [],
    setProductType: [],
    checkTrack: false,
    trackQuantity: 0,
    enableProduct: false,
    istotal: false,
  };
  const [payload, setPayload] = useState(initialObj);
  // console.log('payload--->>>', payload);
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let productId = query.get("id");
  // console.log('productId------', productId);

  const getSingleProductData = async (id) => {
    try {
      setLoading(true);
      const response = await adminInstance().get(
        `api/admin/getProductById/${id}`
      );
      const { code, getProduct } = response.data;
      // console.log('code, getProduct----', code, getProduct);
      if (code === 200) {
        getProduct.color = getProduct?.color?.toString();
        getProduct.size = getProduct?.size?.toString();
        setPayload(getProduct);
        setRectSelectedData(getProduct);
        setLoading(false);
      }
    } catch (error) {
      // console.log('----------->aaa>>', error);
    }
  };
  const setRectSelectedData = async (getProduct) => {
    let copyPayload = getProduct;
    if (selectedCategory.length) {
      let filter1 = selectedCategory.filter((item) =>
        getProduct.categoryId.includes(item.value)
      );
      copyPayload.setCategory = filter1;
      // console.log('filter1==>', filter1);
    }
    if (selectedRarity.length) {
      let filter2 = selectedRarity.filter((item) =>
        getProduct.rarityLevel.includes(item.value)
      );
      copyPayload.setRarity = filter2;
      // console.log('filter2==>', filter2);
    }
    if (selectePartnerList.length) {
      let filter3 = selectePartnerList.filter((item) =>
        getProduct?.partnerId?.includes(item.value)
      );
      copyPayload.setPartner = filter3;
      // console.log('filter3==>', filter3);
    }
    // if (selectebrandList.length) {
    //   let filter4 = selectebrandList.filter((item) =>
    //     getProduct.brandId.includes(item.value)
    //   );
    //   copyPayload.setBrand = filter4;
    //   // console.log('filter4==>', filter4);
    // }
    if (getProduct.size) {
      let filter5 = sizeArray.filter((item) =>
        getProduct.size.includes(item.value)
      );
      copyPayload.setSize = filter5;
      // console.log('filter4==>', filter5);
    }
    if (getProduct.productNature) {
      let filter6 = productNatureArray.filter((item) =>
        getProduct.productNature.includes(item.value)
      );
      copyPayload.setProductType = filter6;
      // console.log('filter4==>', filter6);
    }
    if (getProduct.color) {
      let filter7 = colorArray.filter((item) =>
        getProduct.color.includes(item.value)
      );
      copyPayload.setColor = filter7;
      // console.log('filter4==>', filter7);
    }
    if (getProduct.capacity) {
      let filter8 = sizeArray.filter((item) =>
        getProduct.capacity.includes(item.value)
      );
      copyPayload.setCapacity = filter8;
      // console.log('filter4==>', filter8);
    }
    setPayload(copyPayload);
    setEditStatus(true);
  };
  useEffect(() => {
    if (productId) {
      getSingleProductData(productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!productId) {
      setEditStatus(true);
    }
  }, [productId]);
  useEffect(() => {
    if (localStorage.getItem("ad@#t")) {
      dispatch(getAllCategorySelectList());
      dispatch(getAllRaritySelectList());
      dispatch(getAllProductSelectList());
      dispatch(getAllBrandSelectList());
      dispatch(getAllPartnerSelectList());
    }
  }, [dispatch, productId]);
  return (
    <Fragment>
      <Breadcrumb title="Product" parent="Product" />
      {editStatus && (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> Add Product</h5>
                </CardHeader>
                <CardBody>
                  {productId && editStatus ? (
                    payload?._id &&
                    (!loading ? (
                      <ProductForm
                        payload={payload}
                        setPayload={setPayload}
                        sizeArray={sizeArray}
                        productNatureArray={productNatureArray}
                        colorArray={colorArray}
                      />
                    ) : (
                      <Loader />
                    ))
                  ) : (
                    <ProductForm
                      payload={payload}
                      setPayload={setPayload}
                      sizeArray={sizeArray}
                      productNatureArray={productNatureArray}
                      colorArray={colorArray}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default Create_product;
