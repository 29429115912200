import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import DataTableExtensions from "react-data-table-component-extensions";
// import placeholder from '../../../assets/images/fashion/product/12.jpg'
// import { useHistory } from 'react-router-dom'
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import {searchData} from '../../../utils/queryString'
import DeleteConfirmForSelected from "../common/deleteConfirmForSelected";
import { currencyFormat } from "../../utils/currencyFormater";
import {
  deleteSelectedWithdrawRequest,
  getWithdrawalRequests,
} from "../../reducers/adminReducers";
// import { toast } from "react-toastify";
const ListDatatable = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
  setPayload,
}) => {
  // const history = useHistory()
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);

  const { loginAdmin } = result || {};
  const { role } = loginAdmin;
  const [data, setData] = useState();
  const [selectedBox, setSelectedBox] = useState([]);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(true);
  useEffect(() => {
    setData(myData);
  }, [myData]);
  const columnDefs = [
    {
      name: "User Name",
      selector: (row) => row?.userId?.username,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => currencyFormat(row.amount),
      sortable: true,
    },
    // {
    //   name: "Date/Time",
    //   selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
    //   sortable: true,
    // },
    {
      name: "User Type",
      selector: (row) => row?.userId?.userType,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row.paymentType,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.paymentStatus,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true
    },
  ];
  // console.log('columnDefs====>>>>',columnDefs)

  // const handleAppoved = (id, status) => {
  //   console.log("id=======>>", id, "status", status);
  //   const result = data.find((el) => el._id === id);
  //   const payload = {
  //     userId: result?.userId?._id,
  //     transactionId: result?._id,
  //     approvedStatus: status,
  //     amount: result?.amount,
  //   };
  //   console.log("result", result);
  //   setPayload(payload);
  //   setActiveId(id);
  //   setOpen(true);
  // };

  // const handleEdit = (id) => {
  //   setActiveId(id)
  //   history.push(`/products/add-raritylevel?id=${id}`)
  //   console.log(id, 'edit')
  // }
  // const handleDelete = (id) => {
  //   setActiveId(id)
  //   setOpen(true)
  //   console.log(id, 'delete')
  // }
  const handleSelectFunds = (row) => {
    let selectedPro = [];
    if (!row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
    if (row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
  };
  const handleSelectedDelete = async (e, id) => {
    e.preventDefault();
    const res = await dispatch(deleteSelectedWithdrawRequest(id));
    const { resultData } = res?.payload;
    if (resultData?.code === 200) {
      dispatch(getWithdrawalRequests());
      setSelectedRows(false);
      setOpenDelPopup(false);
    }
  };
  const onCloseModal = () => {
    setOpenDelPopup(false);
  };
  const selectDeleteOpen = (e) => {
    e.preventDefault();
    if (selectedBox.length > 0) {
      setOpenDelPopup(true);
    }
  };
  return (
    <div>
      <DeleteConfirmForSelected
        open={openDelPopup}
        handleSelectedDelete={handleSelectedDelete}
        onCloseModal={onCloseModal}
        id={selectedBox}
      />
      {role?.some((r) => ["suUsers", "superAdmin", "delFunds"].includes(r)) && (
        <div className="btn-popup pull-right">
          <span className="btn btn-secondary" onClick={selectDeleteOpen}>
            Delete
          </span>
        </div>
      )}

      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
          // searchData={searchData}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            selectableRows
            clearSelectedRows={selectedRows}
            onSelectedRowsChange={(e) => handleSelectFunds(e)}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};

// const ProfileImage = ({ row }) => {
//   return (
//     <div className='profile-image'>
//       <img
//         src={row.image ? row.image : placeholder}
//         alt='logo'
//         style={{ height: '20px', width: '20px' }}
//       />
//     </div>
//   )
// }

// const ActionButton = ({
//   row,
//   handleDelete,
//   handleEdit,
//   handleAppoved,
//   role,
// }) => {
//   const writeRole = role?.some((r) =>
//     ["suUsers", "superAdmin", "writeFunds"].includes(r)
//   );
//   return (
//     <div className="action-btn">
//       <span>
//         <i
//           onClick={() =>
//             row.status === "pending"
//               ? writeRole
//                 ? handleAppoved(row._id, "declined")
//                 : toast.error(
//                     `You do not have permission to manage user funds`,
//                     {
//                       toastId: "permission",
//                     }
//                   )
//               : ""
//           }
//           className="fa fa-times"
//           style={{
//             width: 35,
//             fontSize: 20,
//             padding: 11,
//             color: "#e4566e",
//           }}
//         ></i>
//       </span>
//       <span>
//         <i
//           onClick={() =>
//             row.status === "pending"
//               ? writeRole
//                 ? handleAppoved(row._id, "success")
//                 : toast.error(
//                     `You do not have permission to manage user funds`,
//                     {
//                       toastId: "permission",
//                     }
//                   )
//               : ""
//           }
//           className="fa fa-check"
//           style={{
//             width: 35,
//             fontSize: 20,
//             padding: 11,
//             color: "rgb(40, 167, 69)",
//           }}
//         ></i>
//       </span>
//     </div>
//   );
// };

export default ListDatatable;
