import * as Yup from 'yup'
import { adminInstance } from '../config/axios'
import { queryString } from '../utils/queryString'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//const passwordRegExp = /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/ ;
const emailRegExp = /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/


const nameValidationYup = () => {
  let result = Yup.string()
    .required('This field is required')
    .min(3, 'character length must be greater than 2')
    .max(15, 'character length must be less than 16')

  return result
}

const catNameValidationYup = () => {
  let result = Yup.string().required('This field is required')
  return result
}
const emailValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .matches(emailRegExp, `Email is not valid`).checkEmailExits('Email already exists.')
  return result
}

Yup.addMethod(Yup.string, "checkUsernameExits", function (errorMessage) {
  return this.test(`test-user-`, errorMessage, async function (value) {
    const { path, createError } = this;
    const payload = {
      type: 'username',
      value: value,
    }
    const res = await adminInstance().post('/api/admin/checkUserExists', payload);
    const { code } = res.data;
    return (
      code === 200 ||
      createError({ path, message: errorMessage })
    );
  });
});
Yup.addMethod(Yup.string, "checkPhoneExits", function (errorMessage) {
  return this.test(`test-user-`, errorMessage, async function (value) {
    const { path, createError } = this;
    const payload = {
      type: 'phone',
      value: value,
    }
    const res = await adminInstance().post('/api/admin/checkUserExists', payload);
    const { code } = res.data;
    return (
      code === 200 ||
      createError({ path, message: errorMessage })
    );
  });
});
Yup.addMethod(Yup.string, "checkEmailExits", function (errorMessage) {
  return this.test(`test-user-`, errorMessage, async function (value) {
    const { path, createError } = this;
    const payload = {
      type: 'email',
      value: value,
    }
    const res = await adminInstance().post('/api/admin/checkUserExists', payload);
    const { code } = res.data;
    return (
      code === 200 ||
      createError({ path, message: errorMessage })
    );
  });
});
const userNameValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .min(6, 'Username is 6-20 characters long')
    .max(20, 'Username is too long').checkUsernameExits('Username already exists.');
  return result;
}

Yup.addMethod(Yup.string, "checkProductCodeExits", function (errorMessage) {
  return this.test(`test-user-`, errorMessage, async function (value) {
    const { path, createError } = this;
    const { id } = queryString()
    const payload = {
      type: 'productCode',
      value: value,
      id
    }
    const res = await adminInstance().post('/api/admin/checkProductCodeExists', payload);
    const { code } = res.data;
    return (
      code === 200 ||
      createError({ path, message: res.data.msg })
    );
  });
});


// const ProductCodeValidation = () =>{
//   let result = Yup.string()
//   .required('This field is required').checkProductCodeExits('ProductCode already exists.');
//   return result;
// }
// const ProductEditCodeValidation = (id) =>{
//   let result = Yup.string()
//   .required('This field is required').checkProductCodeExits('ProductCode already exists.');
//   return result;
// }
export const changeasswordValidation = () => {
  const schema = Yup.object().shape({
    old_password: Yup.string().required('This field is required'),
    password: Yup.string().required('This field is required').min(8, 'password length must be greater than 8'),
    confirm_password: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password not matche'),
  })
  return schema
}

const numberValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(8, 'Phone Number contains 8 digits only')
    .max(8, 'Phone Number contains 8 digits only').checkPhoneExits('Phone number already exists.');
  return result
}


// const fieldValidation = () => {
//   let result = Yup.string().required('This field is required')
//   return result
// }
const arrayValidation = () => {
  let result = Yup.array().min(1, 'One Should be selected')
  return result
}

export const yupLoginValidation = () => {
  const schema = Yup.object().shape({
    email: Yup.string()

      .required('This field is required')
      .matches(
        /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/,
        'Email is not valid'
      ),
    password: Yup.string().required('This field is required'),
  })
  return schema
}

export const yupPartnerValidation = () => {
  const schema = Yup.object().shape({
    firstname: nameValidationYup(),
    // email: emailValidation(),
    category: arrayValidation(),
    // address: fieldValidation(),
    phoneNumber: Yup.string().notRequired().test(phoneRegExp, 'Phone Number contain 8 digits only ', function (value) {
      if (!!value) {
        const schema = Yup.string().min(8).max(8);
        return schema.isValidSync(value);
      }
      return true;
    }),
  })
  return schema
}

export const yupCreateUserValidation = (pr) => {
  const schema = Yup.object().shape({
    firstname: nameValidationYup(),
    lastname: nameValidationYup(),
    email: pr ? Yup.string()
      .required('This field is required')
      .matches(emailRegExp, `Email is not valid`) : emailValidation(),
    mobile: pr ? Yup.string()
      .required('This field is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(8, 'Phone Number contains 8 digits only')
      .max(8, 'Phone Number contains 8 digits only') : numberValidation(),
    username: pr ? '' : userNameValidation(),
    // password: Yup.string().required('This field is required').matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // ),
    // confirm_password: Yup.string().required('This field is required')
    //       .oneOf([Yup.ref('password'), null], 'confirm_password')
  })
  return schema
}

export const yupCategoryValidation = () => {
  const schema = Yup.object().shape({
    name: catNameValidationYup(),
  })
  return schema
}

export const yupAnnouncementValidation = () => {
  const schema = Yup.object().shape({
    title: catNameValidationYup(),
    // description: catNameValidationYup(),
  })
  return schema
}

// const checkProductCodeLength = requiredText => ({
//   is: requiredText.length,
//   then: Yup.string().checkProductCodeExits()
// });

export const yupProductValidation = () => {
  // console.log('prr', Yup)
  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    price: Yup.string().required('This field is required'),
    //   productCode:Yup.string().when('productCode',checkProductCodeLength()
    // )
  })
  return schema;
}

export const yupBoxValidation = () => {
  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    // category: Yup.string().required('This field is required'),
  })
  return schema
}

export const QuickValidationYup = () => {
  const schema = Yup.object().shape({
    quickSellPercentage: Yup.string().required('This field is required'),
  })
  return schema
}

export const boxProfitValidationYup = () => {
  const schema = Yup.object().shape({
    boxProfit: Yup.string().required('This field is required'),
  })
  return schema
}
export const RaritykValidationYup = () => {
  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  })
  return schema
}
export const productSelectBoxValidtion = (payload, ValidateFields) => {
  console.log('kkdjsdhsjgd', payload, ValidateFields)
  let custErrors = {};
  const copy = { ...payload }
  let isValid = true;
  Object.keys(copy).forEach(el => {
    if (ValidateFields.includes(el)) {
      console.log('elll', el)
      if (el !== 'partnerId' && (!copy[el] || (Array.isArray(copy[el]) && copy[el].length === 0))) {
        custErrors[el] = 'This field is required';
        isValid = false;
      }
    }
  });
  console.log('custErrors', custErrors)
  return { isValid: isValid, custErrors: custErrors };
};

export const productCodeValidation = async (value) => {
  console.log('value=====>>>', value)
  const { id } = queryString()
  const yid = id ? id : ''
  console.log('uiuiu', yid)
  let custErrors = {};
  let isValid = true;
  const payload = {
    type: 'productCode',
    value: value,
    id: yid
  }
  const res = await adminInstance().post('/api/admin/checkProductCodeExists', payload);
  console.log('res===>>', res)
  const { code } = res.data;

  if (code !== 200) {
    isValid = false;
    custErrors.productCode = 'Product Code Already Exist'
  }
  console.log('custErrors', custErrors)
  return { isValid: isValid, custErrors: custErrors };
}


export const validatePassWord = (values) => {
  console.log('vvava=>', values)
  let errors = {};
  let isValid = true;
  if (!values.password) {
    errors.password = 'This field is required';
    isValid = false;
  } else if (values.password.length < 5) {
    errors.password = 'Password should be greater than 5 digist';
    isValid = false;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'This field is required';
    isValid = false;
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Confirm password not matched';
    isValid = false;
  }
  console.log('errors=>', errors)
  return { isValid: isValid, errors: errors };
};