import React from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
    FormGroup,
    Label,
} from 'reactstrap';
// import deleteimg from '../../assets/images/delete-icon.png';
import '../../assets/styles/deletePopup.css'

const AddShippingType = ({ onCloseModal, open, handleUpdate,deliveryMethod,setDeliveryMethod,title }) => {
	const handleOnchange=(e)=>{
       const {name,value}=e.target
	   setDeliveryMethod({...deliveryMethod,[name]:value})
	}
	return (
		<Modal
			className="delete-modal-dialog delete_modal_dialog"
			isOpen={open}
			toggle={()=>onCloseModal('close')}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={()=>onCloseModal('close')}
			></ModalHeader>
			<ModalBody>
				
            <FormGroup className='form__group'>
                    <Label className="col-form-label add_title pt-0">
                      {title}
                    </Label>
					
					<input
					className='form-control'
					type="text"
					name='shippingName'
					defaultValue={deliveryMethod?.shippingName || ''}
					onChange={handleOnchange}
				  />
                    
                    
                  </FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={handleUpdate}
				>
				Submit
				</Button>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={onCloseModal}
				>
				Cancel
				</Button>
				
			</ModalFooter>
		</Modal>
	);
};
export default AddShippingType;
