import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TabsetUser from "./tabset-user";
import { adminInstance } from "../../config/axios";
import { useLocation } from "react-router-dom";

const Create_user = () => {
  const initialObj = {
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
  };

  const [payload, setPayload] = useState(initialObj);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let userId = query.get("id");
  // console.log('userId------', userId);

  const getSingleUsersData = async (id) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getUserDetailById/${id}`
      );
      const { code, userData } = response.data;
      if (code === 200) {
        setPayload(userData);
      }
    } catch (error) {
      console.log("----------->aaa>>", error);
    }
  };

  const blank = {};

  useEffect(() => {
    getSingleUsersData(userId);
    // setSelectData();
  }, [userId]);

  return (
    <Fragment>
      <Breadcrumb title="User" parent="Users" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5> Add User</h5>
              </CardHeader>
              <CardBody>
                {userId ? (
                  payload?._id && <TabsetUser payload={payload} />
                ) : (
                  <TabsetUser payload={blank} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create_user;
