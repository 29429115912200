import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import HandleConfirmAddTeam from '../common/addTeamConfirmation';
import {
	updateTeamData,
	getTeamDetailById,
	resetTeamData,
} from '../../reducers/adminReducers';
import Breadcrumb from '../common/breadcrumb';
//import { adminInstance } from '../../config/axios';
import { queryString } from '../../utils/queryString';
import { updateTeamValidationSchema } from '../../utils/validation';
const UpdateTeam = () => {
	const initialState = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { teamDataById } = result || {};
	const [values, setValues] = useState(initialState);
	const [read, setRead] = useState(false);
	const [write, setWrite] = useState(false);
	const [del, setDel] = useState(false);
	const [superAdmin, setSuperAdmin] = useState(false);
	const [superAdminConfirm, setSuperAdminConfirm] = useState(false);
	const [role, setRole] = useState('');
	const [roleManagment, setRoleManagment] = useState(true);
	const [errors, setErrors] = useState('');
	const [existEmail, setExistEmail] = useState('');
	const { id: userId } = queryString();

	useEffect(() => {
		if (!userId) {
			dispatch(resetTeamData());
		}
	}, [dispatch, userId]);
	useEffect(() => {
		if (teamDataById) {
			setValues({
				...values,
				firstName: teamDataById?.firstname,
				lastName: teamDataById?.lastname,
				email: teamDataById?.email?.toLowerCase(),
			});
			setRole(teamDataById.role);
			setExistEmail(teamDataById.email);
			if (teamDataById.role?.includes('suUsers')) {
				setSuperAdmin(true);
				setRead(false);
				setWrite(false);
				setDel(false);
			} else {
				setSuperAdmin(false);
				setRole(teamDataById.role);
				setRead(true);
				setWrite(true);
				setDel(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamDataById]);
	useEffect(() => {
		dispatch(getTeamDetailById({ userId }));
	}, [dispatch, userId]);
	const onChangeInput = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};
	let checkEmail = /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/;
	let checkName = /^[A-Z a-z]{3,}$/;

	const handleOnblur = (e) => {
		const { name, value } = e.target;
		if (value === '') {
			setErrors({ ...errors, [name]: `This field is required` });
		} else if (name === 'firstName' && !checkName.test(value)) {
			setErrors({ ...errors, [name]: `Enter only character` });
		} else if (name === 'lastName' && !checkName.test(value)) {
			setErrors({ ...errors, [name]: `Enter only character` });
		} else if (name === 'email' && !checkEmail.test(value)) {
			setErrors({ ...errors, [name]: `Enter valid email` });
		} else {
			setErrors({ ...errors, [name]: '' });
		}
	};
	const handleKeyUp = (e) => {
		const { name, value } = e.target;
		if (value !== '') {
			setErrors({ ...errors, [name]: '' });
		}
	};
	let warehouseReadArr = [
		'readCategory',
		'readProduct',
		'readRarityLevel',
		'readBrand',
		'readPartner',
	];
	let warehouseWriteArr = [
		'writeCategory',
		'writeProduct',
		'writeAddProduct',
		'writeRarityLevel',
		'writeBrand',
		'writePartner',
	];
	let warehouseDelArr = [
		'delCategory',
		'delProduct',
		'delRarityLevel',
		'delBrand',
		'delPartner',
	];
	let profitReadArr = [
		'readWithdraws',
		'readOrders',
		'readDeposits',

	];
	let profitWriteArr = [
		'writeWithdraws',
		'writeOrders',
		'writeDeposits',

	];
	let profitDelArr = [
		'delWithdraws',
		'delOrders',
		'delDeposits',

	];

	let gameManagementReadArr = [
		'readBox',
		'readBotSimulator',
		'readBattle',
	];
	let gameManagementWriteArr = [
		'writeBox',
		'writeBotSimulator',
		'writeBattle',
	];
	let gameManagementDelArr = [
		'delBox',
		'delBotSimulator',
		'delBattle',
	];


	let teamManagementReadArr = [
		// 'readTeamMembers',
		'readActivityLog',
	];
	// let teamManagementWriteArr = [
	// 	// 'readTeamMembers',
	// 	'readActivityLog',
	// ];
	let teamManagementDelArr = [
		// 'readTeamMembers',
		'delActivityLog',
	];


	let settingReadArr = [
		// 'readOtherSettings',
		// 'readQuickSellPercentage',
		// 'readChangePassword',
		'readProfile',
		'readPreferences',
		// 'readPaymentOption'
		// 'readHideRolChance',
	];
	let settingWriteArr = [
		// 'writeOtherSettings',
		// 'writeQuickSellPercentage',
		'writeChangePassword',
		'writeProfile',
		// 'writeHideRolChance',
		'writePreferences',
		// 'writePaymentOption'

	];
	const handleCheckBox = (e) => {
		let roleArr = [...role];
		if (superAdmin) {
			roleArr.shift();
		}
		setSuperAdmin(false);
		if (e.target.checked) {
			roleArr.push(e.target.name);
			if (e.target.name === 'readWarehouse') {
				let existWareRole = roleArr?.some((r) => warehouseReadArr.includes(r));
				if (!existWareRole) {
					let wareRole = [...roleArr, ...warehouseReadArr];
					roleArr = wareRole;
				}
			}
			if (e.target.name === 'writeWarehouse') {
				let existWriteWareRole = roleArr?.some((r) =>
					warehouseWriteArr.includes(r)
				);
				if (!existWriteWareRole) {
					let wareWriteRole = [...roleArr, ...warehouseWriteArr];
					roleArr = wareWriteRole;
				}
			}
			if (e.target.name === 'delWarehouse') {
				let existDelWareRole = roleArr?.some((r) =>
					warehouseDelArr.includes(r)
				);
				if (!existDelWareRole) {
					let wareDelRole = [...roleArr, ...warehouseDelArr];
					roleArr = wareDelRole;
				}
			}

			//profit push
			if (e.target.name === 'readProfitManagement') {
				let existWareRole = roleArr?.some((r) => profitReadArr.includes(r));
				if (!existWareRole) {
					let wareRole = [...roleArr, ...profitReadArr];
					roleArr = wareRole;
				}
			}
			if (e.target.name === 'writeProfitManagement') {
				let existWriteWareRole = roleArr?.some((r) =>
					profitWriteArr.includes(r)
				);
				if (!existWriteWareRole) {
					let wareWriteRole = [...roleArr, ...profitWriteArr];
					roleArr = wareWriteRole;
				}
			}
			if (e.target.name === 'delProfitManagement') {
				let existDelWareRole = roleArr?.some((r) =>
					profitDelArr.includes(r)
				);
				if (!existDelWareRole) {
					let wareDelRole = [...roleArr, ...profitDelArr];
					roleArr = wareDelRole;
				}
			}


			if (e.target.name === 'readGameManagement') {
				let existWareRole = roleArr?.some((r) => gameManagementReadArr.includes(r));
				if (!existWareRole) {
					let wareRole = [...roleArr, ...gameManagementReadArr];
					roleArr = wareRole;
				}
			}
			if (e.target.name === 'writeGameManagement') {
				let existWriteWareRole = roleArr?.some((r) =>
					gameManagementWriteArr.includes(r)
				);
				if (!existWriteWareRole) {
					let wareWriteRole = [...roleArr, ...gameManagementWriteArr];
					roleArr = wareWriteRole;
				}
			}
			if (e.target.name === 'delGameManagement') {
				let existDelWareRole = roleArr?.some((r) =>
					gameManagementDelArr.includes(r)
				);
				if (!existDelWareRole) {
					let wareDelRole = [...roleArr, ...gameManagementDelArr];
					roleArr = wareDelRole;
				}
			}

			if (e.target.name === 'readTeamManagement') {
				let existWareRole = roleArr?.some((r) => teamManagementReadArr.includes(r));
				if (!existWareRole) {
					let wareRole = [...roleArr, ...teamManagementReadArr];
					roleArr = wareRole;
				}
			}
			// if (e.target.name === 'writeTeamManagement') {
			// 	let existWriteWareRole = roleArr?.some((r) =>
			// 	teamManagementWriteArr.includes(r)
			// 	);
			// 	if (!existWriteWareRole) {
			// 		let wareWriteRole = [...roleArr, ...teamManagementWriteArr];
			// 		roleArr = wareWriteRole;
			// 	}
			// }
			if (e.target.name === 'delTeamManagement') {
				let existDelWareRole = roleArr?.some((r) =>
					teamManagementDelArr.includes(r)
				);
				if (!existDelWareRole) {
					let wareDelRole = [...roleArr, ...teamManagementDelArr];
					roleArr = wareDelRole;
				}
			}

			if (e.target.name === 'readSetting') {
				let existReadSetting = roleArr?.some((r) => settingReadArr.includes(r));
				if (!existReadSetting) {
					let wareReadSetRole = [...roleArr, ...settingReadArr];
					roleArr = wareReadSetRole;
				}
			}
			if (e.target.name === 'writeSetting') {
				let existReadSetting = roleArr?.some((r) =>
					settingWriteArr.includes(r)
				);
				if (!existReadSetting) {
					let wareWriteSetRole = [...roleArr, ...settingWriteArr];
					roleArr = wareWriteSetRole;
				}
			}
		} else if (roleArr.includes(e.target.name)) {
			if (e.target.name === 'readWarehouse') {
				roleArr = roleArr.filter((el) => {
					const res = !warehouseReadArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'writeWarehouse') {
				roleArr = roleArr.filter((el) => {
					const res = !warehouseWriteArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'delWarehouse') {
				roleArr = roleArr.filter((el) => {
					const res = !warehouseDelArr.includes(el);
					return res;
				});
			}

			//Profit Management
			if (e.target.name === 'readProfitManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !profitReadArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'writeProfitManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !profitWriteArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'delProfitManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !profitDelArr.includes(el);
					return res;
				});
			}

			if (e.target.name === 'readGameManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !gameManagementReadArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'writeGameManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !gameManagementWriteArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'delGameManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !gameManagementDelArr.includes(el);
					return res;
				});
			}


			if (e.target.name === 'readTeamManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !teamManagementReadArr.includes(el);
					return res;
				});
			}
			// if (e.target.name === 'writeTeamManagement') {
			// 	roleArr = roleArr.filter((el) => {
			// 		const res = !teamManagementWriteArr.includes(el);
			// 		return res;
			// 	});
			// }
			if (e.target.name === 'delTeamManagement') {
				roleArr = roleArr.filter((el) => {
					const res = !teamManagementDelArr.includes(el);
					return res;
				});
			}


			if (e.target.name === 'readSetting') {
				roleArr = roleArr.filter((el) => {
					const res = !settingReadArr.includes(el);
					return res;
				});
			}
			if (e.target.name === 'writeSetting') {
				roleArr = roleArr.filter((el) => {
					const res = !settingWriteArr.includes(el);
					return res;
				});
			}
			roleArr = roleArr.filter((el) => {
				const res = el !== e.target.name;
				return res;
			});
		}
		setRole(roleArr);
	};

	// const handleCheckBox = (e) => {
	// 	let roleArr = [...role];
	// 	if (superAdmin) {
	// 		roleArr.shift();
	// 	}
	// 	setSuperAdmin(false);
	// 	if (e.target.checked) {
	// 		roleArr.push(e.target.name);
	// 	} else if (roleArr.includes(e.target.name)) {
	// 		roleArr = roleArr.filter((el) => el !== e.target.name);
	// 	}
	// 	setRole(roleArr);
	// };

	const handleSuAdCheckBox = (e) => {
		if (!superAdmin) {
			let roleArr = [];
			if (e.target.checked) {
				roleArr.push(e.target.name);
			} else if (roleArr.includes(e.target.name)) {
				roleArr = roleArr.filter((el) => el !== e.target.name);
			}
			setRole(roleArr);
			setRead(false);
			setWrite(false);
			setDel(false);
			setRoleManagment(false);
		} else {
			setRole([]);
			setSuperAdmin(!superAdmin);
			if (!teamDataById.role.includes('suUsers')) {
				setRole(teamDataById.role);
				setRoleManagment(true);
				setRead(true);
				setWrite(true);
				setDel(true);
			}
		}
		setSuperAdmin(!superAdmin);
		setSuperAdminConfirm(false);
	};
	const handleSuperAdminCheckBox = (e) => {
		if (superAdmin) {
			setSuperAdmin(false);
			setSuperAdminConfirm(false);
		}
		if (!superAdmin) {
			setRoleManagment(true);
			setSuperAdminConfirm(!superAdminConfirm);
		}
	};
	const closeConfirmation = () => {
		setSuperAdminConfirm(!superAdminConfirm);
	};
	const handleSubmit = async (e) => {
		try {
			e.preventDefault();

			const validate = await updateTeamValidationSchema.validate(values, {
				abortEarly: false,
			});
			if (validate) {
				if (role?.length > 0) {
					const res = await dispatch(
						updateTeamData({
							values,
							role: role,
							userId: userId,
							existEmail: existEmail,
						})
					);
					const { code } = res?.payload;
					if (code === 200) {
						dispatch(resetTeamData());
						history.push('/team-management');
					}
				} else {
					toast.error('Please Select at least one role for team');
				}
			}
		} catch (error) {
			console.log('Error Is here In add roll -->', error);
			let er = {};
			error.inner.forEach((el) => {
				er[el.path] = el.message;
			});
			setErrors({ ...er });
		}
	};
	//console.log('rols--->', role);
	return (
		<>
			<div className="tab-pane fade show active">
				{/* <h5 className="f-w-600 f-16">Profile</h5> */}
				<Breadcrumb title="Update Team" parent="Team Management" />
				<div className="table-responsive profile-table setting-section sub-admin-section">
					<Form onSubmit={handleSubmit} className="needs-validation user-add">
						<div className="admin-input">
							<FormGroup>
								<Label className="col-form-label pt-0">First Name</Label>
								<Input
									type="text"
									placeholder="Enter Name"
									name="firstName"
									value={values?.firstName}
									onChange={onChangeInput}
									onBlur={handleOnblur}
									onKeyUp={handleKeyUp}
								/>
								{errors && errors.firstName && (
									<span className="text-danger">{errors.firstName}</span>
								)}
							</FormGroup>
							<FormGroup>
								<Label className="col-form-label pt-0">Last Name</Label>
								<Input
									type="text"
									placeholder="Last Name"
									name="lastName"
									value={values?.lastName}
									onChange={onChangeInput}
									onBlur={handleOnblur}
									onKeyUp={handleKeyUp}
								/>
								{errors && errors.lastName && (
									<span className="text-danger">{errors.lastName}</span>
								)}
							</FormGroup>
							<FormGroup>
								<Label className="col-form-label pt-0">Email</Label>
								<Input
									type="text"
									placeholder="Enter email"
									name="email"
									value={values?.email}
									onChange={onChangeInput}
									onBlur={handleOnblur}
									onKeyUp={handleKeyUp}
								/>
								{errors && errors.email && (
									<span className="text-danger">{errors.email}</span>
								)}
							</FormGroup>
						</div>

						{roleManagment ? (
							<div className="admin-permission">
								<FormGroup check inline>
									<Input type="checkbox" onChange={() => setRead(!read)} />
									{/* <Label check className="col-form-label pt-0">
										Permission for read
									</Label> */}
									<h3>Permission for read</h3>
								</FormGroup>

								{read ? (
									<div className="transactionManage">
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readDashboard"
												onChange={handleCheckBox}
												checked={
													role?.includes('readDashboard') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Dashboard Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readWarehouse"
												onChange={handleCheckBox}
												checked={
													role?.includes('readWarehouse') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Warehouse Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readCategory"
												onChange={handleCheckBox}
												checked={
													role?.includes('readCategory') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Category Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readProduct"
												onChange={handleCheckBox}
												checked={role?.includes('readProduct') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Product Management
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readAddProduct"
												onChange={handleCheckBox}
												checked={
													role?.includes('readAddProduct') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Add Product Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readRarityLevel"
												onChange={handleCheckBox}
												checked={
													role?.includes('readRarityLevel') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Rarity Level Management
											</Label>
										</FormGroup>

										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readBrand"
												onChange={handleCheckBox}
												checked={role?.includes('readBrand') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Brand Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readPartner"
												onChange={handleCheckBox}
												//onClick={() => checkDuplicate('readUsers')}
												checked={role?.includes('readPartner') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Partner Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readGameManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('readGameManagement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Game Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readBox"
												onChange={handleCheckBox}
												checked={role?.includes('readBox') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Box Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readBotSimulator"
												onChange={handleCheckBox}
												checked={
													role?.includes('readBotSimulator') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Bot Simulator Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readBattle"
												onChange={handleCheckBox}
												checked={role?.includes('readBattle') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Battle Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readUsers"
												onChange={handleCheckBox}
												checked={role?.includes('readUsers') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Users Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readFunds"
												onChange={handleCheckBox}
												checked={role?.includes('readFunds') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Funds Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readProfitManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('readProfitManagement')
														? 'checked'
														: ''
												}
											/>

											<Label className="col-form-label pt-0" check>
												Profit Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readWithdraws"
												onChange={handleCheckBox}
												checked={
													role?.includes('readWithdraws')
														? 'checked'
														: ''
												}
											/>

											<Label className="col-form-label pt-0" check>
												Withdraws
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readOrders"
												onChange={handleCheckBox}
												checked={
													role?.includes('readOrders')
														? 'checked'
														: ''
												}
											/>

											<Label className="col-form-label pt-0" check>
												Orders Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readDeposits"
												onChange={handleCheckBox}
												checked={
													role?.includes('readDeposits')
														? 'checked'
														: ''
												}
											/>

											<Label className="col-form-label pt-0" check>
												Deposits
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
																<Input
																	type="checkbox"
																	name="readDelivery"
																	onChange={handleCheckBox}
																	checked={
																		role?.includes('readDelivery')
																			? 'checked'
																			: ''
																	}
																/>
																<Label className="col-form-label pt-0" check>
																	Delivery Management
																</Label>
															</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readAnnouncement"
												onChange={handleCheckBox}
												checked={
													role?.includes('readAnnouncement') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Announcement Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readSetting"
												onChange={handleCheckBox}
												checked={role?.includes('readSetting') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Setting Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readProfile"
												onChange={handleCheckBox}
												checked={role?.includes('readProfile') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Profile Management
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readChangePassword"
												onChange={handleCheckBox}
												checked={
													role?.includes('readChangePassword') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Password Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readQuickSellPercentage"
												onChange={handleCheckBox}
												checked={
													role?.includes('readQuickSellPercentage')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Quick Sell Percentage Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readOtherSettings"
												onChange={handleCheckBox}
												checked={
													role?.includes('readOtherSettings') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Other Setting Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="readHideRolChance"
												onChange={handleCheckBox}
												checked={
													role?.includes('readHideRolChance') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Hide Roll Chance Management
											</Label>
										</FormGroup> */}



										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readPreferences"
												onChange={handleCheckBox}
												checked={
													role?.includes('readPreferences')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Preferences
											</Label>
										</FormGroup>

										{/* <FormGroup check inline>
																<Input
																	type="checkbox"
																	name="readPaymentOption"
																	onChange={handleCheckBox}
																	checked={
																		role?.includes('readPaymentOption')
																			? 'checked'
																			: ''
																	}
																/>
																<Label className="col-form-label pt-0" check>
																	Payment Option
																</Label>
															</FormGroup> */}

										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readTeamManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('readTeamManagement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Team Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="readActivityLog"
												onChange={handleCheckBox}
												checked={
													role?.includes('readActivityLog') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Activity Logs Management
											</Label>
										</FormGroup>
									</div>
								) : (
									''
								)}
							</div>
						) : (
							''
						)}
						{roleManagment ? (
							<div className="admin-permission">
								<FormGroup check inline>
									<Input type="checkbox" onChange={() => setWrite(!write)} />
									{/* <Label className="col-form-label pt-0" check>
										Permission for write
									</Label> */}
									<h3>Permission for write</h3>
								</FormGroup>

								{write ? (
									<div className="transactionManage">
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="writeDashboard"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeDashboard') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Dashboard Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeWarehouse"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeWarehouse') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Warehouse Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeCategory"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeCategory') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Category Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeProduct"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeProduct') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Product Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeAddProduct"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeAddProduct') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Add Product Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeRarityLevel"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeRarityLevel') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Rarity Level Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeBrand"
												onChange={handleCheckBox}
												checked={role?.includes('writeBrand') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Brand Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writePartner"
												onChange={handleCheckBox}
												checked={
													role?.includes('writePartner') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Partner Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeGameManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeGameManagement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Game Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeBox"
												onChange={handleCheckBox}
												checked={role?.includes('writeBox') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Box Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeBotSimulator"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeBotSimulator') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Bot Simulator Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeBattle"
												onChange={handleCheckBox}
												checked={role?.includes('writeBattle') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Battle Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeUsers"
												onChange={handleCheckBox}
												checked={role?.includes('writeUsers') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Users Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeFunds"
												onChange={handleCheckBox}
												checked={role?.includes('writeFunds') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Funds Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeProfitMangement"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeProfitMangement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Profit Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeWithdraws"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeWithdraws')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Withdraws
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeOrders"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeOrders')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Orders Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeDeposits"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeDeposits')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Deposits
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
																<Input
																	type="checkbox"
																	name="writeDelivery"
																	onChange={handleCheckBox}
																	checked={
																		role?.includes('writeDelivery')
																			? 'checked'
																			: ''
																	}
																/>
																<Label className="col-form-label pt-0" check>
																	Delivery Management
																</Label>
															</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="writeAnnouncement"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeAnnouncement') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Announcement Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeSetting"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeSetting') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Setting Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeProfile"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeProfile') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Profile Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeChangePassword"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeChangePassword') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Password Management
											</Label>
										</FormGroup>


										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writePreferences"
												onChange={handleCheckBox}
												checked={
													role?.includes('writePreferences')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Preferences
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
																<Input
																	type="checkbox"
																	name="writePaymentOption"
																	onChange={handleCheckBox}
																	checked={
																		role?.includes('writePaymentOption')
																			? 'checked'
																			: ''
																	}
																/>
																<Label className="col-form-label pt-0" check>
																	Payment Option
																</Label>
															</FormGroup> */}

										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="writeQuickSellPercentage"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeQuickSellPercentage')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Quick Sell Percentage Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeOtherSettings"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeOtherSettings') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Other Setting Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="writeHideRolChance"
												onChange={handleCheckBox}
												checked={
													role?.includes('writeHideRolChance') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Hide Roll Chance Management
											</Label>
										</FormGroup> */}
									</div>
								) : (
									''
								)}
							</div>
						) : (
							''
						)}
						{roleManagment ? (
							<div className="admin-permission">
								<FormGroup check inline>
									<Input type="checkbox" onChange={() => setDel(!del)} />
									{/* <Label className="col-form-label pt-0" check>
										Permission for write
									</Label> */}
									<h3>Permission for delete</h3>
								</FormGroup>

								{del ? (
									<div className="transactionManage">
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delDashboard"
												onChange={handleCheckBox}
												checked={
													role?.includes('delDashboard') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Dashboard Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delWarehouse"
												onChange={handleCheckBox}
												checked={
													role?.includes('delWarehouse') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Warehouse Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delCategory"
												onChange={handleCheckBox}
												checked={role?.includes('delCategory') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Category Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delProduct"
												onChange={handleCheckBox}
												checked={role?.includes('delProduct') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Product Management
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delAddProduct"
												onChange={handleCheckBox}
												checked={
													role?.includes('delAddProduct') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Add Product Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delRarityLevel"
												onChange={handleCheckBox}
												checked={
													role?.includes('delRarityLevel') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Rarity Level Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delBrand"
												onChange={handleCheckBox}
												checked={role?.includes('delBrand') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Brand Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delPartner"
												onChange={handleCheckBox}
												checked={role?.includes('delPartner') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Partner Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delGameManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('delGameManagement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Game Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delBox"
												onChange={handleCheckBox}
												checked={role?.includes('delBox') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Box Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delBotSimulator"
												onChange={handleCheckBox}
												checked={
													role?.includes('delBotSimulator') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Bot Simulator Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delBattle"
												onChange={handleCheckBox}
												checked={role?.includes('delBattle') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Battle Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delUsers"
												onChange={handleCheckBox}
												checked={role?.includes('delUsers') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Users Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delFunds"
												onChange={handleCheckBox}
												checked={role?.includes('delFunds') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Funds Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delProfitManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('delProfitManagement') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Profit Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delWithdraws"
												onChange={handleCheckBox}
												checked={
													role?.includes('delWithdraws') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Withdraws
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delOrders"
												onChange={handleCheckBox}
												checked={
													role?.includes('delOrders') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Orders Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delDeposits"
												onChange={handleCheckBox}
												checked={
													role?.includes('delDeposits') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Deposits
											</Label>
										</FormGroup>
										{/* <FormGroup check inline>
																<Input
																	type="checkbox"
																	name="delDelivery"
																	onChange={handleCheckBox}
																	checked={
																		role?.includes('delDelivery')
																			? 'checked'
																			: ''
																	}
																/>
																<Label className="col-form-label pt-0" check>
																	Delivery Management
																</Label>
															</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delAnnouncement"
												onChange={handleCheckBox}
												checked={
													role?.includes('delAnnouncement') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Announcement Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delSetting"
												onChange={handleCheckBox}
												checked={role?.includes('delSetting') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Setting Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delProfile"
												onChange={handleCheckBox}
												checked={role?.includes('delProfile') ? 'checked' : ''}
											/>
											<Label className="col-form-label pt-0" check>
												Profile Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delChangePassword"
												onChange={handleCheckBox}
												checked={
													role?.includes('delChangePassword') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Password Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delQuickSellPer"
												onChange={handleCheckBox}
												checked={
													role?.includes('delQuickSellPer') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Quick Sell Percentage Management
											</Label>
										</FormGroup> */}
										{/* <FormGroup check inline>
											<Input
												type="checkbox"
												name="delOtherSetting"
												onChange={handleCheckBox}
												checked={
													role?.includes('delOtherSetting') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Other Setting Management
											</Label>
										</FormGroup> */}
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delPreferences"
												onChange={handleCheckBox}
												checked={
													role?.includes('delPreferences')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Preferences
											</Label>
										</FormGroup>

										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delTeamManagement"
												onChange={handleCheckBox}
												checked={
													role?.includes('delTeamManagement')
														? 'checked'
														: ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Team Management
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Input
												type="checkbox"
												name="delActivityLog"
												onChange={handleCheckBox}
												checked={
													role?.includes('delActivityLog') ? 'checked' : ''
												}
											/>
											<Label className="col-form-label pt-0" check>
												Activity Logs Management
											</Label>
										</FormGroup>
									</div>
								) : (
									''
								)}
							</div>
						) : (
							''
						)}
						<div className="admin-permission">
							<FormGroup check inline>
								<Input
									type="checkbox"
									name="suUsers"
									checked={superAdmin ? 'checked' : ''}
									onClick={handleSuperAdminCheckBox}
									onChange={(e) => (superAdmin ? handleSuAdCheckBox(e) : '')}
								/>
								<h3>Permission for super admin</h3>
								{/* <Label className="col-form-label pt-0" check>
									Permission for super admin
								</Label> */}
							</FormGroup>
							<HandleConfirmAddTeam
								open={superAdminConfirm}
								onCloseModal={closeConfirmation}
								handleConfirm={handleSuAdCheckBox}
							/>
						</div>

						<div className="form-button">
							<Button
								color="primary"
								style={{ borderRadius: '25px' }}
								type="submit"
							>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};
export default UpdateTeam;
