import React from 'react'
import {  FormGroup, Label, } from 'reactstrap';

const FilterProductFields = ({rarityList,categoryList,handleSearchProduct}) => {
  return (
    <FormGroup className='search_boxes_section search-box-section justify-content-center'>
                  <div className='row'>
                    <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Search Here
                      </Label>
                      <input
                        type='text'
                        placeholder='Search'
                        name='title'
                        onChange={(e) => handleSearchProduct(e)}
                        class='form-control'></input>
                      <i class='fa fa-search search-icon'></i>
                    </div>
                    <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Min
                      </Label>
                      <i class='fa fa-dollar dollar-icon'></i>
                      <input
                        name='min'
                        onChange={(e) => handleSearchProduct(e)}
                        className='form-control'
                        type='number'
                        placeholder='Min'
                      />
                    </div>

                    <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Max
                      </Label>
                      <i class='fa fa-dollar dollar-icon'></i>
                      <input
                        name='max'
                        onChange={(e) => handleSearchProduct(e)}
                        className='form-control'
                        type='number'
                        placeholder='Max'
                      />
                    </div>

                    
                    <div className='addbox-input col-xl-12 col-md-12'>
                      <div className='search-section'>
                        <div className='search-category'>
                          <select
                            class='category-search'
                            name='category'
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=''> Choose Category</option>
                            {categoryList &&
                              categoryList.length > 0 &&
                              categoryList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                          <select
                            class='category-search'
                            name='rarity'
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=''> Choose Rarity</option>
                            {rarityList &&
                              rarityList.length > 0 &&
                              rarityList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>
                </FormGroup>
  )
}

export default FilterProductFields