import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import Slider from "react-slick";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";

import Rating from "react-simple-star-rating";

// image import
// import two from "../../assets/images/pro3/2.jpg";
// import twentySeven from "../../assets/images/pro3/27.jpg";
// import one from "../../assets/images/pro3/1.jpg";
import size_chart from "../../assets/images/size-chart.jpg";
// import ProductForm from './createProductForm'
import {
	// Button,
	Card,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	// CardBody
} from "reactstrap";
import { queryString } from '../../utils/queryString'
import { adminInstance } from '../../config/axios'

const Product_detail = () => {
	const { id } = queryString()
	const [open, setOpen] = useState(false);
	const [rating, setRating] = useState(1);
	const [nav, setNav] = useState({
		nav1: null,
		nav2: null,
	});
	const [data, setData] =  useState({});
	const getSingleProductData = async (id) => {
		try {
		  const response = await adminInstance().get(
			`api/admin/getProductById/${id}`
		  )
		  const { code, getProduct } = response.data
		  console.log('data----', code, getProduct)
		  if (code === 200) {
			setData(getProduct);
		  }
		} catch (error) {
		  console.log('----------->aaa>>', error)
		}
	  }
	  useEffect(() => {
		getSingleProductData(id)
	  }, [id])
	// const onOpenModal = () => {
	// 	setOpen(true);
	// };

	const onCloseModal = () => {
		setOpen(false);
	};
	const onStarClick = (nextValue) => {
		setRating(nextValue);
	};
	useEffect(() => {
		setNav({
			nav1: Slider.slider1,
			nav2: Slider.slider2,
		});
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Product Detail" parent="Physical" />

			<Container fluid={true}>
				<Card>
					<Row className="product-page-main card-body">
						<Col xl="4">
							<Slider
								asNavFor={nav.nav2}
								ref={(slider) => (Slider.slider1 = slider)}
								className="product-slider"
							>
								{data.image && data.image.length > 0 && data.image.map((el) => {
								return(
								<div className="item">
									<img className="img-fluid" src={el} alt={el} />
								</div>
								)
								})}
								{/* <div className="item">
									<img className="img-fluid" src={one} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={twentySeven} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={two} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={one} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={twentySeven} alt="" />
								</div> */}
							</Slider>

							<Slider
								asNavFor={nav.nav1}
								ref={(slider) => (Slider.slider2 = slider)}
								slidesToShow={4}
								swipeToSlide={true}
								focusOnSelect={true}
								className="small-slick"
							>
								
								{data.image && data.image.length > 0 && data.image.map((el) => {
								return(
								<div className="item">
									<img className="img-fluid" src={el} alt={el} />
								</div>
								)
								})}

								{/* <div className="item">
									<img className="img-fluid" src={one} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={twentySeven} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={two} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={twentySeven} alt="" />
								</div>
								<div className="item">
									<img className="img-fluid" src={one} alt="" />
								</div> */}
							</Slider>
						</Col>
						<Col xl="8">
							<div className="product-page-details product-right mb-0">
								<h2>{data.name || ''}</h2>
								<div style={{ fontSize: 27, height: 31 }}>
									<Rating
										onClick={onStarClick}
										ratingValue={rating}
										size={25}
										stars={5}
										transition
										name="rate1"
										emptyColor="black"
									/>
								</div>
								<hr />
								<h6 className="product-title">product details</h6>
								<p>
								{data.description || '' } 
								</p>
								<div className="product-price digits mt-2">
									<h3>
									${data.price || ''}                              
									</h3>
								</div>
								<ul  className="color-variant">
								   {data.color && data.color.map((el) => {
                                    return(
                                   <li className='bg-light0'  style={{backgroundColor: el.toLowerCase()}}></li>
                                  )
                                })}
								  </ul>

								{/* <ul className="color-variant">
									<li className="bg-light0"></li>
									<li className="bg-light1"></li>
									<li className="bg-light2"></li>
								</ul> */}
								<hr />
								<h6 className="product-title size-text">
									select size
									<span className="pull-right">
										{/* <a
											href="##"
											data-toggle="modal"
											data-target="#sizemodal"
											onClick={onOpenModal}
										>
											size chart
										</a> */}
									</span>
								</h6>
								<Modal isOpen={open} toggle={onCloseModal}>
									<ModalHeader toggle={onCloseModal}>
										<img
											src={size_chart}
											alt=""
											className="img-fluid blur-up lazyloaded"
										/>
									</ModalHeader>
								</Modal>
								<div className="size-box">
								 <ul>

								 {data.size && data.size.map((el) => {
									 return(
										<li className='active'  href="##">{el}</li>
									 )
								 }
								       
								        
								 )}

								 </ul>

									{/* <ul>
									<li className="active">
											<a href="##">s</a>
										</li>
										<li>
											<a href="##">m</a>
										</li>
										<li>
											<a href="##">l</a>
										</li>
										<li>
											<a href="##">xl</a>
										</li>
									</ul> */}
								</div>
								<div className="add-product-form">
									<h6 className="product-title">quantity</h6>
									<fieldset className="qty-box mt-2 ml-0">
										<div className="input-group bootstrap-touchspin">
											
											<div className="input-group-prepend">
												<span className="input-group-text bootstrap-touchspin-prefix"></span>
											</div>
											<input
												className="touchspin form-control"
												type="text"
												value={data.totalProduct || ''}
												
											/>
											<div className="input-group-append">
												<span className="input-group-text bootstrap-touchspin-postfix"></span>
											</div>
											
										</div>
									</fieldset>
								</div>
								<hr />
								{/* <h6 className="product-title">Time Reminder</h6>
								<div className="timer">
               

									<p id="demo">
										<span>
											25 <span className="padding-l">:</span>{" "}
											<span className="timer-cal">Days</span>{" "}
										</span>
										<span>
											22 <span className="padding-l">:</span>{" "}
											<span className="timer-cal">Hrs</span>{" "}
										</span>
										<span>
											13 <span className="padding-l">:</span>{" "}
											<span className="timer-cal">Min</span>{" "}
										</span>
										<span>
											57 <span className="timer-cal">Sec</span>
										</span>
									</p>
								</div> */}
								{/* <div className="m-t-15">
									<Button color="primary" className="m-r-10" type="button">
										Add To Cart
									</Button>
									<Button color="secondary" type="button">
										Buy Now
									</Button>
								</div> */}
							</div>
						</Col>
					</Row>
				
				
				</Card>
			</Container>
		</Fragment>
	);
};

export default Product_detail;
