import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import deleteimg from '../../assets/images/delete-icon.png';
import '../../assets/styles/deletePopup.css';
const DeleteConfirmForSelected = ({
	title,
	onCloseModal,
	open,
	handleSelectedDelete,
	id,
}) => {
	return (
		<Modal
			className="delete-modal-dialog"
			isOpen={open}
			toggle={onCloseModal}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={onCloseModal}
			></ModalHeader>
			<ModalBody>
				<img className="blur-up lazyloaded" src={deleteimg} alt="delete" />
				<h3>
					Are you sure you want to remove ?
					<br />
					This can not be undone.
				</h3>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={(e) => handleSelectedDelete(e, id)}
				>
					Confirm
				</Button>
				<Button
					type="button"
					color="secondary"
					className="cancel-button"
					onClick={onCloseModal}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default DeleteConfirmForSelected;
