import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import '../../assets/styles/deletePopup.css'
const BoxProfitConfirmation = ({
  onCloseModal,
  open,
  handleConfirm,
  handleChange,
  selectedValue,
}) => {
  return (
    <Modal
      className='delete-modal-dialog'
      isOpen={open}
      toggle={onCloseModal}
      style={{ overlay: { opacity: 0.1 } }}>
      {/* <ModalHeader
				className="delete-modal-header"
				toggle={onCloseModal}
			></ModalHeader> */}
      <ModalBody className='check__modal'>
        {/* <img className="blur-up lazyloaded" src={deleteimg} alt="delete" /> */}
        <FormGroup className='check_box'>
          <Label check>
            Apply to current boxes
            <Input
              type='checkbox'
              className='chck'
              onChange={(e) => handleChange(e, 'current')}
              checked={selectedValue === 'current'}
            />
          </Label>
        </FormGroup>
        <FormGroup className='check_box'>
          <Label check>
            Only to new ones
            <Input
              type='checkbox'
              className='chck'
              onChange={(e) => handleChange(e, 'new')}
              checked={selectedValue === 'new'}
            />
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='primary'
          className='delete-button'
          style={{ background: '#ff4d53 !important' }}
          onClick={() => handleConfirm()}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default BoxProfitConfirmation
