import React, { Fragment, useState, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import VendorForm from './createVendorForm'
import { adminInstance } from '../../config/axios'
import { queryString } from '../../utils/queryString'
import { useSelector, useDispatch } from 'react-redux'
import { getAllCategoryList } from '../../reducers/adminReducers'
const Create_vendors = () => {
  const dispatch = useDispatch()
  const result = useSelector((state) => state.admin)
  const { selectedCategory } = result || {}
  const { id: userId } = queryString()
  const [selectedOption, setSelectedOption] = useState([])
  const [payload, setPayload] = useState({})
  const [dd, setdd] = useState(false)
  const blank = {}
  const getSingleUsersData = async (id) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getPartnerById/${id}`
      )
      const { code, partner } = response.data
      if (code === 200) {
        setPayload(partner)
        await getCategory(partner)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCategory = async (partner) => {
    if (selectedCategory) {

      let filterCat = selectedCategory.filter((item) =>
        partner.category.includes(item.value)
      )
      setSelectedOption(filterCat)
      setdd(true);
    }
  }

  useEffect(() => {
    dispatch(getAllCategoryList())
    if (userId) {
      getSingleUsersData(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  useEffect(() => {
    if (!userId) {
      setdd(true)
    }
  }, [userId])

  return (
    <Fragment>
      <Breadcrumb title='Partner' parent='Partner' />
      {dd && (
        <Container fluid={true}>
          <Row>
            <Col sm='12'>
              <Card>
                <CardHeader>
                  <h5> Add Partner</h5>
                </CardHeader>
                <CardBody>
                  {userId ? (
                    payload?._id && (
                      <VendorForm
                        payload={payload}
                        categories={selectedCategory}
                        selectedOption={selectedOption}
                      />
                    )
                  ) : (
                    <VendorForm
                      payload={blank}
                      categories={selectedCategory}
                      selectedOption={selectedOption}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}

export default Create_vendors
