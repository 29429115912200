import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
// import { adminInstance } from '../../config/axios'
import { useLocation } from 'react-router-dom';
import AddAnnouncementForm from './addAnnoucementForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnouncementDetailById } from '../../reducers/adminReducers';
const AddAnnouncement = () => {
	const initialObj = {
		title: '',
		image: '',
		description: '',
	};

	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { announcementData } = result || {};

	let query = useQuery();
	let announcementId = query.get('id');

	const [payload, setPayload] = useState(initialObj);
	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	useEffect(() => {
		dispatch(getAnnouncementDetailById(announcementId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		const copy = { ...announcementData };
		setPayload(copy);
	}, [announcementData]);

	//   console.log('payload',payload)

	return (
		<Fragment>
			<Breadcrumb title="Announcement" parent="Announcement" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5> Add Announcement </h5>
							</CardHeader>
							<CardBody>
								{announcementId ? (
									payload?._id && <AddAnnouncementForm payload={payload} />
								) : (
									<AddAnnouncementForm payload={initialObj} />
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AddAnnouncement;
