import React from "react";
import "./location.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
function AddPopup({
  showAdd,
  closeAdd,
  handleAddCountry,
  countryName,
  handleAllowChange,
  handleCountryNameChange,
  allow,
  countries,
}) {
  return (
    <>
      <Modal show={showAdd} onHide={closeAdd}>
        <div className='close-button'>
          <button onClick={closeAdd}>
            <svg
              fill='#000000'
              width='26px'
              height='26px'
              viewBox='-6 -6 24 24'
              xmlns='http://www.w3.org/2000/svg'
              preserveAspectRatio='xMinYMin'
              class='jam jam-close'>
              <path d='M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z' />
            </svg>
          </button>
        </div>
        <div className='card-body-content container'>
          <div className='input-fields'>
            <select
              value={countryName}
              onChange={handleCountryNameChange}
              name='country'
              id='country'
              defaultValue='Select Country'>
              <option value='' disabled hidden>
                Select a country
              </option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          <div className='action-radiobuttons mt-4'>
            <div className='form-check'>
              <input
                className='form-check-input'
                id='allow'
                type='radio'
                checked={allow}
                onChange={handleAllowChange}
              />
              <label htmlFor='allow'>Allow</label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                id='block'
                type='radio'
                checked={!allow}
                onChange={handleAllowChange}
              />
              <label htmlFor='block'>Block</label>
            </div>
          </div>
          <div className='add-button mb-4'>
            <Button className='add-btn' onClick={handleAddCountry}>
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddPopup;
