import React from "react";
import "./location.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
function EditPopup({
  showEdit,
  handleCloseEdit,
  selectedCountry,
  handleStatusChange,
  countryStatus,
  handleNewCountryStatus
}) {
  return (
    <>
      <Modal show={showEdit}>
        <div className='close-button'>
          <button onClick={handleCloseEdit}>
            <svg
              fill='#000000'
              width='26px'
              height='26px'
              viewBox='-6 -6 24 24'
              xmlns='http://www.w3.org/2000/svg'
              preserveAspectRatio='xMinYMin'
              class='jam jam-close'>
              <path d='M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z' />
            </svg>
          </button>
        </div>
        <div className='container'>
          <div className='edit-country-details'>
            <div className='country-name'>
              <p>{selectedCountry && selectedCountry.countryName}</p>
            </div>
          </div>
          <div className='action-radiobuttons mt-4'>
            <div class='form-check'>
              <input
                className='form-check-input'
                id='allow'
                type='radio'
                value='allow'
                checked={countryStatus === "allow"}
                onChange={handleStatusChange}
              />
              <label for='allow'>Allow</label>
            </div>
            <div class='form-check'>
              <input
                className='form-check-input'
                id='block'
                type='radio'
                value='block'
                checked={countryStatus === "block"}
                onChange={handleStatusChange}
              />
              <label for='block'>Block</label>
            </div>
          </div>
          <div className='add-button mb-4'>
            <Button className='add-btn' onClick={handleNewCountryStatus}>Save</Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditPopup;
