import React, { Fragment, useEffect } from "react";
import TabsetProfile from "./tabset-profile";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row, Button, Input } from "reactstrap";
import defaultprofile from "../../assets/images/defaultprofile.jpg";
import { useSelector } from "react-redux";
import { queryString } from "../../utils/queryString";
import { getAdminData } from "../../reducers/adminReducers";
import { useDispatch } from "react-redux";
const Profile = () => {
  const { tab } = queryString();
  const result = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { loginAdmin } = result || {};
  const { profile, firstname, lastname, email, walletAmount } =
    loginAdmin || {};
//   console.log("Admin wallet Amount", walletAmount);

  useEffect(() => {
    dispatch(getAdminData());
  }, [dispatch]);

  return (
    <Fragment>
      <Breadcrumb title='Profile' parent='Settings' />
      <Container fluid={true}>
        <Row>
          {tab !== "preferences" && (
            <Col xl='4'>
              <Card>
                <CardBody>
                  <div className='profile-details text-center'>
                    <div className='profile_image'>
                      <img
                        src={profile || defaultprofile}
                        alt='profile'
                        className='img-fluid img-90 rounded-circle blur-up lazyloaded'
                      />
                      <div className='edit-avtr'>
                        <Input
                          className='avtr-input'
                          type='file'
                          id='imageUpload'
                          accept='.png, .jpg, .jpeg'
                        />
                        <label for='imageUpload'></label>
                      </div>
                    </div>
                    <h5 className='f-w-600 f-16 mb-0'>
                      {firstname || ""} {""} {lastname || ""}
                    </h5>
                    <span className='user-email'>{email || ""}</span>
                    <div className='admin-wallet'>
                      <h5>
                        Wallet Amount : <span>$ {walletAmount}</span>
                      </h5>
                    </div>
                    <div className='social'>
                      <div className='form-group btn-showcase'>
                        <Button color='btn social-btn btn-fb d-inline-block'>
                          {" "}
                          <i className='fa fa-facebook'></i>
                        </Button>
                        <Button color='btn social-btn btn-twitter d-inline-block'>
                          <i className='fa fa-google'></i>
                        </Button>
                        <Button color='btn social-btn btn-google d-inline-block mr-0'>
                          <i className='fa fa-twitter'></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* <div className='project-status'>
                  <h5 className='f-w-600 f-16'>Employee Status</h5>
                  <Media>
                    <Media body>
                      <h6>
                        Performance <span className='pull-right'>80%</span>
                      </h6>
                      <div className='progress sm-progress-bar'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{ width: '90%' }}
                          aria-valuenow='25'
                          aria-valuemin='0'
                          aria-valuemax='100'></div>
                      </div>
                    </Media>
                  </Media>
                  <Media>
                    <Media body>
                      <h6>
                        Overtime <span className='pull-right'>60%</span>
                      </h6>
                      <div className='progress sm-progress-bar'>
                        <div
                          className='progress-bar bg-secondary'
                          role='progressbar'
                          style={{ width: '60%' }}
                          aria-valuenow='25'
                          aria-valuemin='0'
                          aria-valuemax='100'></div>
                      </div>
                    </Media>
                  </Media>
                  <Media>
                    <Media body>
                      <h6>
                        Leaves taken <span className='pull-right'>50%</span>
                      </h6>
                      <div className='progress sm-progress-bar'>
                        <div
                          className='progress-bar bg-danger'
                          role='progressbar'
                          style={{ width: '50%' }}
                          aria-valuenow='25'
                          aria-valuemin='0'
                          aria-valuemax='100'></div>
                      </div>
                    </Media>
                  </Media>
                </div> */}
                </CardBody>
              </Card>
            </Col>
          )}
          <Col xl={tab === "preferences" ? "12" : "8"}>
            <Card className='profile-card'>
              <CardBody>
                <TabsetProfile />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Profile;
