import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AddFaqCategoryForm from "./addFaqCategoryForm";
import { adminInstance } from '../../../config/axios'
import { useLocation } from "react-router-dom";
const Addfaq = () => {
  const initialObj = {
    title: "",
    description: "",
    selectCategory: ""
  };

  const [payload, setPayload] = useState(initialObj);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);

  }

  let query = useQuery();
  let faqId = query.get('id');
  console.log("faqId=====>", faqId);
  const getSingleFaqCategoryData = async (id) => {
    try {
      console.log("ID   +++++++", id)
      const response = await adminInstance().get(`api/admin/getSingleFaqCategoryData/${id}`)
      console.log("Response Updatee", response.data);
      const { code, faqData } = response.data;
      // console.log('data', data)
      if (code === 200) {
        setPayload(faqData)
      }
    } catch (error) {
      console.log('----------->aaa>>', error)
    }
  }

  const blank = {}

  useEffect(() => {
    getSingleFaqCategoryData(faqId)
    // setSelectData();
  }, [faqId])



  console.log('pauloaododi', payload)

  return (
    <Fragment>
      <Breadcrumb title="FAQ MANAGEMENT" parent="Faq-Category" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                {faqId ? payload?._id && <h5> Update Faq Category </h5> : <h5> Add  Faq Category </h5>}
                {/* <h5> Add  Faq  </h5> */}
              </CardHeader>
              <CardBody>
                {/* <AddFaqForm ></AddFaqForm> */}
                {console.log("faq Change =========>", faqId)}
                {console.log("faq  payload=========>", payload)}
                {faqId ? payload?._id && <AddFaqCategoryForm payload={payload} /> : <AddFaqCategoryForm payload={blank} />}
                {/* { <AddBrandForm payload ={payload} />?"" : <AddBrandForm payload={blank}/> }                                 */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Addfaq;

